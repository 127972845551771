import React, { useState } from 'react';
/* eslint-disable no-unused-vars */
import UserListTable from './UserListTable';
import {useHistory} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';
import Switch from "@material-ui/core/Switch";

const UserList = (props) => {

  const [stats, setStats] = useState({});
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const [coach, setCoach] = useAtom(coachAtom);
  const [analyzeUsers, setAnalyzeUsers] = useState(false);

  const onUserClick = (user) => {
    history.push({ 
      pathname: '/admin/userdetails', 
      state: { user },
    })
  }

  return (
    <div style={{marginTop: -20 }}>
      <div style={{marginBottom: 10 }}>
        <span style={{paddingRight: 15}}>
          Yüklenme: {loading}/7 {loading >= 7 ? " ✅" : ""}
        </span>
        <Switch 
          checked={analyzeUsers} color="primary"
          onChange={() => {
            setAnalyzeUsers(!analyzeUsers);
          }} 
        />
        <span style={{paddingRight: 15}}>Tüm Veri</span>
        {Object.keys(stats).map((k, i) => {
          return (
            <span key={i}>
              {k}: <strong style={{paddingRight: 10}}>{stats[k]}</strong>
            </span>
          )
        })}
        {coach.name && coach.name.includes("Cihan") && <CSVLink data={csvData}>Export CSV</CSVLink>}
      </div>
      <UserListTable 
        onClick={onUserClick} stats={stats} setStats={setStats} setLoading={setLoading} 
        setCsvData={setCsvData} analyze={analyzeUsers}
      />
    </div>
  );
}

export default UserList;