import axios from 'axios';
import { domain } from './domain';

// get web users
export const getWebUsers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getwebusers', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get web forms
export const getWebForms = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getwebforms', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update web form
export const updateWebForm = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/webform', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

