/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { updateWebForm } from 'services/webUserServices';

const statuses = ["yeni", "ilk iletişim", "ulaşılamadı", "dönüş yapmadı", "olumsuz", "düşünecek", "olumlu", "DANIŞAN"];

const FormDialog = (props) => {
  
  const { open, toggle, form, forms, setForms } = props;
  const [status, setStatus] = useState(statuses[0]);

  useEffect(() => {
    setStatus(form.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, form]);

  const handleChangeStatus = event => setStatus(event.target.value);

  const updateForm = (event) => {
    event.preventDefault();
    toggle();
    console.log("event.target.elements: ", event.target.elements)
    var info = event.target.elements.info ? event.target.elements.info.value : ""
    const data = { id: form._id, status, info }
    updateWebForm(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        const newForm = res.data.data;
        console.log("form updated: ", newForm)
        // update state
        let index = forms.map(f => f._id).indexOf(newForm._id)
        if (index > -1) {
          setForms([
            ...forms.slice(0, index), 
            newForm,
            ...forms.slice(index + 1)
          ])
        }
      } else {
        console.log("sorun: ", res)
      }
    })
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Durum Güncellemesi: {form.name}</DialogTitle>
      <DialogContent>
        <form onSubmit={updateForm}>
          <div>
            <InputLabel style={{marginTop: 0}} id="select-status">Adayın Durumu</InputLabel>
            <Select labelId="label-status" id="status" value={status ? status : ""}
              onChange={handleChangeStatus} style={{width: 200}}>
              {statuses.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
            </Select>
          </div>
          <div>
            <TextField 
              name="info" id="info" label="Notlar" type="text" multiline rows={3}
              defaultValue={form.info ? form.info : ""}
              style={{marginTop: 15, width: 350}}
            />
          </div>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">{"GÜNCELLE"}</Button>
            <Button onClick={toggle} color="primary">Kapat</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default FormDialog;