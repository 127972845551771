import axios from 'axios';
import { domain } from './domain';
import movements from '../../src/movements.json';

// MOVEMENTS
// create new movement
export const createMovement = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/movement', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// create all movements database
export const createMovements = (allMovements) => {
  var newMovement;
  for (let i = 0 ; i < movements.list.length ; i++) {
    // check if exists in DB
    newMovement = true;
    if (allMovements) {
      for (let j = 0; j < allMovements.length; j++) {
        if (allMovements[j].name.toLowerCase().trim() === movements.list[i].name.toLowerCase().trim()) {
          newMovement = false;
        };
      }
    }
    if (movements.list[i].name && newMovement ) {
      const data = {
        name: movements.list[i].name.trim(),  
        video: movements.list[i].video.trim(),
        stage: movements.list[i].stage.trim(),
        muscle: movements.list[i].muscle.trim(),
        detail: movements.list[i].detail,
        equipment: movements.list[i].equipment.trim(),
        doubleside: movements.list[i].doubleside,
        lumbar: movements.list[i].lumbar,
        knee: movements.list[i].knee,
        jump: movements.list[i].jump,
        shoulder: movements.list[i].shoulder,
        level: movements.list[i].level,
        timerep: movements.list[i].timerep,
        progress: movements.list[i].progress,
        resistance: movements.list[i].resistance,
        nameaudio: movements.list[i].nameaudio,
        descaudio: movements.list[i].descaudio,
      }
      console.log("new movement: ", data.name);
      createMovement(data)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          console.log("created: ", res.data.data.name);
        } else {
          console.log("Error! movement name: ", data.name);
        }
      });
      // break;  // for testing
    }
  }
}

export const update = (allMovements) => {
  for (let i = 0 ; i < movements.list.length ; i++) {
    let id;
    allMovements.map(m => {
      if (m.name.toLowerCase().trim() === movements.list[i].name.toLowerCase().trim()) {
        id = m._id;
      };
      return null;
    });
    if (id) {
      const d = {
        shoulder: movements.list[i].shoulder === "1" ? true : false,
      }
      const data = { id, ...d }
      console.log("updating, data: ", data);
      updateMovement(data)
      .then((res) => {
        if (res.data && res.data.code === 200){
          console.log("movement update başarılı: ", movements.list[i].name)
        } else {
          console.log("movement update hata: ", movements.list[i].name)
        }
      });
      // break;  // for testing
    } else {
      console.log("movement bulunamadı: ", movements.list[i].name)
    }
  }
}

// get all movements
export const getMovements = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getmovements', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update a food
export const updateMovement = (data) => {
  const requestOptions = {
      method: "PUT",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/movement', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}