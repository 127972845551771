import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserMessages } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserMessages = (props) => {
  const { user } = props;

  const [messages1, setMessages1] = useState([]);
  const [messages2, setMessages2] = useState([]);
  const [messages3, setMessages3] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchMessages = () => {
    // const getLastDays = 60;
    // let oneWeek = new Date();
    // oneWeek.setDate(oneWeek.getDate() - getLastDays);
    for (let i = 1; i <= 3; i++) {
      // let data = { id: user._id, coach: i, date: oneWeek }
      let data = { id: user._id, coach: i, date: new Date(2021, 4, 1) } // from 01.05.2021
      getUserMessages(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          if (i === 1) {
            setMessages1(res.data.data);
          }
          if (i === 2) {
            setMessages2(res.data.data);
          }
          if (i === 3) {
            setMessages3(res.data.data);
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 50px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  const userCard = { 
    float: 'right',
    borderRadius: 12, 
    borderWidth: 1, 
    borderColor: '#E4DFFF', 
    backgroundColor: '#E4DFFF',
    padding: 6,
    paddingLeft: 12,
    paddingRight: 12,
    maxWidth: '85%',
    marginBottom: 5
  }
  const coachCard = { 
    float: 'left',
    borderRadius: 12, 
    borderWidth: 1, 
    borderColor: '#F4F6FA', 
    backgroundColor: '#F4F6FA',
    padding: 6,
    paddingLeft: 12,
    paddingRight: 12,
    width: '85%',
    marginBottom: 5
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '34%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll" }}>
              <h6>DİYETİSYEN Merve</h6>
              {messages1 &&
                <div>
                  {messages1.map((message, i) => {
                    return (
                      <div key={i} style={message.sender === 0 ? userCard : coachCard}>
                        {message.text} 
                      </div>
                    )
                  })}
                </div>
              }
            </CardBody>
          </Card>
        </GridItem>
    
        <GridItem style={{marginLeft: -12, width: '34%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll"}}>
              <h6>PSİKOLOG BELİZ</h6>
              {messages2 &&
                <div>
                  {messages2.map((message, i) => {
                    return (
                      <div key={i} style={message.sender === 0 ? userCard : coachCard} >
                        {message.text} 
                      </div>
                    )
                  })}
                </div>
              }
           </CardBody>
          </Card>
        </GridItem>
      
        <GridItem style={{marginLeft: -12, width: '33.2%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll"}}>
              <h6>ANTRENÖR Yağmur</h6>
              {messages3 &&
                <div>
                  {messages3.map((message, i) => {
                    return (
                      <div key={i} style={message.sender === 0 ? userCard : coachCard}>
                        {message.text} 
                      </div>
                    )
                  })}
                </div>
              }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserMessages;