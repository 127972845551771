import React, { useState } from 'react';
import ErrorLogsTable from './ErrorLogsTable';
/* eslint-disable no-unused-vars */

const ErrorLogs = () => {
  const [notSolved, setNotSolved] = useState(0);

  return (
    <>
      <div style={{marginBottom: 5, marginTop: -30 }} >
        Açık Error Log: <strong>{notSolved} adet</strong> 
      </div>
      <ErrorLogsTable setNotSolved={setNotSolved}/>
    </>
  );
}

export default ErrorLogs;