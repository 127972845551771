import React, { useState } from 'react';
import FeedbacksTable from './FeedbacksTable';
/* eslint-disable no-unused-vars */

const UserFeedbacks = () => {
  const [rating, setRating] = useState(0);
  const [notSolved, setNotSolved] = useState(0);
  const [chats, setChats] = useState("");

  return (
    <>
      <div style={{marginBottom: 5, marginTop: -30 }} >
        Ortalama Rating: <strong style={{ paddingRight: 20}}>{parseInt(rating * 10) / 10}</strong> 
        Açık Geribildirim: <strong style={{ paddingRight: 20}}>{notSolved} adet</strong> 
        Chat Mesajları/Kullanıcı: <strong style={{ paddingRight: 20}}>{chats}</strong> 
      </div>
      <FeedbacksTable setRating={setRating} setNotSolved={setNotSolved} setChats={setChats}/>
    </>
  );
}

export default UserFeedbacks;