import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import { dateLocal } from '../../services/helperFunctions';
import { getErrorLogs, updateErrorLog } from '../../services/userServices';
import SweetAlert from "react-bootstrap-sweetalert";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'user', title: 'Kullanıcı' },
  { name: 'function', title: 'Fonksiyon' },
  { name: 'error', title: 'Hata' },
  { name: 'solved', title: 'Durumu' },
];

const ErrorLogsTable = (props) => {

  const { setNotSolved } = props;

  const [errorLogs, setErrorLogs] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: 'function', wordWrapEnabled: true }, { columnName: 'error', wordWrapEnabled: true },
  ]);
  const [alert, setAlert] = useState();

  useEffect(() => {
    getAllErrorLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getAllErrorLogs = () => {
    getErrorLogs()
    .then(res => {
      if (res.data.code === 200) {
        let data = res.data.data;
        setErrorLogs(data);
        let notSolved = data.filter(d => !d.solved);
        setNotSolved(notSolved.length);
      } else {
        console.log('Hata: ', res.data.code);
      }
    })
    .catch((err) => {
      console.log('Bir hata oluştu: ', err)
    })
  }
  
  const onClick = (error) => {
    // handle errorLog
    setAlert(
      <SweetAlert 
        info
        title="Hata çözüldü mü?" 
        onConfirm={() => closeError(error)} 
        onCancel={() => setAlert()}
        confirmBtnText=" Evet "
        cancelBtnText=" Hayır "
        showCancel={true}
        showConfirm={true}
        confirmBtnBsStyle='success'
        cancelBtnBsStyle='secondary'
      />
    );
  }
  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert 
        success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" 
        onConfirm={() => hideAlert()} showConfirm={false}
      >
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1500);
  };
  const hideAlert = () => setAlert(null);

  const closeError = (error) => {
    setAlert();
    console.log(error);
    let data = {id: error._id, solved: true}
    // update
    updateErrorLog(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        console.log("error updated:", res.data.data);
        setTimeout(autoCloseAlert, 100);
        getAllErrorLogs();
      }
    })
  }

  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'date', width: 90 },
    { columnName: 'user', width: 230 },
    { columnName: 'function', width: 160 },
    { columnName: 'error', width: 220 },
    { columnName: 'solved', width: 95 },
  ])

  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedError = errorLogs.find(s => s._id === row.id);
        onClick(selectedError);
      }}
      style={{
        cursor: 'pointer',
        backgroundColor: row.solved === "🟢 Kapandı" ? "#F4F6FA" : "#EFFFFF",
      }}
    />
  );

  const rows = (errorLogs) => {
    return (
      errorLogs.map((s, i) => {
        var id = s._id;
        var no = i + 1;
        var avatar = s.user && s.user.avatar;
        var name = s.user && s.user.name;
        var email = s.user && s.user.email;
        var user = `${avatar} ${name} ${email}`;
        var date = dateLocal(s.date);
        var func = s.function;
        var error = s.error;
        var solved = s.solved;
        return ({
          no: no,
          id: id,
          user: user,
          date: date,
          function: func,
          error: error,
          solved: solved ? "🟢 Kapandı" : "🔴 Açık"
        })
      })
    )
    
  }

  return (
    <>
      <Paper>
        <Grid rows={rows(errorLogs)} columns={columns}>
          <SortingState defaultSorting={[{ }]}/>
          <IntegratedSorting />
        
          <Table />
          <VirtualTable 
            height="calc(100vh - 130px)"
            rowComponent={TableRow} 
            tableComponent={TableComponent} 
            columnExtensions={tableColumnExtensions} 
          />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls />

          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        </Grid>
      </Paper>
      
      {alert}
    </>
    
  );
}

export default ErrorLogsTable;