import React, { useState } from 'react';
/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPrograms, getLessons, getScreens, getProgramLessons, getProgram } from '../../services/contentServices';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const columns = [
  { name: 'no', title: 'Sıra' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'userName', title: 'İsim' },
  { name: 'userEmail', title: 'Eposta' },
  { name: 'answer', title: 'Cevap' },
  // { name: 'content', title: 'İçeriği' },
];

const UserAnswers = (props) => {

  const { open, toggle, userAnswers, title} = props;
  
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 90 },
    { columnName: 'id', width: 90 },
    { columnName: 'date', width: 160 },
    { columnName: 'userName', width: 110 },
    { columnName: 'userEmail', width: 210 },
    { columnName: 'answer', width: 310 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const [tableColumnExtensions] = useState([
    { columnName: 'answer', wordWrapEnabled: true },
  ]);

  const [totalSummaryItems] = useState([
    { columnName: 'date', type: 'count' },
  ]);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      style={{
        cursor: 'pointer'
      }}
    />
  );

  const rows = () => {
    var no = 0;
    return (
      userAnswers.map(a => {
        no += 1;
        return ({
          no: no,
          id: a._id,
          date: a.date.slice(0, 19).replace("T", " "),
          userName: a.user && a.user.name,
          userEmail: a.user && a.user.email,
          answer: a.value ? a.value : a.choices.map((c, i) => c.choiceText + (i !== a.choices.length - 1 ? ", " : "."))
        });
  
      })
    )
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md" PaperComponent={PaperComponent}>
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
          Soru: {title}
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Grid rows={rows()} columns={columns}>
              <SortingState defaultSorting={[{ }]}/>
              <IntegratedSorting />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <Table />
              <VirtualTable 
                height="calc(100vh - 200px)"
                rowComponent={TableRow} 
                tableComponent={TableComponent} 
                columnExtensions={tableColumnExtensions} 
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
              <SummaryState
                totalItems={totalSummaryItems}
              />
              <IntegratedSummary />
              <TableSummaryRow />
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="primary" variant="contained">Kapat</Button>
        </DialogActions>
    </Dialog>
  );
}

export default UserAnswers;