import React, { useState } from 'react';
/* eslint-disable no-unused-vars */
import CoachListTable from './CoachListTable';
import { useHistory } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';

const CoachList = (props) => {

  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const [csvData, setCsvData] = useState([]);
  const [coach, setCoach] = useAtom(coachAtom);

  const onFormClick = (user) => {
    //
  }

  return (
    <div style={{marginTop: -20, marginLeft: -15, paddingLeft: -20 }}>
      <div style={{marginBottom: 10 }}>
        <span style={{paddingRight: 15}}>
          Yüklenme: {loading >= 1 ? " ✅" : ""}
        </span>
        {Object.keys(stats).map((k, i) => {
          return (
            <span key={i}>
              {k}: <strong style={{paddingRight: 15}}>{stats[k]}</strong>
            </span>
          )
        })}
      </div>
      {coach.name && coach.name.includes("Cihan") && <CSVLink data={csvData}>Export CSV</CSVLink>}
      <CoachListTable 
        onClick={onFormClick} stats={stats} setStats={setStats} setLoading={setLoading} 
        setCsvData={setCsvData}
      />
    </div>
  );
}

export default CoachList;