import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary, DataTypeProvider } from '@devexpress/dx-react-grid';
import { getUsers, getUsersActive, updateUser, getUserWorkouts, getAllUserWorkouts, getAllUserLessons, getAllMoods, getFeedbacks, getAllWaters, getAllMealDiaries, getUserAnswerByScreenId, getAllSubscriptions } from '../../services/userServices';
import { dateLocal } from '../../services/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Adı' },
  { name: 'email', title: 'E-posta' },
  { name: 'gender', title: 'Cinsiyet' },
  { name: 'point', title: 'Puan' },
  { name: 'startDate', title: 'Kayıt' },
  { name: 'days', title: 'Gün' },
  { name: 'type', title: 'Üyelik' },
  { name: 'phone', title: 'Cihaz' },
  // { name: 'age', title: 'Yaş' },
  // { name: 'tel', title: 'Tel' },
  { name: 'lastLogin', title: 'Son Giriş' },
  { name: 'active', title: 'Aktif' },
  { name: 'lesson', title: 'Ders' },
  { name: 'moods', title: 'Mod' },
  { name: 'chats', title: 'Chat' },
  { name: 'waters', title: 'Su' },
  { name: 'meals', title: 'Öğün' },
  { name: 'workout', title: 'Ant' },
];

const activeText = (text) => {
  let level = (text && parseInt(text.split(".")[0])) || 0;
  switch (level) {
    case 1: return "Son 7 gün aktif, puan > 0";
    case 2: return "Son 7 gün aktif, puan > 20";
    case 3: return "Son 7 gün aktif, tekrar gelmiş";
    case 4: return "Son 3 gün aktif, tekrar gelmiş, 1+ gündür üye";
    case 5: return "Son 3 gün aktif, 7+ gündür üye";
    default: return "Son 7 gün aktif değil";
  }
}
const TooltipFormatter = ({ row: { active }, value }) => (
  <Tooltip title={(
    <span style={{ fontSize: 14, lineHeight: "20px" }}>
      {`Aktiflik: ${active}`} <br/>
      {activeText(active)}
    </span>
  )}
  >
    <span>
      {value}
    </span>
  </Tooltip>
);
const CellTooltip = props => (
  <DataTypeProvider
    for={columns.map(({ name }) => name )}
    formatterComponent={TooltipFormatter}
    {...props}
  />
);

let allUsers = [];
let statsObj = {};
let loading = 0;
let userSubs = {};

const UserListTable = (props) => {

  const { onClick, stats, setStats, setLoading, setCsvData, analyze } = props;
  const [users, setUsers] = useState([{}]);
  const [row, setRow] = useState([{}]);
  const [totalSummaryItems] = useState([
    { columnName: 'name', type: 'count' },
  ]);

  const setLoad = () => {
    loading++;
    setLoading(loading);
    // console.log(loading);
    if (loading === 7) {
      setUsers(allUsers);
      let r = rows(allUsers)
      setRow(r);
      setCsvData(r);
    }
  }

  useEffect(() => {
    if (analyze) {
      getLessons();
      getMoods();
      getAllFeedbacks();
      getWaters();
      getMealDiaries();
      getWorkouts(); 
      // getAnswers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[analyze]);

  const getWorkouts = () => {
    let time = new Date();
    getAllUserWorkouts()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let workouts = res.data.data;
        console.log(workouts.length, " user workouts loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        // let completedWorkouts = workouts.filter(w => w.isCompleted);
        for (let i = 0; i < workouts.length; i++) {
          const findUser = (user) => user && workouts[i].user && user._id === workouts[i].user._id;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.workoutCount = u.workoutCount ? u.workoutCount + 1 : 1;
            // u.workoutDate = workouts[i].date;
          }
          if (i === workouts.length - 1) {   
            setLoad();
          }
        }
      }
    })
  }

  const getLessons = () => {
    let time = new Date();
    getAllUserLessons()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let lessons = res.data.data;
        console.log(lessons.length, " user lessons loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < lessons.length; i++) {
          const findUser = (user) => user && user._id === lessons[i].user;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.lessonCount = u.lessonCount ? u.lessonCount + 1 : 1;
          }
          if (i === lessons.length - 1) {
            setLoad();
          }
        }
      }
    })
  }

  const getSubscriptions = () => {
    let time = new Date();
    getAllSubscriptions()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let subscriptions = res.data.data;
        console.log(subscriptions.length, " user subscriptions loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < subscriptions.length; i++) {
          let userId = subscriptions[i].user;
          let type = subscriptions[i].userType ? "P" : "F";
          userSubs[userId] = userSubs[userId] ? userSubs[userId] + " " + type : type;
          if (i === subscriptions.length - 1) {
            // son üyelik premium ise end date bak, tarihi geçmiş ise F ekle
            // console.log(userSubs);
            // setUsers(allUsers);
            // setRow(rows(allUsers));
            // setCsvData(rows(allUsers));
            // setLoad();
          }
        }
      } else {
        console.log("hata: ", res);
      }
    })
  }

  const getAnswers = () => {
    /*eslint no-loop-func: "off"*/
    const screens = [
      { field: "tel", id: "612790fffa90cb0407aad854"},
      { field: "birth", id: "6092aae4db84297e556fecda"},
    ]
    let time = new Date();
    for (let s = 0; s < screens.length; s++) {
      getUserAnswerByScreenId({ screenId: screens[s].id })
      .then(res => {
        if (res.data && res.data.code === 200) {
          let answers = res.data.data;
          console.log(answers.length, " user answers loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
          for (let i = 0; i < answers.length; i++) {
            const findUser = (user) => user && user._id === answers[i].user;
            let index = allUsers.findIndex(findUser);
            if (index !== -1) {
              let u = allUsers[index];
              // get answer (value)
              u[screens[s].field] = answers[i].value;
            }
            if (i === answers.length - 1) {
              setLoad();
              // analyze age average
              if (screens[s].field === "birth") {
                let ageSum = 0;
                let ageCount = 0;
                for (let j = 0; j < allUsers.length; j++) {
                  let u = allUsers[j];
                  if (u.birth) {
                    let age = Math.round((new Date() - new Date(u.birth))/(1000*60*60*24*365));
                    if (isNaN(age)) {
                      console.log("isNan: ", j, u);
                    }
                    if (age >= 12) {
                      ageCount++;
                      ageSum += age;
                    }
                  }
                  if (j === allUsers.length - 1) {
                    console.log(ageSum, ageCount);

                    statsObj["Ort.Yaş:"] = Math.round(ageSum/ageCount);
                    setStats(statsObj);
                  }
                }
              }
            }
          }
        }
      })
    }
  }

  const getMoods = () => {
    let time = new Date();
    getAllMoods()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let moods = res.data.data;
        console.log(moods.length, " user moods loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < moods.length; i++) {
          const findUser = (user) => user && user._id === moods[i].user;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.moodCount = u.moodCount ? u.moodCount + 1 : 1;
            // u.moodTotal = (u.moodTotal || 0) + moods[i].mood + 1;
          }
          if (i === moods.length - 1) {
            setLoad();
          }
        }
      }
    })
  }

  const getAllFeedbacks = () => {
    let time = new Date();
    getFeedbacks()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let feedbacks = res.data.data;
        feedbacks = feedbacks.filter(f => f.type.includes("Chat SSS:"));
        console.log(feedbacks.length, " user feedbacks loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < feedbacks.length; i++) {
          const findUser = (user) => user && feedbacks[i].user && user._id === feedbacks[i].user._id;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.chatCount = u.chatCount ? u.chatCount + 1 : 1;
          }
          if (i === feedbacks.length - 1) {
            setLoad();
          }
        }
      }
    })
  }

  const getWaters = () => {
    let time = new Date();
    getAllWaters()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let waters = res.data.data;
        console.log(waters.length, " user waters loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < waters.length; i++) {
          const findUser = (user) => user && waters[i].user && user._id === waters[i].user;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.waterCount = u.waterCount ? u.waterCount + 1 : 1;
          }
          if (i === waters.length - 1) {
            setLoad();
          }
        }
      }
    })
  }

  const getMealDiaries = () => {
    let time = new Date();
    getAllMealDiaries()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let meals = res.data.data;
        console.log(meals.length, " user meals loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        for (let i = 0; i < meals.length; i++) {
          const findUser = (user) => user && meals[i].user && user._id === meals[i].user;
          let index = allUsers.findIndex(findUser);
          if (index !== -1) {
            let u = allUsers[index];
            u.mealCount = u.mealCount ? u.mealCount + 1 : 1;
          }
          if (i === meals.length - 1) {
            setLoad();
          }
        }
      }
    })
  }

  useEffect(() => {
    loading = 0;
    fetchUsers();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchUsers = () => {
    let time = new Date();
    getUsers()
    .then(res => {
      if (res.data.code === 200) {
        allUsers = res.data.data.reverse();
        console.log(allUsers.length, " users loaded in ", parseInt((new Date() - time) / 100) / 10, "sec");
        // 13, 22  sec
        setUsers(allUsers);
        let r = rows(allUsers)
        setRow(r);
        setCsvData(r);
        setLoad();

        // getSubscriptions();

        // stats
        let dau = 0;
        let mau = 0;
        let wau = 0;
        let ios = 0;
        let isFemale = 0;
        let premium = 0;
        let newUsers1 = 0;
        let newUsers2 = 0;
        var d1 = new Date();
        d1.setDate(d1.getDate() - 1);
        var d2 = new Date();
        d2.setDate(d2.getDate() - 2);
        let signedUp = 0;
        // let activeFreemium = 0;
        for (let i = 0; i < allUsers.length; i++) {
          // if signedUp
          if (parseInt(allUsers[i].xp)) {
            let passedDays = (new Date() - new Date(allUsers[i].lastLogin)) / (1000*60*60*24);
            dau += passedDays <= 1 ? 1 : 0;
            mau += passedDays <= 30 ? 1 : 0;
            wau += passedDays <= 7 ? 1 : 0;
            ios += allUsers[i].device.includes("Apple") ? 1 : 0;
            isFemale += allUsers[i].isFemale ? 1 : 0;
            let s = allUsers[i].activeSubscription;
            premium += s && s.userType && new Date(s.endDate) > new Date();
            newUsers1 += s && new Date(s.startDate).toLocaleDateString() === new Date(d1).toLocaleDateString() ;
            newUsers2 += s && new Date(s.startDate).toLocaleDateString() === new Date(d2).toLocaleDateString() ;
            signedUp += parseInt(allUsers[i].xp) ? 1 : 0;
            // let days = (new Date() - new Date(s.startDate)) / (1000*60*60*24);
            // activeFreemium += passedDays < 3 ? 1 : 0; 
          }
        }
        statsObj = {
          "MAU" : parseInt(100 * mau / signedUp) + "% " + mau,
          "Kayıt olan" : parseInt(100 * signedUp / allUsers.length) + "%",
          "DAU/MAU" : parseInt(100 * dau / mau) + "%",
          "WAU/MAU" : parseInt(100 * wau / mau) + "%",
          // "DAU/WAU" : parseInt(100 * dau / wau) + "%",
          "Premium" : parseInt(100 * premium / signedUp) + "%",
          "Yeni (dün/ö.gün)" : newUsers1 + "/" + newUsers2,
          "ios/Android" : parseInt(100 * ios / signedUp) + "/" + parseInt(100 * (1 - ios / signedUp)),
          // "Kadın/Erkek" : parseInt(100 * isFemale / signedUp) + "/" + parseInt(100 * (1 - isFemale / signedUp)),
        };
        setStats(statsObj);
        
        // UPDATE ALL USERS DATA

        // mealPlanWeek
        // allUsers.map((u, i) => {
          // if (i === allUsers.length - 1 || true) {    // for testing chn
          //   let updatedData = { id: u._id, mealPlanWeek: 0 }
          //   updateUser(updatedData)
          //   .then((res) => {
          //     if (res.data && res.data.code === 200) {
          //       console.log("user data updated: ", u.name);
          //     } else {
          //       console.log("user data update error!")
          //     }
          //   })
          // }
          // return null;
        // })

        // add signup date to user model
        // allUsers.map((u, i) => {
        //   if (!u.date) {  // if no user.date ** not working!
        //     let s = u.activeSubscription;
        //     let updatedData = { id: u._id, date: s.startDate }
        //     updateUser(updatedData)
        //     .then((res) => {
        //       if (res.data && res.data.code === 200) {
        //         console.log("date - user data updated: ", u.name);
        //       } else {
        //         console.log("user data update error!")
        //       }
        //     })
        //   }
        //   return null;
        // })
      }
    })
  }
  
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'name', width: 95 },
    { columnName: 'startDate', width: 110 },
    { columnName: 'point', width: 85 },
    { columnName: 'phone', width: 100 },
    { columnName: 'email', width: 130 },
    { columnName: 'gender', width: 95 },
    { columnName: 'lastLogin', width: 100 },
    { columnName: 'days', width: 80 },
    { columnName: 'type', width: 95 },
    { columnName: 'active', width: 130 },
    { columnName: 'lesson', width: 85 },
    { columnName: 'moods', width: 85 },
    { columnName: 'chats', width: 85 },
    { columnName: 'meals', width: 85 },
    { columnName: 'waters', width: 85 },
    { columnName: 'workout', width: 85 },
    // { columnName: 'age', width: 80 },
    // { columnName: 'tel', width: 120 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedUser = users.find(s => s._id === row.id);
        // setUser(selectedUser);
        // console.log(selectedUser);
        onClick(selectedUser);
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );

  const rows = (users) => {
    let time = new Date();
    var no = users.length + 1;
    return (
      users.map(s => {
        no -= 1;
        var id = s._id;
        // var avatar = s.avatar;
        var name = s.name;
        var point = parseInt(s.xp) || (s.email && s.email.includes("@") ? 10 : null);
        let actions = s.lessonCount ? s.lessonCount : 0 + s.chatCount ? s.chatCount : 0 + s.mealCount ? s.mealCount : 0 + s.waterCount ? s.waterCount : 0 + s.workoutCount ? s.workoutCount : 0;
        // console.log(no, actions);
        point +=  actions > 0 ? actions * 5 : 0;
        var phone = s.device && s.device.split("~")[0];
        // phone+= s.pushToken ? " 🔔" : "";
        var email = s.email;
        var gender = s.isFemale ? "Kadın" : 'Erkek';
        // var lastLogin = dateLocal(s.lastLogin);
        // var lastLogin = point > 0 ? s.lastLogin.slice(0,10) : "";
        var lastLogin = point > 0 ? Math.round((new Date() - new Date(s.lastLogin)) / (1000*60*60*24)) : "";
        var type = point > 0 ? (s.activeSubscription && s.activeSubscription.userType && new Date(s.activeSubscription.endDate) > new Date() ? "Premium 💎" : (s.activeSubscription && s.activeSubscription.userType ? "Freemium P" : "Freemium")) : "-";
        // var endDate = point > 0 ? s.activeSubscription && s.activeSubscription.endDate.slice(0,10) : "";
        var startDate = s.activeSubscription && s.activeSubscription.startDate.slice(0,10);
        startDate += dateLocal(startDate) === dateLocal(new Date()) ?  " ✳️" : "" ;
        var days = Math.round((new Date() - new Date(s.date)) / (1000*60*60*24));
        var active = lastLogin < 7 && point > 0 ? 1 : point > 0 ? 0 : " ";
        active = active && point > 20 ? 2 : active;
        active = active && point > 20 && days - lastLogin >= 1 ? 3 : active;
        active = active && point > 20 && lastLogin <= 3 && days - lastLogin > 1 && days >= 1 ? 4 : active;
        let dailyPoints = (point - 10) / days;
        active = (active && point > 35 && lastLogin <= 3 && days >= 7) ? 5 + dailyPoints * .01  : active;
        active = active > 5.9 ? 5.9 : active;
        // let age = s.birth && Math.round((new Date() - new Date(s.birth))/(1000*60*60*24*365));
        // elapsed
        // if (no === 1) {
        //   console.log(" row function elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        // }
        return ({
          no: no,
          id: id,
          // avatar: avatar,
          name: name,
          email: point > 0 ? email : "",
          startDate: startDate,
          point: point > 0 ? point : " ",
          phone: phone,
          gender: point > 0 ? gender : "",
          days: point > 0 ? (days < 10 ? "0" + days : days) : "",
          lastLogin: lastLogin < 10 && point > 0 ? "0" + lastLogin : lastLogin,
          // ratio: point > 0 && s.workoutCount > 0 ? parseInt(100 * s.workoutCount / (days || 1)) : "",
          // workDate: s.workoutDate && dateLocal(s.workoutDate),
          // workDate: s.workoutDate && s.workoutDate.slice(0, 10),
          type: type,
          // endDate: endDate,
          // image: <img src={image} alt={image} style={{ height:'30px', marginLeft:0 }}/>
          active: (active > 5 ? Math.round(active * 10) / 10 : active + (active > 0 ? "." : "")) + " " + "⭐️".repeat(active),
          lesson: point > 0 ? (s.lessonCount ? s.lessonCount + "." : 0) : " ",
          moods: point > 0 ? (s.moodCount > 1 ? s.moodCount + "." : s.moodCount) : " ",
          chats: s.chatCount ? s.chatCount + ".": " ",
          meals: s.mealCount ? s.mealCount + ".": " ",
          waters: s.waterCount ? s.waterCount + ".": " ",
          // age: age >= 12 && age < 90 ? age + "." : " ",
          // tel: s.tel && s.tel.length > 9 ? s.tel + "." : " ",
          workout: point && s.workoutCount > 0 ? s.workoutCount + "." : "",
        })
      })
    )
  }

  return (
    users[0].name ? 
    <Paper>
      <Grid rows={row} columns={columns}>
        <CellTooltip />
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          height="calc(100vh - 100px - 60px)"
          // height="calc(100vh + 2000px)"  // for copy-paste
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <SummaryState
          totalItems={totalSummaryItems}
        />
        <IntegratedSummary />
        <TableSummaryRow />
      </Grid>
    </Paper>
    :
    <CircularProgress />
  );
}

export default UserListTable;