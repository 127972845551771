/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { updatePayment } from 'services/userServices';

const statuses = ["yeni", "ilk iletişim", "liste iletildi"];
const therapyStatuses = ["evet", "hayır", "gerek yok"];

const PaymentDialog = (props) => {
  
  const { open, toggle, payment, payments, setPayments } = props;
  const [status, setStatus] = useState(statuses[0]);
  const [therapyStatus, setTherapyStatus] = useState("");

  useEffect(() => {
    setStatus(payment.info);
    setTherapyStatus(payment.therapy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, payment]);

  const handleChangeStatus = event => setStatus(event.target.value);
  const handleChangeTherapyStatus = event => setTherapyStatus(event.target.value);

  const updateWebPayment = (event) => {
    event.preventDefault();
    toggle();
    var notes = event.target.elements.notes ? event.target.elements.notes.value : ""
    const data = { id: payment._id, info: status, notes, therapy: therapyStatus }
    updatePayment(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        const newPayment = res.data.data;
        console.log("payment updated: ", newPayment)
        // update state
        let index = payments.map(f => f._id).indexOf(newPayment._id)
        if (index > -1) {
          setPayments([
            ...payments.slice(0, index), 
            newPayment,
            ...payments.slice(index + 1)
          ])
        }
      } else {
        console.log("sorun: ", res)
      }
    })
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Durum Güncellemesi: {payment.name}</DialogTitle>
      <DialogContent>
        <form onSubmit={updateWebPayment}>
          <div style={{display:"flex", flexDirection: "row", width: 350, justifyContent: "space-between" }}>
            <div>
              <InputLabel id="select-status">Danışanın Durumu</InputLabel>
              <Select labelId="label-status" id="status" value={status ? status : ""}
                onChange={handleChangeStatus} style={{width: 150}}>
                {statuses.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
              </Select>
            </div>
            <div>
              <InputLabel id="select-status">Terapiye Yönlendirildi mi?</InputLabel>
              <Select labelId="label-therapyStatus" id="therapyStatus" value={therapyStatus ? therapyStatus : ""}
                onChange={handleChangeTherapyStatus} style={{width: 180}}>
                {therapyStatuses.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
              </Select>
            </div>
          </div>
          
          <div>
            <TextField 
              name="notes" id="notes" label="Notlar" type="text" multiline rows={3}
              defaultValue={payment.notes ? payment.notes : ""}
              style={{marginTop: 15, width: 350}}
            />
          </div>
          <DialogActions>
            <Button type="submit" color="primary" variant="contained">{"GÜNCELLE"}</Button>
            <Button onClick={toggle} color="primary">Kapat</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentDialog;