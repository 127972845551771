import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getScreensSystem } from '../../services/contentServices';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const columns = [
  { name: 'no', title: 'Sıra' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Adı' },
  { name: 'screenType', title: 'Ekran' },
  { name: 'content', title: 'İçeriği' },
];

const QuestionArchiveDialog = (props) => {

  const { open, toggle, setQuestionName, systemPage } = props;
  
  const [links, setLinks] = useState([{}]);
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 90 },
    { columnName: 'id', width: 90 },
    { columnName: 'name', width: 320 },
    { columnName: 'screenType', width: 160 },
    { columnName: 'content', width: 150 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const [tableColumnExtensions] = useState([
    { columnName: 'name', wordWrapEnabled: true },
  ]);

  useEffect(() => {
    getAllLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open]);

  const getAllLinks = () => {
    console.log("getting system & question screens..."); 
    let time = new Date();
    getScreensSystem()  // only system & question screens
    .then(res => {
      if (res.data.code === 200) {
        var newLinks = res.data.data;
        if (systemPage) {
          newLinks = newLinks.filter(l => l.type === "Sistem")
        } else {
          newLinks = newLinks.filter(l => l.type === "Sistem" || l.type === "Tekli seçmeli soru" || l.type === "Çoklu seçmeli soru" || l.type === "Metin girişi" || l.type === "Rakam girişi" )
        }
        setLinks(newLinks);
        console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        console.log(res.data.data.length, ' adet kayıt');
      }
    })
  }

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        // select a link
        var selectedId = row.id;
        var selectedName = row.name;
        // console.log(selectedId, selectedName)
        // setLink({id: row.id, name: row.name, type: row.type})

        let choices = links.filter(l => l._id === row.id)[0].choices
        setQuestionName({id: row.id, name: row.name, choices: choices})

        // console.log({id: row.id, name: row.name})
        toggle()
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );
  const rows = () => {
    var no = 0;
    return (
      links.map(l => {
        no += 1;
        if (l.lessons) {
          var linkType = "program"
          if (l.lessons[0]) {
            var content = l.lessons[0].name
          } 
        } else if(l.pages) {
          linkType = "ders"
          if (l.pages[0]) {
            content = l.pages[0].screenName
          }
        } else {
          linkType = "ekran"
          content = l.title
          var screenType = l.type
        }
        return ({
          no: no,
          id: l._id,
          branch: l.branch,
          name: l.name ? l.name : l.screenName,
          screenType: l.type,
          content: content
        })
      })
    )
  }
  
  
  const onAddAlgorithm = () => {
    //
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md" PaperComponent={PaperComponent}>
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
          {systemPage ? "Sistem Sayfası Seçin" : "Sistem veya Soru Sayfası Seçin"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Arşivden seçin ({links.length}):<strong style={{background:'honeydew'}}></strong>
          </DialogContentText>
          <Paper>
            <Grid rows={rows()} columns={columns}>
              <SortingState defaultSorting={[{ }]}/>
              <IntegratedSorting />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <Table />
              <VirtualTable 
                height="400px" 
                rowComponent={TableRow} 
                tableComponent={TableComponent} 
                columnExtensions={tableColumnExtensions} 
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddAlgorithm} color="primary" variant="contained">EKLE</Button>
          <Button onClick={toggle} color="primary">Kapat</Button>
        </DialogActions>
    </Dialog>
  );
}

export default QuestionArchiveDialog;