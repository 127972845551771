import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Adı' },
  { name: 'email', title: 'E-posta' },
  { name: 'type', title: 'Üyelik' },
  { name: 'isActive', title: 'Durumu' },
];

let allUsers = [];

const UserListTableSmall = (props) => {

  const { onClick, userList, listDisplay } = props;
  if (listDisplay === "program") {
    columns[5].title = "1.Ders";
  } else {
    columns[5].title = "Durumu";
  }
  const [users, setUsers] = useState([{}]);
  const [row, setRow] = useState([{}]);
  const [totalSummaryItems] = useState([
    { columnName: 'name', type: 'count' },
  ]);

  useEffect(() => {
    allUsers = userList;
    setUsers(allUsers);
    setRow(rows(allUsers));
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 85 },
    { columnName: 'id', width: 90 },
    { columnName: 'name', width: 120 },
    { columnName: 'email', width: 225 },
    { columnName: 'type', width: 115 },
    { columnName: 'isActive', width: 110 },

  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedUser = users.find(s => s.user && s.user._id === row.id);
        // setUser(selectedUser);
        // console.log(selectedUser);
        onClick(selectedUser);
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );

  const rows = (users) => {
    var no = users.length + 1;
    return (
      users.map(s => {
        no -= 1;
        var id = s.user && s.user._id;
        var name = s.user && s.user.name;
        var email = s.user && s.user.email;
        var sub = s.user && s.user.activeSubscription;
        var type = sub && sub.userType && new Date(sub.endDate) > new Date() ? "Premium 💎" : sub && sub.userType ? "Freemium P" : "Freemium";
        var isActive = s.isActive ? "Sırada" : "Tamamladı ✔"
        return ({
          no,
          id,
          name,
          email,
          type,
          isActive
        })
      })
    )
  }

  return (
    users.length > 0 ? 
    <Paper>
      <Grid rows={row} columns={columns}>
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          height="calc(100vh - 230px)"
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <SummaryState
          totalItems={totalSummaryItems}
        />
        <IntegratedSummary />
        <TableSummaryRow />
      </Grid>
    </Paper>
    :
    <CircularProgress />
  );
}

export default UserListTableSmall;