import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { updateUser, createSubscription, getUserById, getUserSubscriptions } from '../../services/userServices';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { dateLocal } from '../../services/helperFunctions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SweetAlert from "react-bootstrap-sweetalert";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import moment from 'moment';

const subscriptions = [0.03, 0.25, 0.5, 1, 3, 6, 12];

const UserInfo = (props) => {
  const { user } = props;

  const [months, setMonths] = useState();
  const [alert, setAlert] = useState(null);
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false);
  const [newUser, setNewUser] = useState(user);
  const [open, setOpen] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  useEffect(() => {
    getUserById({ id: user._id})
    .then(res=> {
      if (res.data && res.data.code === 200) {
        setNewUser(res.data.data);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    getUserSubscriptions({ id: user._id })
    .then(res=> {
      if (res.data && res.data.code === 200) {
        let userSubs = res.data.data;
        setUserSubscriptions(userSubs);
        console.log(userSubs);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const onAddSubscription = () => {
    // add subs
    toggleAddSubscription();
    // if freemium => premium : membership starts today, initial date (signup date not changed)
    let subData = {id: user._id, userType: 1, signupDate: user.activeSubscription.startDate, months: months}  
    if (user.activeSubscription.userType === 1) { 
      if (new Date(user.activeSubscription.endDate) > new Date ()) {
        // add date to existing premium membership ending date : early renewal
        subData.startDate = new Date(user.activeSubscription.endDate);
      } // else no start date, start from today
    } 
    createSubscription(subData)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        let activeSubscription = res.data.data;
        console.log("created Subscription: ", activeSubscription);
        // update user data
        let updatedData = { id: user._id, activeSubscription: activeSubscription._id }
        updateUser(updatedData)
        .then((res) => {
          if (res.data && res.data.code === 200) {
            let newData = res.data.data;
            console.log("user data updated: ", newData);
            setNewUser(newData);
            user.activeSubscription.endDate = newData.activeSubscription.endDate;
            autoCloseAlert();
            // return res.data.data;
          } else {
            console.log("user data update error!")
          }
        })
      } else {
        console.log("create subscription error!")
      }
    })
  }

  const toggleAddSubscription = () => {
    setOpenSubscriptionDialog(!openSubscriptionDialog);
  }

  const handleChange = event => setMonths(event.target.value);

  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert 
        success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" 
        onConfirm={() => hideAlert()} showConfirm={false}
      >
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1500);
  };

  const hideAlert = () => setAlert(null);

  const cardStyle = {
    marginTop: -20, 
    borderRadius: 6, 
    borderWidth: 1, 
    // borderColor: '#F4F6FA', 
    // backgroundColor: '#F4F6FA',
  }
  const userStyle = {
    display:"flex", 
    flexDirection: "row", 
    alignItems: 'center', 
    backgroundColor: '#E4DFFF',
    padding: 5,
    justifyContent: 'space-between',
  }

  return (
    user ? 
    <>
      <Card style={cardStyle} onClick={() => setOpen(!open)}>
        <CardBody>
          <div style={userStyle}>
            <div>
              {newUser.avatar} <strong style={{ paddingLeft: 10 }} >{newUser.name}</strong>, {newUser.email}, {newUser.isFemale ? "Kadın, " : 'Erkek, '} 
              {newUser.activeSubscription && newUser.activeSubscription.userType && new Date(newUser.activeSubscription.endDate) > new Date() ? "Premium" : "Freemium"} üye, 
              Şifre: {newUser.password ? "🟢  " : "🔴  "}
              <div>
                Başlangıç Tarihi : {newUser.activeSubscription && dateLocal(newUser.activeSubscription.startDate)}
              </div>
              {userSubscriptions.map(s => {
                return (
                  <div key={s._id}>
                    {`Üyelik: ${s.userType ? "Premium" : "Fremium"} Bitiş:${dateLocal(s.endDate)}`}
                  </div>
                )
              })}
              {/* Bitiş tarihi: {newUser.activeSubscription && dateLocal(newUser.activeSubscription.endDate)}   */}
            </div>
            <div >
              <Button color="primary" variant="contained" onClick={toggleAddSubscription}>ÜYELİK EKLE</Button>
            </div>
          </div>
          <div style={{ padding: 5, backgroundColor: '#F4F6FA' }}>
            Giriş seri: {newUser.appStreak} Egzersiz seri: {newUser.exerciseStreak} ☘️{newUser.xp} 🎖{newUser.level} <br></br>
            Rozetler: {newUser.badges && newUser.badges.map((b, i) => {
              return <span key={i}>Rozet no:{b.no} {b.p1}/{b.p2} {b.count}X </span>
            })}
          </div>
          <div style={{ padding: 5, backgroundColor: '#E4DFFF' }}>
            Tooltips ({newUser.tooltips && newUser.tooltips.length}): {newUser.tooltips && newUser.tooltips.map((t, j) => {
              return <span key={j}>{t} </span>
            })}
            <br></br>
            Cihaz: <strong>{newUser.device}</strong>
          </div>
        </CardBody>
      </Card>
     
      <Dialog open={openSubscriptionDialog} onClose={toggleAddSubscription} fullWidth={false} maxWidth="xs">
        <DialogTitle id="form-dialog-title">{user.name} adlı kullanıcıya üyelik ekleyin</DialogTitle>
        
        <DialogContent>
          <div>
            <InputLabel required style={{marginBottom: 20}} id="select-type">Premium üyelik Ay:</InputLabel>
            <Select 
              labelId="label-type" id="type" value={months ? months : ""}
              onChange={handleChange} style={{width: 150}}
            >
              {subscriptions.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
            </Select>
          </div>

        </DialogContent>

        <DialogActions>
        <Button onClick={onAddSubscription} color="primary" variant="contained">EKLE</Button>
        <Button onClick={toggleAddSubscription} color="primary">KAPAT</Button>
        </DialogActions>
      </Dialog>
      {alert}
    </>
    : 
    <></>
  );
}

export default UserInfo;