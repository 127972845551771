import React from 'react';
/* eslint-disable no-unused-vars */
import UserListTable from './UserListTable';
import { useHistory } from 'react-router-dom';

const UserList = (props) => {

  const history = useHistory();

  const onUserClick = (user) => {
    history.push({ 
      pathname: '/admin/userdetails', 
      state: { user},
    })
  }

  return (
    <div style={{marginTop: -20 }}>
      <UserListTable 
        onClick={onUserClick} 
      />
    </div>
  );
}

export default UserList;