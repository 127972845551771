import React, { useState } from 'react';
import FormListTable from './FormListTable';

const FormList = (props) => {

  const [stats, setStats] = useState({});

  return (
    <div style={{marginTop: -20, marginLeft: -15, paddingLeft: -20 }}>
      <div style={{marginBottom: 10 }}>
        {Object.keys(stats).map((k, i) => {
          return (
            <span key={i}>
              {k}: <strong style={{paddingRight: 15}}>{stats[k]}</strong>
            </span>
          )
        })}
      </div>
      <FormListTable 
        stats={stats} setStats={setStats} 
      />
    </div>
  );
}

export default FormList;