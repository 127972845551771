import React from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateProgram } from '../../services/contentServices';

const UpdateProgramDialog = (props) => {

  const { open, toggle, fetch, program } = props;

  const onUpdateProgram = (event) => {
    event.preventDefault();
    toggle()
    var name = event.target.elements.programName.value;
    var id = program._id
    var data = { id, name };
    console.log(data)
    if (name && id) {
      updateProgram(data)
      .then((res)=>{
        if (res.data.code === 200) {
          console.log('Program adı revize edildi :', name);
          fetch()
        } 
      })
    }
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">Program adını güncelleyin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Mevcut program adı: {program.name}
        </DialogContentText>
        <form onSubmit={onUpdateProgram}>
          <TextField name="programName" autoFocus id="name" label="Yeni program adı" type="text" fullWidth/>
          <DialogActions>
            <Button type="submit" variant="contained" size="small">KAYDET</Button>
            <Button onClick={toggle} color="primary">Kapat</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UpdateProgramDialog;