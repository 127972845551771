import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getLessons, addLessonToProgram } from '../../services/contentServices';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const columns = [
  { name: 'no', title: 'Sıra' },
  { name: 'id', title: 'ID' },
  { name: 'branch', title: 'Branş' },
  { name: 'name', title: 'Ders Adı' },
  // { name: 'pages', title: 'Sayfalar' },
];

const ArchiveDialog2 = (props) => {

  const { open, toggle, fetchPrograms, setProgram, setLessons, program, setQuestionName, fetchProgram } = props;

  const [lessons2, setLessons2] = useState([{}]); // '2' archive lessons state 
  const [lesson, setLesson] = useState({ pages: [] });
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 90 },
    { columnName: 'id', width: 90 },
    { columnName: 'branch', width: 120 },
    { columnName: 'name', width: 280 },
    // { columnName: 'pages', width: 200 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  useEffect(()=>{
    getAllLessons()
  },[open]);

  const getAllLessons = () => {
    let time = new Date();
    getLessons()
    .then(res => {
      if (res.data && res.data.code === 200) {
        setLessons2(res.data.data);
      }
    })
  }
  
  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedLesson = lessons2.find(l => l._id === row.id);
        setLesson(selectedLesson);
        console.log(selectedLesson)
        if (setQuestionName) {
          setQuestionName({id: row.id, name: row.name})
        }
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );
  const rows = (lessons2) => {
    var no = 0;
    return (
      lessons2.map(l => {
        no += 1;
        var branch = l.branch;
        var id = l._id
        var name = l.name;
        // if (l.pages) {
        //   if (l.pages.length) {
        //     var pages = `(${l.pages.length}) ${l.pages[0].screenName} ${l.pages.length > 1 ? "..." : ""}`
        //   } else {
        //     pages = "";
        //   }
        // } else {
        //   pages = "";
        // }
        return ({
          no: no,
          id: id,
          branch: branch,
          name: name,
          // pages: pages
        })
      })
    )
  }
  
  const onAddLesson = () => {
    toggle()
    if (program) {
      console.log(lesson.name, 'adlı ders,', program.name, 'programına ekleniyor...')
      // add lesson to program: program id, lesson id
      var lessonId = lesson._id
      // get lessons and push new lesson
      var lessons = program.lessons.map(l => l._id); // populate yapmadan sadece id
      lessons.push(lessonId)
      var id = program._id
      var data = { id, lessons }
      addLessonToProgram(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          console.log('Ders, programa eklendi:',res.data.data.lessons);
          // fetchPrograms();
          // setProgram(res.data.data) // update parent state 
          // setLessons(res.data.data.lessons) // update parent state 
          fetchProgram(program);
        } else {
          console.log('Sorun çıktı. Kod: ',res.data.code)
        }
      })
      .catch(err =>{
        console.log('İşlem başarısız: ', err)
      })
    }
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md" PaperComponent={PaperComponent}>
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
          {program ? "Programa Ders Ekleyin" : "Ders Arşivi" }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Arşivden Ders seçin ({lessons2.length}): <strong style={{background:'honeydew'}}>{lesson.name}</strong>
          </DialogContentText>
          <Paper>
            <Grid rows={rows(lessons2)} columns={columns}>
              <SortingState defaultSorting={[{ columnName: 'city', direction: 'asc' }]}/>
              <IntegratedSorting />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <Table />
              <VirtualTable 
                height="400px" 
                rowComponent={TableRow} 
                tableComponent={TableComponent} 
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddLesson} color="primary" variant="contained">EKLE</Button>
          <Button onClick={toggle} color="primary">Kapat</Button>
        </DialogActions>
    </Dialog>
  );
}

export default ArchiveDialog2;