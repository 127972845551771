import React, { useState } from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserListTable from '../../views/UserList/UserListTableSmall';
import { useHistory } from 'react-router-dom';
import UserDetailsDialog from '../Dialogs/UserDetailsDialog';

/* eslint-disable no-unused-vars */

const UserListProgramDialog = (props) => {

  const { open, toggle, program, lesson, users, listDisplay } = props;
  const history = useHistory();

  const [openUserDetailsDialog, setOpenUserDetailsDialog] = useState(false);
  const toggleUserDetailsDialog = () => setOpenUserDetailsDialog(!openUserDetailsDialog);
  const [user, setUser] = useState();

  const onUserClick = (u) => {
    setUser({ _id: u.user._id})
    toggleUserDetailsDialog()
  }
  
  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
      <DialogTitle id="form-dialog-title">
        {listDisplay === "program" ?
          <>
           {program.name} adlı programın
          </>
        :
          <>
           {lesson.name} adlı dersin
          </>
        }
        {` gittiği kullanıcılar`}
      </DialogTitle>

      <DialogContent>
        <UserListTable onClick={onUserClick} userList={users} listDisplay={listDisplay}/>
        <DialogActions>
          <Button onClick={toggle} color="primary" variant="contained">KAPAT</Button>
        </DialogActions>
      </DialogContent>
      <UserDetailsDialog 
        open={openUserDetailsDialog} toggle={toggleUserDetailsDialog} user={user} 
      />
    </Dialog>
  );
}

export default UserListProgramDialog;