import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { addLesson, addLessonToProgram, updateLesson } from '../../services/contentServices';
const lessonProcesses = ["Ders", "Takip"];
const branches = ["Psikoloji", "Beslenme", "Egzersiz", "Genel", "Yaşam Tarzı"];
// const lessonTypes = ["Çözüm içeriği", "Özel durum", "Blog", "Anket"]

const LessonDialog = (props) => {

  const { open, toggle, program, lesson, lessonIndex, setLesson, setPages, fetchProgram, fetchLesson } = props;
  const [process, setProcess] = useState(lessonProcesses[0]);
  const [branch, setBranch] = useState("Genel");

  useEffect(() => {
    if (lesson.name) {
      setProcess(lesson.process);
      setBranch(lesson.branch);
    } else {
      setProcess(lessonProcesses[0])
      setBranch("Genel");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, lesson])

  const handleChangeProcess = event => setProcess(event.target.value);
  const handleChangeBranch = event => setBranch(event.target.value);
  
  const onAddLesson = (event) => {
    event.preventDefault();
    toggle()
    var name = event.target.elements.lessonName.value;
    var data = { name, branch, process };
    if (!lesson._id) {
      // create lesson
      addLesson(data)
      .then((res)=>{
        if (res.data.code === 200) {
          console.log('Ders oluşturuldu :', name);
          // add lesson to program: program id, lesson id
          var lessonId = res.data.data._id
          // get lessons and push new lesson
          var lessons = program.lessons.map(l => l._id) // populate yapmadan sadece id
          lessons.push(lessonId )
          var id = program._id
          let data2 = { id, lessons }
          addLessonToProgram(data2)
          .then(res => {
            if (res.data && res.data.code === 200) {
              console.log('Ders, programa eklendi:', res.data.data.lessons);
              // fetch();
              // setProgram(res.data.data) // update parent state 
              // setLessons(res.data.data.lessons) // update parent state 
              // or
              fetchProgram(program);
            } else {
              console.log('Sorun çıktı. Kod: ',res.data.code)
            }
          })
          .catch(err => {
            console.log('İşlem başarısız: ', err)
          })
        } 
      })
    } else {
      // update lesson
      data = {...data, id: lesson._id}
      updateLesson(data)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          let updatedLesson = res.data.data
          console.log('Lesson güncellendi :', updatedLesson);
          //fetch();
          // or
          fetchProgram(program);
          fetchLesson(updatedLesson);
        } else {
          console.log('Hata oluştu :', res.data.message, res.data.code);
        }
      })
    }
  }

  const onDeleteLesson = () => {
    // delete lesson from program (with update program)
    // there can be two identical id lessons in a program (use index) !!
    if (window.confirm(`${lesson.name} dersi, programdan çıkarılacak, emin misiniz?`)) {
      toggle();
      console.log("lessonIndex", lessonIndex);
      var updatedLessons = program.lessons.filter((l, i) => i !== lessonIndex);
      console.log("onDeleteLesson updatedLessons1: ", updatedLessons);
      updatedLessons = updatedLessons.map(l => l._id) // populate yapmadan sadece id
      var id = program._id;
      var data = { id, lessons: updatedLessons }
      console.log("onDeleteLesson updatedLessons2: ", updatedLessons);

      addLessonToProgram(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          console.log('Ders, programdan silindi:', res.data.data.lessons);
          console.log("ondeletelesson - program:", program);
          setLesson({ pages: [] })
          // fetch();
          fetchProgram(program);

          setPages([]);
        } else {
          console.log('Sorun çıktı. Kod: ',res.data.code)
        }
      })
      .catch(err => {
        console.log('İşlem başarısız: ', err)
      })
    }
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">{lesson._id ? "Dersi Güncelleyin" : "Yeni Ders"}</DialogTitle>
        <DialogContent>
          <form onSubmit={onAddLesson}>
            
            <div style={{display:"flex", flexDirection: "row"}}>
              <div>
                <InputLabel required style={{marginTop: 20}} id="select-process">Ders İşleyişi</InputLabel>
                <Select labelId="label-process" id="process" value={process ? process : ""}
                  onChange={handleChangeProcess} style={{width: 150}}>
                  {lessonProcesses.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
              <div style={{marginTop: 20, marginLeft: 20 }}>
                <InputLabel required  id="select-branch">Branş</InputLabel>
                <Select 
                  autoFocus labelId="label-branch" id="branch" value={branch ? branch : ""}
                  onChange={handleChangeBranch} style={{width: 150}}
                >
                  {branches.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
            </div>

            <TextField 
              name="lessonName" autoFocus id="name" label="Ders Adı" type="text" fullWidth required multiline
              defaultValue={lesson.name ? lesson.name : ""} style={{marginTop: 10}}
            />
            <DialogActions style={{marginTop: 20}}>
              <Button 
                type="submit" color="primary" variant="contained">{lesson._id ? "GÜNCELLE" : "OLUŞTUR"}
              </Button>
              {lesson._id && 
                <Button color="secondary" onClick={onDeleteLesson}>SİL</Button>
              }
              <Button onClick={toggle} color="primary">Kapat</Button>
            </DialogActions>
          </form>
        </DialogContent>
    </Dialog>
  );
}

export default LessonDialog;