import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import { createFoods, getFoods, updateFood, createFood, createFoodRecepie, createRecepie, updateFoodRecepie, updateRecepie } from '../../services/nutritionServices'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow, TableEditRow,
  TableEditColumn, } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, DataTypeProvider } from '@devexpress/dx-react-grid';
import Paper from '@material-ui/core/Paper';
import { EditingState } from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const getRowId = row => row.id;

const columns = [
  { name: 'id', title: 'id' },
  { name: 'no', title: 'no' },
  { name: 'group', title: 'group' },
  { name: 'name', title: 'name' },
  { name: 'cooked', title: 'cooked' },
  { name: 'description', title: 'description' },
  { name: 'mealgroup', title: 'mealgroup' },
  { name: 'image', title: 'image' },
  { name: 'amount1', title: 'amount1' },
  { name: 'unit1', title: 'unit1' },
  { name: 'amount2', title: 'amount2' },
  { name: 'unit2', title: 'unit2' },
  { name: 'v', title: 'v' },
  { name: 'protein', title: 'protein' },
  { name: 'carb', title: 'carb' },
  { name: 'fat', title: 'fat' },
  { name: 'cal', title: 'cal' },
  { name: 'season', title: 'season' },
  { name: 'ketogenic', title: 'ketogenic' },
  { name: 'vegetarian', title: 'vegetarian' },
  { name: 'vegan', title: 'vegan' },
  { name: 'pregnant', title: 'pregnant' },
  { name: 'breastfeeding', title: 'breastfeeding' },
  { name: 'cholesterol', title: 'cholesterol' },
  { name: 'diabetes', title: 'diabetes' },
  { name: 'pcos', title: 'pcos' },
  { name: 'insulinresistance', title: 'insulinresistance' },
  { name: 'hypothyroidism', title: 'hypothyroidism' },
  { name: 'hashimoto', title: 'hashimoto' },
  { name: 'bloodpressure', title: 'bloodpressure' },
  { name: 'bloodthinner', title: 'bloodthinner' },
  { name: 'cortisone', title: 'cortisone' },
  { name: 'diuretic', title: 'diuretic' },
  { name: 'lactose', title: 'lactose' },
  { name: 'seafood', title: 'seafood' },
  { name: 'gluten', title: 'gluten' },
  { name: 'constipation', title: 'constipation' },
  { name: 'diarrhea', title: 'diarrhea' }
];

const Foods = () => {
  
  const [foods, setFoods] = useState([{}]);
  // const [rows, setRows] = useState([{}]);
  const [editingRowIds, setEditingRowIds] = useState([]);

  useEffect(() => {
    
    // CREATE FOOD DATABASE
    // createFoods();

    getFoods()
    .then((res)=>{
      if (res.data.code === 200) {
        setFoods(res.data.data.reverse());
        // setRows(rows1(res.data.data));
      } 
    });

  }, [])

  const rows = (foods) => {
    return (
      foods.map((f, i) => {
        return ({
          id: f._id,
          no: foods.length - i,
          group: f.group, 
          name: f.name,
          cooked: f.cooked, 
          description: f.description, 
          mealgroup: f.mealgroup, 
          image: f.image,
          amount1: f.amount1,
          unit1: f.unit1,
          amount2: f.amount2,
          unit2: f.unit2,
          v: f.v,
          protein: f.protein,
          carb: f.carb,
          fat: f.fat,
          cal: f.cal,
          season: f.season,
          ketogenic: f.ketogenic ? 1 : 0,
          vegetarian: f.vegetarian ? 1 : 0,
          vegan: f.vegan ? 1 : 0,
          pregnant: f.pregnant ? 1 : 0,
          breastfeeding: f.breastfeeding ? 1 : 0,
          cholesterol: f.cholesterol ? 1 : 0,
          diabetes: f.diabetes ? 1 : 0,
          pcos: f.pcos ? 1 : 0,
          insulinresistance: f.insulinresistance ? 1 : 0,
          hypothyroidism: f.hypothyroidism ? 1 : 0,
          hashimoto: f.hashimoto ? 1 : 0,
          bloodpressure: f.bloodpressure ? 1 : 0,
          bloodthinner: f.bloodthinner ? 1 : 0,
          cortisone: f.cortisone ? 1 : 0,
          diuretic: f.diuretic ? 1 : 0,
          lactose: f.lactose ? 1 : 0,
          seafood: f.seafood ? 1 : 0,
          gluten: f.gluten ? 1 : 0,
          constipation: f.constipation ? 1 : 0,
          diarrhea: f.diarrhea ? 1 : 0
        })
      })
    )
  }

  const [defaultColumnWidths] = useState([
    { columnName: 'id', width: 80 },
    { columnName: 'no', width: 80 },
    { columnName: 'group', width: 120 },
    { columnName: 'name', width: 150 },
    { columnName: 'cooked', width: 90 },
    { columnName: 'description', width: 90 },
    { columnName: 'mealgroup', width: 90 },
    { columnName: 'image', width: 90 },
    { columnName: 'amount1', width: 95 },
    { columnName: 'unit1', width: 120 },
    { columnName: 'amount2', width: 95 },
    { columnName: 'unit2', width: 90 },
    { columnName: 'v', width: 70 },
    { columnName: 'protein', width: 70 },
    { columnName: 'carb', width: 70  },
    { columnName: 'fat', width: 70  },
    { columnName: 'cal', width: 70 },
    { columnName: 'season', width: 90 },
    { columnName: 'ketogenic', width: 90 },
    { columnName: 'vegetarian', width: 90 },
    { columnName: 'vegan', width: 90 },
    { columnName: 'pregnant', width: 90 },
    { columnName: 'breastfeeding', width: 90 },
    { columnName: 'cholesterol', width: 90 },
    { columnName: 'diabetes', width: 90 },
    { columnName: 'pcos', width: 90 },
    { columnName: 'insulinresistance', width: 90 },
    { columnName: 'hypothyroidism', width:80  },
    { columnName: 'hashimoto', width: 90 },
    { columnName: 'bloodpressure', width: 90 },
    { columnName: 'bloodthinner', width: 90 },
    { columnName: 'cortisone', width: 90 },
    { columnName: 'diuretic', width: 90 },
    { columnName: 'lactose', width: 90 },
    { columnName: 'seafood', width: 90 },
    { columnName: 'gluten', width: 90 },
    { columnName: 'constipation', width: 90 },
    { columnName: 'diarrhea', width: 90 }

  ])

  const [defaultHiddenColumnNames] = useState(['id', 'description', 'mealgroup', 'image']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        //
      }}
      // style={{
      //   cursor: 'pointer'
      // }}
    />
  );

  const commitChanges = (e) => {
    const { changed, added } = e;
    if (changed) {
      const id = editingRowIds[0];
      const d = changed[id];
      // update calorie
      if ((d.protein || d.carb || d.fat) && !d.cal) {
        d.cal = d.protein * 4 + d.carb * 4 + d.fat * 9;
      }
      const data = { id, ...d }
      updateFood(data)
      .then((res)=> {
        if (res.data && res.data.code === 200){
          console.log("Besin güncelleme başarılı");
          let index = foods.map( f => f._id).indexOf(res.data.data._id)
          if (index > -1) {
            setFoods([
              ...foods.slice(0, index), 
              res.data.data,
              ...foods.slice(index + 1)
            ])
          };
          // update food recepie
          updateFoodRecepie(d)
          .then(res1 => {
            updateRecepie({ id, ...res1 })
            .then((res)=> {
              if (res.data && res.data.code === 200) {
                console.log("food recepie updated");
              }
            })
          })
          
        } else {
          console.log("Besin güncelleme hatası")
        }
      });
    }
    if (added) {
      const data = {
        group: added[0].group,
        name: added[0].name && added[0].name.trim(),   
        cooked: added[0].cooked || "çiğ",
        description: added[0].description,
        mealgroup: added[0].mealgroup,
        image: added[0].image,
        amount1: added[0].amount1 || 1,
        unit1: added[0].unit1 || "adet",
        amount2: added[0].amount2 || 100,
        unit2: added[0].unit2 || "gr",
        v: added[0].v || 0,
        protein: added[0].protein || 0,
        carb: added[0].carb || 0,
        fat: added[0].fat || 0,
        cal: added[0].cal || parseFloat(added[0].protein)*4 + parseFloat(added[0].carb)*4 + parseFloat(added[0].fat)*9,
        season: added[0].season || "Hepsi",
        ketogenic: added[0].ketogenic || 1,
        vegetarian: added[0].vegetarian || 1,
        vegan: added[0].vegan || 1,
        pregnant: added[0].pregnant || 1,
        breastfeeding: added[0].breastfeeding || 1,
        cholesterol: added[0].cholesterol || 1,
        diabetes: added[0].diabetes || 1,
        pcos: added[0].pcos || 1,
        insulinresistance: added[0].insulinresistance || 1,
        hypothyroidism: added[0].hypothyroidism || 1,
        hashimoto: added[0].hashimoto || 1,
        bloodpressure: added[0].bloodpressure || 1,
        celiac: added[0].celiac || 1,
        bloodthinner: added[0].bloodthinner || 1,
        cortisone: added[0].cortisone || 1,
        diuretic: added[0].diuretic || 1,
        lactose: added[0].lactose || 1,
        seafood: added[0].seafood || 1,
        gluten: added[0].gluten || 1,
        constipation: added[0].constipation || 1,
        diarrhea: added[0].diarrhea || 1
      }
      createFood(data)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          let newFood = res.data.data;
          console.log('Yeni besin oluşturuldu :' , newFood);
          setFoods([res.data.data, ...foods]);
          createFoodRecepie(newFood)
          .then((res) => {
            createRecepie(res)
            .then(r => {
              if (r.data && r.data.code === 200) {
                let food = r.data.data;
                console.log("food recepie created: ", food.name);
              } else {
                console.log("food recepie *** problem ***: ", newFood.name)
              }
            })
          })
        } else {
          console.log('. *** Besin oluşturma hatası *** : ', data.name);
        }
      })
    }
  }

  return (
    <div style={{marginLeft: -20, marginTop: -20}}>
      <Grid 
        rows={rows(foods)} 
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          onCommitChanges={commitChanges}
        />
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          height="700px" 
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <TableEditRow />
        <TableEditColumn
          showAddCommand
          showEditCommand
        />
      </Grid>
    </div>
  );
}

export default Foods;