import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserById, getUserMealgroups, getUserMealplans, getNutritionSettings } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserNutrition = (props) => {
  const { user } = props;

  const [userData, setUserData] = useState([]);
  const [mealgroups, setMealgroups] = useState([]);
  const [mealplans, setMealplans] = useState([]);
  const [open, setOpen] = useState(false);
  const [settings, setSettings] = useState({});

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => {
    getUserById({ id: user._id})
    .then(res => {
      if (res.data && res.data.code === 200) {
        let allUserData = res.data.data;
        setUserData(allUserData);
        getUserMealgroups({ userId: user._id })
        .then(res => {
          if (res.data && res.data.code === 200) {
            let allMealGroups = res.data.data;
            setMealgroups(allMealGroups);
            // console.log(allMealGroups);
            getUserMealplans({ id: user._id, week: allUserData.mealPlanWeek})
            .then(res => {
              if (res.data && res.data.code === 200) {
                let allMealplans = res.data.data;
                setMealplans(allMealplans);
                // let oneWeek = new Date()
                // oneWeek.setDate(oneWeek.getDate() - 7);
                // getUserMealdiaries({ id: user._id, date: oneWeek})
                // .then(res => {
                //   if (res.data && res.data.code === 200) {
                //     let allMealdiaries = res.data.data;
                //     setMealdiaries(allMealdiaries);
                //   }
                // })
              }
            });
            getNutritionSettings({ userId: user._id })
            .then(res => {
              if (res.data && res.data.code === 200) {
                let nutritionSettings = res.data.data;
                // console.log(nutritionSettings);
                setSettings(nutritionSettings)
              }
            });
          }
        })
      }
    })
  }

  const containerStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
  }
  const mealStyle = {
    borderRadius: 6, 
    borderWidth: 1, 
    borderColor: '#F4F6FA', 
    backgroundColor: '#F4F6FA',
    padding: 6,
    width: '95%',
    marginBottom: 5,
  }

  const days = ["Pzt", "Salı", "Çrş", "Prş", "Cuma", "Cmt", "Paz"];

  const showBudget = (budget) => {
    switch (budget) {
      case 2: return "Orta"; 
      case 3: return "Tüm";
      default: return "Düşük";
    }
  }

  const typeOrder = (typeArr) => {
    if (typeArr.length) {
      let text = "";
      typeArr.map((t, i) => text += t.name + (i < typeArr.length - 1 ? " - " : "") );
      return text;
    } else return "(Std) Klasik - Pratik - Fit - Türk M. - Dünya M. - Lezzetli"
  }

  const foodList = (foods) => {
    if (foods.length) {
      let text = "";
      foods.map((f, i) => text += f.name + (i < foods.length - 1 ? " , " : "") );
      return text;
    } else return " - "
  }

  const mealGrp = (m) => {
    if (m.length) {
      let text = "";
      m.map((f, i) => text += f + (i < m.length - 1 ? ", " : "") );
      return text;
    } else return " - "
  }

  return (
    <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
      <GridItem style={{width: '100%'}}>
        <Card style={containerStyle} >
          <CardBody style={{ overflowY: "scroll" }}>
            <h6>BESLENME PROGRAMI</h6>
            <div>
              {`Hafta : ${userData.mealPlanWeek} - Mealgroups : ${mealgroups[0] && mealgroups[0].rank && mealGrp(mealgroups[0].rank)}`}
            </div>
            <div>
              {settings && `Süre: ${settings.time}dk,  Bütçe: ${showBudget(settings.budget)},  Öğün Düzeni: ${settings.meals ? settings.meals : " - "},  Sıralama: ${settings.time && typeOrder(settings.type)}`}<br></br>
              {settings && `Hariç Yiyecekler: ${settings.time && foodList(settings.foods)}`}
            </div>

            <GridContainer style={{ marginLeft: 15 }}>
              {days.map((d, n) => {
                return (
                  <GridItem key={d} style={{width: '16.4%', marginLeft: -30}}>
                    <h6>{d}</h6>
                    <div>
                      {mealplans.map((meal, i) => {
                        if (i < (n + 1) * mealplans.length / 7 && i >= n * mealplans.length / 7) {
                          return (
                            <div key={i} style={{...mealStyle, backgroundColor: i % 2 ? "#F4F6FA" : "#E4DFFF" }}>
                              <strong>{meal.meal.toUpperCase()}</strong>
                              {meal.plates.map((p, j) => {
                                return (
                                  <div key={j}>
                                    {p.recepie.name} <br></br>
                                    {p.amount} {p.recepie.unit}
                                  </div>
                                )
                              })}
                            </div>
                          )
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </GridItem>
                )
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default UserNutrition;