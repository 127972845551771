import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import App from './app'
import { Provider } from 'jotai';

ReactDOM.render(
  <Provider>
    <App />
  </Provider>,
  document.getElementById("root")
);
