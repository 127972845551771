import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow, TableEditRow, TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary} from '@devexpress/dx-react-grid';
import { getCoaches, updateCoach } from '../../services/coachServices';
import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { getWebForms } from '../../services/webUserServices';
import { getPayments } from '../../services/userServices';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: '#E4DFFF'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'name', title: 'Ad' },

  { name: 'isActive', title: 'Aktif' },
  { name: 'forms', title: 'Formlar' },
  { name: 'payments', title: 'Danışanlar' },
  { name: 'score', title: 'Skor' },

  { name: 'title', title: 'Ünvan' },
  { name: 'phone', title: 'Telefon' },
  { name: 'email', title: 'Eposta' },
  { name: 'image', title: 'Foto' },
  { name: 'cv', title: 'CV' },
  { name: 'bio', title: 'Biografi' },
  { name: 'university', title: 'Üniversite' },
  { name: 'courses', title: 'Kurs/Sertifika' },
  { name: 'expertise', title: 'Uzmanlıklar' },
  { name: 'iban', title: 'Iban' },
  { name: 'url', title: 'url' },
  { name: 'isBusy', title: 'Meşgul' },
 
];

let allCoaches = [];
let allForms = [];
let allPayments = [];
let loading = 0;

const getRowId = row => row.id || null;

const CoachListTable = (props) => {

  const { onClick, stats, setStats, setLoading, setCsvData } = props;
  const [coaches, setCoaches] = useState([{}]);

  const [row, setRow] = useState([{}]);
  const [editingRowIds, setEditingRowIds] = useState([]);

  const [totalSummaryItems] = useState([
    { columnName: 'date', type: 'count' },
  ]);

  const [tableColumnExtensions] = useState([
    // { columnName: 'bio', wordWrapEnabled: true },
    // { columnName: 'university', wordWrapEnabled: true },
    // { columnName: 'courses', wordWrapEnabled: true },
    // { columnName: 'expertise', wordWrapEnabled: true },
  ]);

  const setLoad = () => {
    loading++;
    setLoading(loading);
    // console.log(loading);
  }

  useEffect(() => {
    loading = 0;
    fetchCoaches();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchCoaches = () => {
    // const time = new Date();
    getCoaches()
    .then(res => {
      if (res.data && res.data.code === 200) {
        // console.log("loaded in ", (new Date() - time) / 1000, "sec");
        allCoaches = res.data.data.reverse()
        // setCoaches(allCoaches);
        // setRow(rows(allCoaches));
        console.log("coaches: ", allCoaches)
        getWebForms()
        .then(res => {
          if (res.data && res.data.code === 200) {
            allForms = res.data.data;
            // console.log("Forms: ", allForms);
            // forms => coaches
            for (let c = 0; c < allCoaches.length; c++) {
              const coachForms = allForms.filter(f => f.coach && f.coach.includes(allCoaches[c].email));
              allCoaches[c].forms = coachForms;
            }
            // setForms(allForms);
            
            getPayments()
            .then(res2 => {
              if (res2.data && res2.data.code === 200) {
                allPayments = res2.data.data;
                // payments => coaches
                for (let c = 0; c < allCoaches.length; c++) {
                  var coachPayments = allPayments.filter(f => f.coach && f.coach.includes(allCoaches[c].email));
                  coachPayments = coachPayments.filter(p => p.status === "success")
                  let paymentSum  = 0
                  for (let i = 0; i < coachPayments.length; i++){
                    paymentSum += coachPayments[i].amount;
                  }
                  allCoaches[c].payments = `${coachPayments.length} ad. ${paymentSum} TL`;
                  // calc rank
                  let formHours = 0;
                  if (allCoaches[c].forms) {
                    for (let i = 0; i < allCoaches[c].forms.length; i++) {
                      if (allCoaches[c].forms[i].status === "yeni") {
                        let hours = parseInt((new Date () - new Date(allCoaches[c].forms[i].date)) / (1000*60*60));
                        formHours += hours >= 16 ? hours : 0;
                        if (hours >= 16) {
                          console.log("hours >= 16: ", hours, allCoaches[c].name);
                        }
                      }
                    }
                  }
                  // if hours >= 24 then minus hours
                  allCoaches[c].score = parseInt(100 * (0.1 + paymentSum / 249) / allCoaches[c].forms.length) - formHours || 0
                  // if forms < 7 then add 100 / forms 
                  // allCoaches[c].score += allCoaches[c].forms.length < 7 ? parseInt(100 / (allCoaches[c].forms.length || 1)) : 0
                }

                setCoaches(allCoaches);
                setLoad();
                let coachSummary = allCoaches
                for (let i = 0; i < coachSummary.length; i++) {
                  delete coachSummary[i].bio;
                  delete coachSummary[i].university;
                  delete coachSummary[i].courses;
                  delete coachSummary[i].image;
                  delete coachSummary[i].cv;
                  delete coachSummary[i].expertise;
                  delete coachSummary[i].iban;
                  delete coachSummary[i].id;
                  delete coachSummary[i].phone;
                  delete coachSummary[i].isBusy;
                }
                setCsvData(rows(coachSummary));
                // setCsvData(rows(allCoaches));
    
              }
            })

          } else {
            console.log("sorun: ", res)
          }
        })

      } else {
        console.log("sorun: ", res)
      }
    })
  }
  
  const [defaultColumnWidths] = useState([    
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'date', width: 115 },
    { columnName: 'name', width: 125 },
    { columnName: 'email', width: 175 },
    { columnName: 'phone', width: 115 },

    { columnName: 'title', width: 135 },
    { columnName: 'image', width: 115 },
    { columnName: 'cv', width: 115 },
    { columnName: 'bio', width: 200 },
    { columnName: 'university', width: 200 },
    { columnName: 'courses', width: 200 },
    { columnName: 'expertise', width: 200 },
    { columnName: 'iban', width: 180 },
    { columnName: 'url', width: 180 },
    { columnName: 'isActive', width: 90 },
    { columnName: 'isBusy', width: 90 },
    { columnName: 'forms', width: 180 },
    { columnName: 'payments', width: 110 },
    { columnName: 'score', width: 100 },

  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        // var selectedForm = coaches.find(s => s._id === row.id);
        // onClick(selectedForm);
      }}
      style={{
        // cursor: 'pointer'
      }}
    />
  );

  const commitChanges = (e) => {
    const { changed, added } = e;
    if (changed) {
      const id = editingRowIds[0];
      const d = changed[id]
      const data = { id, ...d }
      console.log(data)
      updateCoach(data)
      .then((res)=> {
        if (res.data && res.data.code === 200){
          console.log("form update başarılı")
          let index = coaches.map( f => f._id).indexOf(res.data.data._id)
          if (index > -1) {
            setCoaches([
              ...coaches.slice(0, index), 
              res.data.data,
              ...coaches.slice(index + 1)
            ])
          }
        } else {
          console.log("form update hata: ", res.data)
        }
      });
    }
  }

  const rows = (coaches) => {
    var no = coaches.length + 1;
    return (
      coaches.map(s => {
        no -= 1;
        var id = s._id;
        const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit"    };
        const dateLocal = new Date(s.date).toLocaleDateString('tr-TR', dateOptions);
        var date = dateLocal.slice(0, 14);
        var forms = s.forms && s.forms.map((f, i) => f.status); // + (i < s.forms.length - 1 ? ", " : "")
        let formHours = 0;
        if (s.forms) {
          for (let i = 0; i < s.forms.length; i++) {
            if (s.forms[i].status === "yeni") {
              formHours += parseInt((new Date () - new Date(s.forms[i].date)) / (1000*60*60));
            }
          }
        }
        forms = s.forms && `${s.forms.length} ad ${formHours > 0 ? "Yeni s:" + formHours : ""} - ${forms}`
        return ({
          no,
          id,
          date,
          name: s.name, 
          phone: s.phone,
          email: s.email,
          title: s.title,
          image: s.image,
          cv: s.cv,
          bio: s.bio,
          university: s.university,
          courses: s.courses,
          expertise: s.expertise,
          iban: s.iban,
          url: s.url,
          isActive: s.isActive ? "true" : "false",
          isBusy: s.isBusy ? "true" : "false",
          forms: forms,
          payments: s.payments,
          score: s.score
        })
      })
    )
  }

  return (
    coaches[0]._id ? 
    <Paper>
      <Grid rows={rows(coaches)} columns={columns} getRowId={getRowId}>
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          onCommitChanges={commitChanges}
        />
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          columnExtensions={tableColumnExtensions} 
          height="calc(100vh - 100px - 60px)"
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <SummaryState
          totalItems={totalSummaryItems}
        />
        <IntegratedSummary />
        <TableSummaryRow />
        <TableEditRow />
        <TableEditColumn
          showEditCommand
        />
      </Grid>
    </Paper>
    :
    <CircularProgress />
  );
}

export default CoachListTable;