import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary, DataTypeProvider } from '@devexpress/dx-react-grid';
// import { getRegisteredUsers } from '../../services/userServices';
// import { dateLocal } from '../../services/helperFunctions';
// import Tooltip from '@material-ui/core/Tooltip';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Adı' },
  { name: 'email', title: 'E-posta' },
  // { name: 'point', title: 'Puan' },
  // { name: 'startDate', title: 'Kayıt' },
  // { name: 'days', title: 'Gün' },
  // { name: 'lastLogin', title: 'Son Giriş' },
  // { name: 'active', title: 'Aktif' },
];

// const activeText = (text) => {
//   let level = text && parseInt(text.split(".")[0]) || 0;
//   switch (level) {
//     case 1: return "Son 7 gün aktif, puan > 0";
//     case 2: return "Son 7 gün aktif, puan > 20";
//     case 3: return "Son 7 gün aktif, tekrar gelmiş";
//     case 4: return "Son 3 gün aktif, tekrar gelmiş, 1+ gündür üye";
//     case 5: return "Son 3 gün aktif, 7+ gündür üye";
//     default: return "Son 7 gün aktif değil";
//   }
// }
// const TooltipFormatter = ({ row: { active }, value }) => (
//   <Tooltip title={(
//     <span style={{ fontSize: 14, lineHeight: "20px" }}>
//       {`Aktiflik: ${active}`} <br/>
//       {activeText(active)}
//     </span>
//   )}
//   >
//     <span>
//       {value}
//     </span>
//   </Tooltip>
// );
// const CellTooltip = props => (
//   <DataTypeProvider
//     for={columns.map(({ name }) => name )}
//     formatterComponent={TooltipFormatter}
//     {...props}
//   />
// );

// let allUsers = [];

const UserListTable = (props) => {

  const { onClick, users } = props;
  // const [users, setUsers] = useState([{}]);
  const [row, setRow] = useState([{}]);
  const [totalSummaryItems] = useState([
    { columnName: 'name', type: 'count' },
  ]);

  useEffect(() => {
    // fetchUsers();

    setRow(rows(users.reverse()));

     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // const fetchUsers = () => {
  //   // let time = new Date()
  //   getRegisteredUsers()
  //   .then(res => {
  //     if (res.data && res.data.code === 200) {
  //       // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
  //       allUsers = res.data.data.reverse();
  //       setUsers(allUsers);
  //       setRow(rows(allUsers));
  //     }
  //   })
  // }
  
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 85 },
    { columnName: 'id', width: 85 },
    { columnName: 'name', width: 120 },
    // { columnName: 'startDate', width: 110 },
    // { columnName: 'point', width: 85 },
    { columnName: 'email', width: 240 },
    // { columnName: 'lastLogin', width: 100 },
    // { columnName: 'days', width: 80 },
    // { columnName: 'active', width: 130 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedUser = users.find(s => s._id === row.id);
        // setUser(selectedUser);
        // console.log(selectedUser);
        onClick(selectedUser);
      }}
      style={{
        cursor: 'pointer'
      }}
      
    />
  );

  const rows = (users) => {
    var no = users.length + 1;
    return (
      users.map(s => {
        no -= 1;
        var id = s._id;
        var name = s.name;
        // var point = parseInt(s.xp);
        var email = s.email;
        // var lastLogin = point > 0 ? Math.round((new Date() - new Date(s.lastLogin)) / (1000*60*60*24)) : "";
        // var startDate = s.date && s.date.slice(0,10);
        // startDate += dateLocal(startDate) === dateLocal(new Date()) ?  " ✳️" : "" ;
        // var days = Math.round((new Date() - new Date(s.date)) / (1000*60*60*24));
        // var active = lastLogin < 7 && point > 0 ? 1 : point > 0 ? 0 : " ";
        // active = active && point > 20 ? 2 : active;
        // active = active && point > 20 && days - lastLogin >= 1 ? 3 : active;
        // active = active && point > 20 && lastLogin <= 3 && days - lastLogin > 1 && days >= 1 ? 4 : active;
        // let dailyPoints = (point - 10) / days;
        // active = (active && point > 35 && lastLogin <= 3 && days >= 7) ? 5 + dailyPoints * .01  : active;
        // active = active > 5.9 ? 5.9 : active;
        return ({
          no,
          id,
          name,
          // startDate: startDate,
          // point: point > 0 ? point : " ",
          // email: point > 0 ? email : "",
          email,
          // lastLogin: lastLogin < 10 && point > 0 ? "0" + lastLogin : lastLogin,
          // days: point > 0 ? (days < 10 ? "0" + days : days) : "",
          // active: (active > 5 ? Math.round(active * 10) / 10 : active + (active > 0 ? "." : "")) + " " + "⭐️".repeat(active),
        })
      })
    )
  }

  return (
    users[0].name ? 
    <Paper>
      <Grid rows={row} columns={columns}>
        {/* <CellTooltip /> */}
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          height="calc(100vh - 235px)"
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <SummaryState
          totalItems={totalSummaryItems}
        />
        <IntegratedSummary />
        <TableSummaryRow />
      </Grid>
    </Paper>
    :
    <CircularProgress />
  );
}

export default UserListTable;