import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { getPrograms, updateProgram, updateLesson, addUserLesson, addUserLessons, getLesson, getProgramLessons, addLessonToProgram, getProgram } from '../../services/contentServices';
import { getProgramUsers, getLessonUsers, getUserAnswerByScreenId } from '../../services/userServices';
import ProgramDialog from '../../components/Dialogs/Program';
import LessonDialog from '../../components/Dialogs/Lesson';
import ScreenDialog from '../../components/Dialogs/Screen';
// import ArchiveDialog from '../../components/Dialogs/Archive';
import PageArchiveDialog from '../../components/Dialogs/PageArchive';
import AlgorithmDialog from '../../components/Dialogs/Algorithm';
import UpdateProgramDialog from '../../components/Dialogs/UpdateProgram';
import Archive2Dialog from '../../components/Dialogs/Archive2';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import SweetAlert from "react-bootstrap-sweetalert";
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AlgorithmProgramDialog from '../../components/Dialogs/AlgorithmProgram';
import UserListDialog from '../../components/Dialogs/UserListDialog';
import UserListProgramDialog from '../../components/Dialogs/UserListProgramDialog';
import { getRegisteredUsers } from '../../services/userServices';
import { CSVLink, CSVDownload } from "react-csv";
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';

var programUsers = [];
var lessonUsers = [];
var arr = [];
var arr2 = [];

const Content = () => {
  //const [alert, setAlert] = useState('');
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({ lessons: [] });
  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState({ pages: [] });
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState({});
  const [openProgramDialog, setOpenProgramDialog] = useState(false);
  const [openLessonDialog, setOpenLessonDialog] = useState(false);
  const [openScreenDialog, setOpenScreenDialog] = useState(false);
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openArchive2Dialog, setOpenArchive2Dialog] = useState(false);
  const [openPageArchiveDialog, setOpenPageArchiveDialog] = useState(false);
  const [openAlgorithmDialog, setOpenAlgorithmDialog] = useState(false);
  const [openUpdateProgramDialog, setOpenUpdateProgramDialog] = useState(false);
  const [openAlgorithmProgramDialog, setOpenAlgorithmProgramDialog] = useState(false);
  const [openUserListDialog, setOpenUserListDialog] = useState(false);
  const [openUserListProgramDialog, setOpenUserListProgramDialog] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState('Tümü');
  const [sortable, setSortable] = useState(false);
  const [alert, setAlert] = useState(null);
  const [user, setUser] = useState({});
  const [lessonIndex, setLessonIndex] = useState();
  const [screens, setScreens] = useState([{}]);
  const [progUsers, setProgUsers] = useState(0);
  const [lesUsers, setLesUsers] = useState(0);          // all users that lesson sent
  const [lesUsersComp, setLesUsersComp] = useState(0);  // users that completed lesson
  // const [programUsers, setProgramUsers] = useState([]);
  // const [lessonUsers, setLessonUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [users, setUsers] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvData2, setCsvData2] = useState([]);
  const [csvData3, setCsvData3] = useState([]);

  const [userAnswers, setUserAnswers] = useState([]);
  const [coach, setCoach] = useAtom(coachAtom);
  const [lessonStats, setLessonStats] = useState({});
  const [programStats, setProgramStats] = useState({});
  const [analyzeLesson, setAnalyzeLesson] = useState(false);
  const [analyzeProgram, setAnalyzeProgram] = useState(false);
  const [analyzeProgramC, setAnalyzeProgramC] = useState(0);
  const [analyzeLessonC, setAnalyzeLessonC] = useState(0);
  const [listDisplay, setListDisplay] = useState("");
  const chatBranch = ["Beslenme", "Psikoloji", "Egzersiz"];
  
  // get chat data
  const getChatData = () => {
    let data = { id: "60928b04db84297e556fec0d"}
    getProgramLessons(data)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        let progData = res.data.data.lessons;
        // console.log("chat program data: ", progData);
        let arr = [];
        for (let i = 0; i < progData.length; i++){
          let questions = progData[i].name.split("|");
          for (let j = 0; j < questions.length; j++) {
            questions[j] = questions[j].trim().toLowerCase();
            // clear words;
            let words = questions[j].split(" ");
            questions[j] = [];
            for (let k = 0; k < words.length; k++) {
              // clear signs
              words[k] = words[k].replace(/,/g, "").replace(/i̇/g, "i").replace(/\?/g, "").replace(/"/g, "");
              questions[j] += words[k] ? words[k] + " " : "";
            }
            questions[j] = questions[j].trim();
          }
          arr = [...arr, questions];
        }
        // console.log("chat program array: ", arr);
        setCsvData(arr);
      } else {
        console.log("Hata: ", res);
      }
    });
  }

  const getYearData = (id, year) => {
    getProgram({ id })
    .then((res) => {
      if (res.data && res.data.code === 200) {
        let data = res.data.data.lessons;
        for (let i = 0; i < data.length; i++ ) {  // ay
          for (let j = 0; j < data[i].pages.length; j++ ) {  // gün
            let post = data[i].pages[j];
            arr = [...arr, { 
              date: post.screenName + `.${year}`, 
              branch: post.branch, 
              title: post.title.replaceAll("\n", " ").replaceAll("\"", "'"), 
              likes: post.likes.length, 
              comments: post.comments.length 
            }]
          } 
        }
        // console.log(arr.length);
        setCsvData2(arr);
      } else {
        console.log("Hata: ", res);
      }
    });
  }
  const getCommunityData = () => {
    getYearData("60928f79db84297e556fec4d", 2021);
    getYearData("61c965e918d6bc11004c53e5", 2022);
  }

  const removeHtml = (text) => {
    return text.replace(/\n|"|,|;|<p>|<\/p>|<strong>|<\/strong>|&nbsp/g, "");
  }

  const getBlogData = () => {
    let blogs = programs.filter(p => p.name.includes("Blog "))
    for (let b = 0; b < blogs.length; b++) {
      getProgram({ id: blogs[b]._id })
      .then((res) => {
        if (res.data && res.data.code === 200) {
          let data = res.data.data.lessons;
          for (let i = 0; i < data.length; i++ ) {  // lesson
            let page = data[i].pages[0];
            arr2 = [...arr2, { 
              program: `${blogs[b].name}`, 
              blog:`${i < 9 ? "0" + (i + 1) : i  + 1}. ${data[i].name}`, 
              branch: data[i].branch, 
              title: page && page.title && removeHtml(page.title), 
              body: page && page.body && removeHtml(page.body), 
            }]
          }
          // console.log(arr2);
          setCsvData3(arr2);
        } else {
          console.log("Hata: ", res);
        }
      });
    }
  }

  useEffect(() => {
    if (true || (coach.name && coach.name.includes("Cihan"))) {
      getChatData()
      getCommunityData()
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if ((true || (coach.name && coach.name.includes("Cihan"))) && programs.length) {
      getBlogData()
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[programs]);

  useEffect(() => {
    fetchPrograms();
    getRegisteredUsers()
    .then(res => {
      if (res.data && res.data.code === 200) {
        // console.log("registered users ready")
        setUsers(res.data.data);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchPrograms = (prog) => {
    getPrograms()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let allPrograms = res.data.data;
        setPrograms(allPrograms);
        if (analyzeProgram) {
          // get program users
          let statsObj = {};
          var loadCount = 0;
          /*eslint no-loop-func: "off"*/
          for (let i = 0; i < allPrograms.length; i++) {
            let programId = allPrograms[i]._id;
            let data = { programId }
            getProgramUsers(data)
            .then((res) => {
              if (res.data && res.data.code === 200) {
                let programUsers = res.data.data.filter(u => u.user);
                statsObj[programId] = `${programUsers.length}`;
                loadCount++;
                setAnalyzeProgramC(loadCount);
                if ( i === allPrograms.length - 1) {
                  setProgramStats(statsObj);
                }
              } else {
                console.log("Hata: ", res);
              }
            });
          }

          // to refresh state
          // setProgUsers(0);
          // let data = { programId: program._id}
          // getProgramUsers(data)
          // .then((res) => {
          //   if (res.data && res.data.code === 200) {
          //     programUsers = res.data.data.filter(u => u.user);
          //     // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
          //     // console.log(netUsers.length, " program users");
          //     setProgUsers(programUsers.length);
          //   } else {
          //     console.log("Hata: ", res);
          //   }
          // });

        }

        if (program.name) {
          let refreshedProgram;
          if (prog) {
            // newly created program
            refreshedProgram = allPrograms.filter(p => p._id === prog._id);
            setLessons([]);
            setPages([]);
          } else {
            refreshedProgram = allPrograms.filter(p => p._id === program._id);
          }
          if (refreshedProgram[0]) {
            setProgram(refreshedProgram[0]);
          } else {
            setProgram({ lessons: [] });
          }
          if (refreshedProgram[0] && refreshedProgram[0].lessons && lesson.name) {
            setLessons(refreshedProgram[0].lessons);
            if (lesson && refreshedProgram[0].lessons) {
              let refreshedLesson = refreshedProgram[0].lessons.filter(l => l._id === lesson._id);
              if (refreshedLesson[0]) {
                setLesson(refreshedLesson[0])
                setPages(refreshedLesson[0].pages);
              };
            } 
          }
        }
      } else {
        console.log("hata: ", res);
        window.alert(`Bağlantı Hatası`);
      }
    })
  }

  const setActiveProgram = (p) => {
    // refresh program lessons in case lesson added to program from archive
    // fetchPrograms();
    console.log("Active program: ", p);
    setProgram(p);
    setLessons(p.lessons);
    setLesson({ pages: [] })
    setPages([]);
  }

  const setActiveLesson = (l) => {
    setLesson(l);
    setPages(l.pages);
    console.log('Seçilen ders: ', l)
    setPage({});
  }

  const onSetActivePage = (pg) => {
    setPage(pg);
    // console.log('Seçilen sayfa: ', pg);
    setUserAnswers([]);
    // if question page get userAnswers
    if (pg.type === "Çoklu seçmeli soru" || pg.type === "Tekli seçmeli soru" || pg.type === "Metin girişi" || pg.type === "Rakam girişi") {
      let data = { screenId: pg._id }
      getUserAnswerByScreenId(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          let allUserAnswers = res.data.data;
          setUserAnswers(allUserAnswers);
        }
      })
    }

    // open dialog and show details
    toggleScreenDialog();
  }

  const onNewProgram = () => {
    setProgram({ lessons: [] });
    setLessons([]);
    setPages([]);
    setTimeout(() => toggleProgramDialog(), 200);
  }
  const onNewLesson = () => {
    setLesson({ pages: [] });
    setPages([]);
    setPage({ choices: [] });
    setTimeout(() => toggleLessonDialog(), 200);
  }
  const onNewScreen = () => {
    setPage({ choices: [] });
    toggleScreenDialog();
  }


  const toggleProgramDialog = () => setOpenProgramDialog(!openProgramDialog);
  const toggleLessonDialog = () => setOpenLessonDialog(!openLessonDialog);
  const toggleScreenDialog = () => setOpenScreenDialog(!openScreenDialog);;
  const toggleArchive2Dialog = () => setOpenArchive2Dialog(!openArchive2Dialog);
  const togglePageArchiveDialog = () => setOpenPageArchiveDialog(!openPageArchiveDialog);
  const toggleAlgorithmDialog = () => setOpenAlgorithmDialog(!openAlgorithmDialog);
  const toggleUpdateProgramDialog = () => setOpenUpdateProgramDialog(!openUpdateProgramDialog);
  const toggleAlgorithmProgramDialog = () => setOpenAlgorithmProgramDialog(!openAlgorithmProgramDialog);
  const toggleUserListDialog = () => setOpenUserListDialog(!openUserListDialog);
  const toggleUserListProgramDialog = () => setOpenUserListProgramDialog(!openUserListProgramDialog);

  const onDeleteProgram = () => {
    if(window.confirm(`${program.name} programı silinecek, emin misiniz?`)) {
      var isActive = false;
      var id = program._id
      var data = { id, isActive };
      if (id) {
        updateProgram(data)
        .then(res => {
          if (res.data.code === 200) {
            setProgram({ lessons: [] });
            fetchPrograms();
            setLessons([]);
            autoCloseAlert()
          } else {
            console.log('Bir hata oluştu: ', res.data.code)
          }
        })
        .catch(err => {
          console.log('Hata: ', err)
        })
      }
    }
  }

  const onDeletePage = () => {
    console.log('Delete page:', page);
    if(window.confirm(`${page.screenName} sayfası dersten çıkarılacak, emin misiniz?`)) {
      var updatedPages = lesson.pages.filter((l, i) => i !== page.index);
      updatedPages = updatedPages.map(p => p._id) // populate yapmadan sadece id
      var id = lesson._id;
      var data = { id, pages: updatedPages }
      updateLesson(data)
      .then(res =>{
        if (res.data && res.data.code === 200) {
          console.log('Sayfa, dersten silindi:',res.data.data.pages);
          // fetchPrograms();
          fetchLesson(lesson);
          // setActiveLesson(res.data.data)  
          // setPages(res.data.data.pages)  
          autoCloseAlert()
          toggleScreenDialog();
        } else {
          console.log('Sorun çıktı. Kod: ',res.data.code)
        }
      })
      .catch(err => {
        console.log('İşlem başarısız: ', err)
      })
    }
  }

  const SortableItem = sortableElement(({value}) => <li style={{ cursor: 'ns-resize'}}>{value.name}</li>);
  const SortableItem2 = sortableElement(({value}) => <li style={{ cursor: 'ns-resize'}}>{value.screenName}</li>);
  const SortableContainer = sortableContainer(({children}) => {
    return <ol style={{ paddingLeft: 10 }}>{children}</ol>;
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    // change order of lessons
    var newLessons = arrayMove(lessons, oldIndex, newIndex)
    setLessons(newLessons)
    newLessons = newLessons.map(l => l._id);  // populate yapmadan sadece id gönder
    // update lessons in the program
    var id = program._id
    const image = program.image;  // update api bunu sildiği için
    // const decisions = program.decisions; // update api bunu sildiği için
    var data = { id, lessons: newLessons, image }
    // console.log(data);
    addLessonToProgram(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        let updatedProgram = res.data.data;
        console.log('Ders sıralaması güncellendi:',updatedProgram);
        // fetchPrograms()
        // setProgram(res.data.data) 
        // setLessons(res.data.data.lessons) 
        // setLesson({ pages: [] })
        // setPages([{}])
        setSortable(!sortable)
        autoCloseAlert()
        // SuccessAlert(setAlert);
        fetchProgram(updatedProgram);
      } else {
        console.log('Sorun çıktı. Kod: ',res.data.code)
      }
    })
    .catch(err => {
      console.log('İşlem başarısız: ', err)
    })
  };
  const onSortEnd2 = ({oldIndex, newIndex}) => {
    // change order of screens
    var newPages = arrayMove(pages, oldIndex, newIndex)
    setPages(newPages)
    // update pages in the lesson
    var id = lesson._id
    var data = { id, pages: newPages }
    updateLesson(data)
    .then(res =>{
      if (res.data && res.data.code === 200) {
        let updatedLesson = res.data.data;
        console.log('Sayfa sıralaması güncellendi:', updatedLesson.pages);
        // fetchPrograms()
        // setLesson(res.data.data) 
        fetchLesson(updatedLesson);
        autoCloseAlert()
        setSortable(!sortable)
      } else {
        console.log('Sorun çıktı. Kod: ',res.data.code)
      }
    })
    .catch(err =>{
      console.log('İşlem başarısız: ', err)
    })
  };

  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" onConfirm={() => hideAlert()} showConfirm={false}>
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1500);
  };
  const hideAlert = () => setAlert(null);

  const screenTypeColor = (screenType) => {
    switch (screenType) {
      case "Sistem": return "green";
      case "Bilgi ekranı": return "royalblue";
      case "Çoklu seçmeli soru": case "Tekli seçmeli soru": case "Metin girişi": case "Rakam girişi": return "salmon";
      default: return "darkslategray"; 
    }
  }

  // send lesson to a user
  const sendLesson = () => {
    // toggle userfeedbacks or user list table dialog
    // if no branch user will not see
    if (!chatBranch.includes(lesson.branch) && lesson.pages[0].ui === "Chat") {
      window.alert(`Bu dersin branşı "${lesson.branch}" ve bir Chat dersi olduğu için önce branş seçiniz!`)
    } else {
      toggleUserListDialog();  // select user
    }
  }
  const sendProgram = () => {
    setLesson({ pages: [] });
    toggleUserListDialog();  // select user
  }

  const onSendLesson = () => {
    if (!lesson._id) {
      // send program
      addUserLessons(program, user._id)
      .then(res => {
        toggleUserListDialog();
        autoCloseAlert();
        console.log("addUserLessons res: ", res);
      })

    } else {
      // send lesson
      addUserLesson(lesson._id, user._id, user.pushToken)
      .then(res => {
        toggleUserListDialog();
        autoCloseAlert();
        console.log("addUserLesson res: ", res);
      })
    }
  }

  const branchEmoji = (branch) => {
    switch (branch && branch.toLowerCase()) {
      case "beslenme": return "🥑";
      case "psikoloji": return "🦋";
      case "egzersiz": return "🏋🏻‍♀️";
      case "genel": return "🍀";
      default: return "☀️";
    }
  }

  const fetchLesson = (l, i) => {
    // get lesson data
    let data = { id: l._id}
    getLesson(data)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        let lessonData = res.data.data;
        setActiveLesson(lessonData); 
        // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        if (i >= 0) {
          setLessonIndex(i);
        }
      } else {
        console.log("Hata: ", res);
      }
    });

    // get lessons users
    // let time = new Date();
    // console.log("getting programs lessons users...");
    setLesUsers(0);
    setLesUsersComp(0);
    data = { lessonId: l._id}
    getLessonUsers(data)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        lessonUsers = res.data.data.filter(u => u.user);
        // netUsers = netUsers.sort((a, b) => (a.user.email > b.user.email ? 1 : -1));
        // netUsers.map(u => console.log(u.user.name, u.user.email));
        let completed = lessonUsers.filter(u => !u.isActive).length;
        setLesUsers(lessonUsers.length);
        setLesUsersComp(completed);
        // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        // console.log(netUsers.length, " program lesson users", completed, " completed %", parseInt(100 * completed / netUsers.length));
      } else {
        console.log("Hata: ", res);
      }
    });

  }

  const fetchProgram = (p) => {
    // console.log("fetch program ", p, analyzeLesson);
    setAnalyzeLessonC(0);
    // get program data
    // let time = new Date();
    var progData;
    let data = { id: p._id}
    getProgramLessons(data)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        progData = res.data.data;
        setActiveProgram(progData);
        // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        // console.log("selected program: ", progData)

        // get lessons users
        if (analyzeLesson) {
          let statsObj = {};
          var loadCount = 0;
          /*eslint no-loop-func: "off"*/
          for (let i = 0; i < progData.lessons.length; i++) {
            let lessonId = progData.lessons[i]._id;
            data = { lessonId }
            getLessonUsers(data)
            .then((res) => {
              if (res.data && res.data.code === 200) {
                let lessonUsers = res.data.data.filter(u => u.user);
                let completed = lessonUsers.filter(u => !u.isActive).length;
                statsObj[lessonId] = `${completed}/${lessonUsers.length}`;
                loadCount++;
                setAnalyzeLessonC(loadCount);
                if ( i === progData.lessons.length - 1) {
                  setLessonStats(statsObj);
                }
              } else {
                console.log("Hata: ", res);
              }
            });
          }
        }
        
      } else {
        console.log("Hata: ", res);
      }
    });

    // get programs users
    // let time = new Date();
    // console.log("getting programs users...");
    setProgUsers(0);
    data = { programId: p._id}
    getProgramUsers(data)
    .then((res) => {
      if (res.data && res.data.code === 200) {
        programUsers = res.data.data.filter(u => u.user);
        // console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        // console.log(netUsers.length, " program users");
        setProgUsers(programUsers.length);
      } else {
        console.log("Hata: ", res);
      }
    });
  }

  const cardStyle = {
    maxHeight: "calc(100vh - 100px)", 
    padding: 10,
    marginBottom: 0
  }

  const showUsers = () => {
    programUsers = programUsers.sort((a, b) => (a.user.email > b.user.email ? 1 : -1));
    setUserList(programUsers);
    setListDisplay("program");
    // open programs users modal
    toggleUserListProgramDialog();  
    // console.log(programUsers);
  }
  const showUsers2 = () => {
    lessonUsers = lessonUsers.sort((a, b) => (a.user.email > b.user.email ? 1 : -1));
    setUserList(lessonUsers);
    setListDisplay("ders");
    // open programs users modal
    toggleUserListProgramDialog();  
    // console.log(programUsers);
  }

  useEffect(() => {
    setLessonStats([]);
    if (analyzeLesson) {
      // console.log("fetchProgram...");
      fetchProgram(program);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[analyzeLesson]);

  useEffect(() => {
    // setLessonStats([]);
    // setLessons([]);
    // setProgram({ lessons: [] });
    
    if (analyzeProgram) {
      // console.log("fetchPrograms...");
      fetchPrograms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[analyzeProgram]);

  return (
    <GridContainer style={{marginTop: -40}}>
      <GridItem style={{margin:-20, width: '36%'}}>
        <Card style={cardStyle}>
          <h5>
              PROGRAMLAR 

              <Switch 
                checked={analyzeProgram} color="primary"
                onChange={() => {
                  setAnalyzeProgram(!analyzeProgram);
                  
                }} 
              />

              <span style={{ fontSize: 14 }}>
                Analiz 
                {analyzeProgram && programs.length &&
                  <>
                    (%{(100 * analyzeProgramC / programs.length).toFixed(0)})
                  </>
                }
              </span>
              
              <select name="progBranch" onChange={(e)=>setSelectedBranch(e.target.value)} 
                style={{ color: 'blue', padding: 5, marginBottom: 0, float: 'right'}}>
                <option value="Tümü">Tümü</option>
                <option value="Psikoloji">Psikoloji</option>
                <option value="Beslenme">Beslenme</option>
                <option value="Egzersiz">Egzersiz</option>
                <option value="Genel">Genel</option>
                {/* <option value="yaşam tarzı">yaşam tarzı</option> */}
              </select>
            </h5>
              <ButtonGroup variant="text" size="small" color="primary">
                <Button onClick={onNewProgram}>YENİ</Button>
                {program._id && 
                  <Button onClick={onDeleteProgram}>SİL</Button>
                }
                {program._id && 
                  <Button onClick={toggleProgramDialog}>DÜZENLE</Button>
                }
                {program._id &&
                  <Button onClick={toggleAlgorithmProgramDialog}>ALG.</Button>
                }
              </ButtonGroup>

          <CardBody style={{ overflowY: "scroll", marginTop: 5 }}>
              <ol style={{ paddingLeft: 5, paddingBottom: 5, marginTop: -10, marginRight: -12 }}>
                {programs.map(p => {
                  if ((p.branch === selectedBranch || selectedBranch === 'Tümü') && p.name) {
                    return (
                      <li 
                        // onClick={()=> fetchProgram(p)} 
                        style={{ backgroundColor: program._id === p._id ? "lightgreen" : "" }} key={p._id ? p._id : "1"}
                      >
                        <span
                          onClick={()=> fetchProgram(p)} 
                          style={{ cursor: 'pointer' }}
                        >
                          {`${branchEmoji(p.branch)} ${" "} ${p.description && p.image && p.lessons.length ? (p.decisions.length ? "✅" : "☑️") : "" } ${" "} ${p.name}`}
                        </span>

                        {analyzeProgram &&
                          <span style={{ display: "inline-block", float: "right", marginRight: 5 }}>
                            {programStats[p._id]}
                          </span>
                        }
                        
                        {program._id && p._id === program._id && 
                          <>
                            {progUsers > 0 &&
                              <IconButton 
                                onClick={showUsers} size="small" 
                                style={{ marginLeft: 8, padding: 0, float: "right", marginRight: 5 }}
                              >
                                <VisibilityIcon style={{ fontSize: 16 }} color="secondary" />
                                {!analyzeProgram &&
                                  <span style={{ fontSize: 15, paddingLeft: 1 }}>
                                    {progUsers}
                                  </span>
                                }
                              </IconButton>
                            }
                            <IconButton 
                              onClick={sendProgram} size="small" 
                              style={{marginLeft: 5, padding: 0, float: "right" }}
                            >
                              <SendIcon style={{ fontSize: 16 }} color="secondary" />
                            </IconButton>
                          </>
                        }

                      </li>
                    )
                  } else return null;
                })}
              </ol>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem style={{marginTop: -20, width: '38%'}}>
        <Card style={cardStyle}>
        <h5>
          DERSLER
          <Switch 
            checked={analyzeLesson} color="primary"
            onChange={() => {
              setAnalyzeLesson(!analyzeLesson);
              
            }} 
          />
          <span style={{ fontSize: 14 }}>
            Analiz 
            {analyzeLesson && lessons.length &&
              <>
                (%{(100 * analyzeLessonC / lessons.length).toFixed(0)})
              </>
            }
          </span>

          {(coach.name && coach.name.includes("Cihan")) && csvData.length && // true
            <span>
              <CSVLink 
                filename={"bewell-chatdata.csv"} data={csvData} 
                style={{ marginLeft: 20}}
              >
                ChatCSV
              </CSVLink>
              <CSVLink 
                filename={"bewell-communitydata.csv"} data={csvData2} 
                style={{ marginLeft: 10}}
              >
                CommCSV
              </CSVLink>
              <CSVLink 
                filename={"bewell-blogdata.csv"} data={csvData3} 
                style={{ marginLeft: 10}}
              >
                BlogCSV
              </CSVLink>
            </span>
            
          }
        </h5>
            <ButtonGroup variant="text" size="small" color="primary">
              {program._id && 
                <Button onClick={onNewLesson}>YENİ</Button>
              }
              {lesson._id && 
                <Button onClick={toggleLessonDialog}>DÜZENLE</Button>
              }
              {program._id && 
                <Button onClick={toggleArchive2Dialog}>ARŞİV</Button>
              }
              {program._id && 
                <Button onClick={()=>setSortable(!sortable)} variant={sortable ? "contained" : "text"}>SIRA</Button>
              }
              {lesson._id && 
                <Button onClick={toggleAlgorithmDialog}>ALG.</Button>
              }
            </ButtonGroup>
          <CardBody style={{ overflowY: "scroll", marginTop: 5 }}>
            
            {!sortable && 
              <ol 
                style={{ paddingLeft: 12, paddingBottom: 5, marginTop: -10, marginRight: -12 }}
              >
                {lessons !== [] ? 
                  lessons.map((l, i) => {
                    if (l) {
                      return (
                        <li 
                          onClick={()=> fetchLesson(l, i)} key={l._id + i}
                          style={{ cursor: 'pointer', backgroundColor: lesson._id && l._id === lesson._id ? "lightgreen" : "" }} 
                        >
                          {l.name ? (l.name.split("|").length > 1 ? `${l.name.split("|")[0]} (${l.name.split("|").length})`: l.name) : ""} 

                          <span style={{ display: "inline-block", float: "right", marginRight: 5 }}>
                            {lessonStats[l._id]}
                          </span>
                          
                          {lesson._id && l._id === lesson._id && 
                            <>
                              
                              {lesUsers > 0 &&
                                <IconButton 
                                  onClick={showUsers2} size="small" 
                                  style={{ marginLeft: 8, padding: 0, float: "right", marginRight: 5 }}
                                >
                                  <VisibilityIcon style={{ fontSize: 16 }} color="secondary" />
                                  {!analyzeLesson &&
                                    <span style={{ fontSize: 15, paddingLeft: 1 }}>
                                      {lesUsersComp}/{lesUsers}
                                    </span>
                                  }
                                </IconButton>
                              }
                              <IconButton 
                                onClick={sendLesson} size="small" 
                                style={{marginLeft: 5, padding: 0, float: "right" }}
                              >
                                <SendIcon style={{ fontSize: 16 }} color="secondary" />
                              </IconButton>
                            </>
                          }

                        </li>
                      )
                    } else {
                      return null;
                    }
                  }) 
                : "" }
              </ol>
            }
            {sortable && 
              <SortableContainer onSortEnd={onSortEnd} >
                {lessons !== [] ? 
                  lessons.map((value, index) => {
                    return (<SortableItem key={value + index} index={index} value={value} />) 
                  }) 
                : ""}
              </SortableContainer>
            }
          </CardBody>
        </Card>
      </GridItem>
      <GridItem style={{margin:-20, width: '30%'}}>
        <Card style={cardStyle}>
        <h5>SAYFALAR</h5>
            <ButtonGroup variant="text" size="small" color="primary">
              {lesson._id && 
                <Button onClick={onNewScreen}>YENİ</Button>
              }
              {lesson._id && 
                <Button onClick={togglePageArchiveDialog}>ARŞİV</Button>
              }
              {lesson._id && 
                <Button onClick={() => setSortable(!sortable)} variant={sortable ? "contained" : "text"}>SIRA</Button>
              }
              {/* {page._id && <Button onClick={onDeletePage}>SİL</Button>} */}
            </ButtonGroup>
          <CardBody style={{ overflowY: "scroll", marginTop: 5 }}>
            
            {!sortable && 
              <ol style={{ paddingLeft: 5, paddingBottom: 5, marginTop: -10, marginRight: -12 }}>
                {pages !== [] && pages ? 
                  pages.map((pg, i) => {
                    return (
                      <li 
                        onClick={()=> onSetActivePage({...pg, index: i})} key={pg._id + i} 
                        style={{cursor: 'pointer', backgroundColor: i === page.index && page._id ? "lightgreen" : "", color: screenTypeColor(pg.type)}}
                      >
                        {pg.screenName}
                        {pg.ui === "Chat" && pg.choices.length > 1 &&
                          "  " + "💬".repeat(pg.choices.length)
                        }
                      </li>
                    )
                  }) 
                : "" }
              </ol>
            }
            {sortable && 
              <SortableContainer onSortEnd={onSortEnd2}>
                {pages !== [] ? 
                  pages.map((value, index) => {
                    return (<SortableItem2 key={value + index} index={index} value={value} />) 
                  }) 
                : ""}
              </SortableContainer>
            }
          </CardBody>
        </Card>
      </GridItem>
      <ProgramDialog 
        open={openProgramDialog} toggle={toggleProgramDialog} fetch={fetchPrograms} 
        program={program} setProgram={setProgram} fetchProgram={fetchProgram}
      />
      <LessonDialog 
        open={openLessonDialog} toggle={toggleLessonDialog} program={program} setProgram={setProgram} 
        lesson={lesson} setLessons={setLessons} fetch={fetchPrograms} lessonIndex={lessonIndex}
        setLesson={setLesson} setPages={setPages} setPage={setPage} fetchProgram={fetchProgram}
        fetchLesson={fetchLesson}
      />
      <ScreenDialog 
        open={openScreenDialog} toggle={toggleScreenDialog} setLesson={setLesson} 
        pages={pages} setPages={setPages} lesson={lesson} page={page} onDeletePage={onDeletePage} 
        fetchPrograms={fetchPrograms} fetchLesson={fetchLesson} program={program}
        screens={screens} setScreens={setScreens}
        userAnswers={userAnswers}
      />
      <Archive2Dialog 
        open={openArchive2Dialog} toggle={toggleArchive2Dialog} setProgram={setProgram} 
        setLessons={setLessons} program={program} fetchPrograms={fetchPrograms} 
        fetchProgram={fetchProgram}
      />
      <PageArchiveDialog 
        open={openPageArchiveDialog} toggle={togglePageArchiveDialog} setLesson={setLesson} 
        setPages={setPages} lesson={lesson} fetchLesson={fetchLesson}
        screens={screens} setScreens={setScreens}
      />
      <AlgorithmDialog 
        open={openAlgorithmDialog} toggle={toggleAlgorithmDialog} lesson={lesson} 
        program={program} setActiveProgram={setActiveProgram} pages={pages}
        fetchLesson={fetchLesson}
      />
      <UpdateProgramDialog 
        open={openUpdateProgramDialog} toggle={toggleUpdateProgramDialog} 
        fetch={fetchPrograms} program={program}
      />
      <AlgorithmProgramDialog 
        open={openAlgorithmProgramDialog} toggle={toggleAlgorithmProgramDialog} 
        lesson={lesson} program={program} setActiveProgram={setActiveProgram} fetch={fetchPrograms}
      />
      <UserListDialog 
        open={openUserListDialog} toggle={toggleUserListDialog} onSendLesson={onSendLesson}
        user={user} setUser={setUser} lesson={lesson} program={program} users={users}
      />
      <UserListProgramDialog 
        open={openUserListProgramDialog} toggle={toggleUserListProgramDialog} 
        users={userList} program={program} lesson={lesson} listDisplay={listDisplay}
      />
      {alert}
    </GridContainer>
  );
}

export default Content;