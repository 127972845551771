import axios from 'axios';
import { domain } from './domain';
// import discountCodes from '../../src/discountCodes.json';

// get user lessons
export const getUserLessons = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserlessons', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update user lesson
export const updateUserLesson = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/updateuserlesson', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// create a user lesson 
export const createUserLesson = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/userlesson', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get users all lessons
export const getUserLesson = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserlesson', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all users all lessons
export const getAllUserLessons = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getalluserlessons', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all users all lessons2
export const getAllUserLessons2 = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getalluserlessons2', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get programs users
export const getProgramUsers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getbyprogram', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get program's lesson's users
export const getLessonUsers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getbylesson', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all user feedbacks
export const getFeedbacks = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getfeedbacks', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update user feedback
export const updateUserFeedback = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/userfeedback', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all users
export const getUsers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusers', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get registered users
export const getRegisteredUsers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getregisteredusers', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get active users
export const getUsersActive = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusersactive', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update user
export const updateUser = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/user', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// create subscription
export const createSubscription = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/subscription', requestOptions)
  .then( response => { 
      if (response.status) {
        return response
      } else {
        throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get user subscriptions
export const getUserSubscriptions = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/usersubscriptions', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all subscriptions
export const getAllSubscriptions = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/allsubscriptions', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get user messages
export const getUserMessages = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermessages', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get users answers
export const getUserAnswers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuseranswers', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all users answers
export const getAllUserAnswers = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getalluseranswers', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all users answers by screen id
export const getUserAnswerByScreenId = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuseranswerbyscreenid', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// getUserById
export const getUserById = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserbyid', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}


// user NUTRITION services
export const getUserMealplans = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermealplans2', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getUserMealgroups = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermealgroups', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getUserMealdiaries = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermealdiaries', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getUserWaters = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserwaters', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getUserMoods = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermoods', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// WORKOUT
export const getUserWorkouts = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserworkouts2', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getAllUserWorkouts = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getalluserworkouts', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get PhysicalActivity
export const getUserPhysicalActivities = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserphysicalactivities', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get users all feedbacks
export const getUserFeedbacks = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getfeedbacks2', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get users all recepies
export const getUserRecepies = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserrecepies', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}


// get users all movements
export const getUserMovements = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusermovements', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get users all quotes
export const getUserQuotes = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getuserquotes', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all user errorLogs
export const getErrorLogs = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/geterrorlogs', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update user errorLog
export const updateErrorLog = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/errorLog', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all paymnts
export const getPayments = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getpayments', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all no user paymnts
export const getNoUserPayments = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getnouserpayments', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all apple receipt
export const getAppleReceipts = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getreceipts', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get apple payments
export const verifyReceipt = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios('https://buy.itunes.apple.com/verifyReceipt', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all moods
export const getAllMoods = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getallmoods', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all waters
export const getAllWaters = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getallwaters', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get all mealDiaries
export const getAllMealDiaries = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getallmealdiaries', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get user nutrition settings
export const getNutritionSettings = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getusernutrition', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// get user events
export const getUserEvents = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getevents', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// create discountCodes
export const createDiscountCode = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/discountcode', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const createDiscountCodes = async () => {
  // let data = {}
  // let requests = [];
  // for (let i = 9000; i < discountCodes.Sayfa1.length; i++) {
  //   data = {
  //     date: new Date(),
  //     code: discountCodes.Sayfa1[i].kod,
  //     isActive: true,
  //   };
  //   requests = [...requests, createDiscountCode(data)];
  // }
  // let res =  await Promise.all(requests);
  // console.log("res: ", res.length);
}

// update payment
export const updatePayment = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/payment', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}
