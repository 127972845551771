import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonGroup } from '@material-ui/core';
import AlgorithmArchiveDialog from './AlgorithmArchive';
import { addAlgorithmToProgram } from '../../services/contentServices';
/* eslint-disable no-unused-vars */
const AlgorithmProgramDialog = (props) => {

  const { open, toggle, lesson, program, setActiveProgram, fetch } = props;

  const [decisions, setDecisions] = useState([]);
  const [point, setPoint] = useState("");
  const [link, setLink] = useState({});
  const [openAlgorithmArchiveDialog, setOpenAlgorithmArchiveDialog] = useState(false);

  useEffect(() => {
    if (open) {
      setDecisions(program.decisions)
    }
  },[open, program])

  const onAddAlgorithm = (event) => {
    event.preventDefault();
    toggle()
    console.log('algoritma ekleniyor')
    // add algorithm to program
    var id = program._id
    const data = { id, decisions }
    addAlgorithmToProgram(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        console.log('Algoritma Programa eklendi', res.data.data.decisions);
        setActiveProgram(res.data.data) // update active program state
        fetch() // refresh all program state
      } else {
        console.log('Sorun çıktı. Kod: ',res.data.code)
      }
    })
    .catch(err => {
      console.log('İşlem başarısız: ', err)
    })

  }
  const handleAdd = () => {
    // add decision parametres
    if (point) {
      const decision = { point: point, link: link.name, linkId: link.id}
      setDecisions([...decisions, decision]);
      setPoint("");
      setLink({})
    }
  }
  const handleRemove = index => {
    setDecisions([
      ...decisions.slice(0, index), 
      ...decisions.slice(index + 1)
    ])
  }
  const toggleAlgorithmArchiveDialog = () => setOpenAlgorithmArchiveDialog(!openAlgorithmArchiveDialog);

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
      {program.name} {decisions.length ? "programının Algoritmasını düzenleyin" : " programına Algoritma Ekleyin"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Puan ortalamasına göre algoritma seçin
        </DialogContentText>
        <form onSubmit={onAddAlgorithm}>

          <TextField id="decision" label="Ortalama Puan:" type="number" value={point} onChange={(e) => setPoint(e.target.value)}/> 
          <TextField label={link.name ? link.name : "Algoritma"} style={{marginLeft: 20, width: 220}} 
            id="decisionAlg" disabled defaultValue="" onClick={toggleAlgorithmArchiveDialog} /> 
              
          <ButtonGroup style={{float:"right", marginTop: 15}} variant="contained" size="small" color="default">
            <Button onClick={handleAdd}>+</Button>
          </ButtonGroup>

          <ul style={{ paddingLeft: 15}}>
            {decisions.map((decision, i) => {
              return (
                <li key={i} style={{padding: 8}}>
                  Puan <b>{i ? decisions[i - 1].point : "0"} - {decision.point}</b> ise <b>{decision.link}</b> adlı içerik gidecek
                  <ButtonGroup style={{float: "right", marginRight: -8}} variant="contained" size="small" color="secondary">
                    <Button onClick={() => handleRemove(i)}>x</Button>
                  </ButtonGroup>
                </li>
              )
            })}
          </ul>

          <DialogActions style={{marginTop:30}}>
            <Button color="primary" variant="contained" type="submit" >KAYDET</Button>
            <Button onClick={toggle} color="primary">Kapat</Button>
          </DialogActions>
        </form>
      </DialogContent>
      <AlgorithmArchiveDialog 
        open={openAlgorithmArchiveDialog} toggle={toggleAlgorithmArchiveDialog} setLink={setLink}
        program={program}
      />
    </Dialog>
  );
}

export default AlgorithmProgramDialog;