import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserMovements } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserMovements = (props) => {
  const { user } = props;

  const [movements, setMovements] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => {
    let data = { userId: user._id }
    getUserMovements(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        setMovements(res.data.data);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <strong>Hareketleri</strong>
              <table style={cardStyle} >
                <thead>
                  <tr style={{ backgroundColor: '#8D81F8', color: '#FFF' }} >
                    <th style={{width: '20%', padding: 5}} align="left">Hareket</th>
                    <th style={{width: '15%', padding: 5}} align="left">Yıldız</th>
                    <th style={{width: '15%', padding: 5}} align="left">Tekrar</th>
                    <th style={{width: '15%', padding: 5}} align="left">Son Kullanım</th>
                    <th style={{width: '15%', padding: 5}} align="left">Rank</th>
                  </tr>
                </thead>
                <tbody>
                  {movements.map((r, i) => {
                    return (
                      <tr style={{ backgroundColor : i % 2 ? "#E4DFFF" : "#F4F6FA"}} key={i}>
                        <td style={{padding: 5}}>{r.movement.id.name}</td>
                        <td style={{padding: 5}}>{r.movement.stars}</td>
                        <td style={{padding: 5}}>{r.movement.repetition}</td>
                        <td style={{padding: 5}}>{r.movement.lastused}</td>
                        <td style={{padding: 5}}>{parseInt(r.movement.rank * 10)/10}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserMovements;