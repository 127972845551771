import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserFee, getUserFeedbacks } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserFeedbacks = (props) => {
  const { user } = props;

  const [feedbacks, setFeedbacks] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => { 
    let data = { id: user._id }
    getUserFeedbacks(data)
    .then(res => {
      if (res.data && res.data.code === 200){
        setFeedbacks(res.data.data);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)} >
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <strong>Geribildirimleri</strong>
              <table>
                <thead>
                  <tr style={{ backgroundColor: '#8D81F8', color: '#FFF' }} >
                    <th style={{width: '8%', padding: 5}} align="left">Tarih</th>
                    <th style={{width: '40%', padding: 5}} align="left">Tip</th>
                    <th style={{width: '25%', padding: 5}} align="left">Metin</th>
                    <th style={{width: '12%', padding: 5}} align="left">Rating</th>
                    <th style={{width: '10%', padding: 5}} align="left">Durumu</th>
                  </tr>
                </thead>
                <tbody>
                  {feedbacks.map((f, i) => {
                    return (
                      <tr style={{ backgroundColor : i % 2 ? "#E4DFFF" : "#F4F6FA"}} key={i}>
                        <td style={{padding: 5}}>{new Date(f.date).toLocaleDateString().slice(0, 5)}</td>
                        <td style={{padding: 5}}>{f.type.split("|")[0]}</td>
                        <td style={{padding: 5}}>{f.text ? <strong>{f.text}</strong> : " - "} {" "}</td>
                        <td style={{padding: 5}}>{f.stars ? "⭐️".repeat(f.stars) : " - "}</td>
                        <td style={{padding: 5}}>{f.solved ? "🟢 Kapandı" : "🔴 Açık"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserFeedbacks;