import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserAnswers } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserAnswers = (props) => {
  const { user } = props;
  const [answers, setAnswers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchAnswers = () => {
    getUserAnswers({ userId: user._id })
    .then(res => {
      if (res.data && res.data.code === 200) {
        let allAnswers = res.data.data
        // group answers
        let screenIds = [];
        let qnaObjArr = [];
        allAnswers.map(a => {
          let index = screenIds.indexOf(a.screen._id);
          let answer = "";
          if (a.value || a.value === 0) {
            answer = a.value;
          } else {
            a.choices.map((c, j) => {
              if (c) { 
                return (answer += c.choiceText + (j < a.choices.length - 1 ? ", " : ""));
              } else return null;
            })
          }
          answer += `  (${new Date(a.date).toLocaleDateString().slice(0, 5)})`;
          if (index === -1) {
            screenIds = [...screenIds, a.screen._id];
            let question = `${a.screen.screenName} ~ ${a.screen.title.split("|")[0]}`
            if (question.length > 95) {
              question = question.slice(0, 94) + "...";
            }
            qnaObjArr = [...qnaObjArr, { q: question, a: [answer] }];
          } else {
            // append answer
            qnaObjArr[index].a = [...qnaObjArr[index].a, answer];
          }
          return null;
        });
        setAnswers(qnaObjArr);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)} >
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll" }}>
              <h6>Kullanıcı Soru ve Cevapları ({answers.length} soru)</h6>
              <table style = {{ width: '100%'}}>
                <thead>
                  <tr style={{ backgroundColor: '#8D81F8', color: '#FFF' }} >
                    <th style={{width: '65%', padding: 5}} align="left">Soru</th>
                    <th style={{width: '35%', padding: 5}} align="left">Cevap(lar)</th>
                  </tr>
                </thead>
                <tbody>
                  {answers.map((qna, i) => {
                    return (
                      <tr style={{ backgroundColor : i % 2 ? "#E4DFFF" : "#F4F6FA"}} key={i}>
                        <td style={{padding: 5}}>{qna.q}</td>
                        <td style={{padding: 5}}>{qna.a.map((answer, j) => <div key={j}>{answer}</div>)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserAnswers;