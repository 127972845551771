import axios from 'axios';
import { domain } from './domain';

// create new blog
export const createBlog = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/blog', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get all blogs
export const getBlogs = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getblogs', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get blog by id
export const getBlog = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getblog', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update a blog
export const updateBlog = (data) => {
  const requestOptions = {
      method: "PUT",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/blog', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}
