import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getPrograms, getLessons, getScreens, getProgramLessons, getProgram } from '../../services/contentServices';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const columns = [
  { name: 'no', title: 'Sıra' },
  { name: 'id', title: 'ID' },
  { name: 'type', title: 'Tip' },
  { name: 'branch', title: 'Branş' },
  { name: 'name', title: 'Adı' },
  { name: 'screenType', title: 'Ekran' },
  // { name: 'content', title: 'İçeriği' },
];

const AlgorithmArchiveDialog = (props) => {

  const { open, toggle, setLink, pages, program } = props;
  
  const [links, setLinks] = useState([{}]);
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 90 },
    { columnName: 'id', width: 90 },
    { columnName: 'type', width: 100 },
    { columnName: 'branch', width: 100 },
    { columnName: 'name', width: 250 },
    { columnName: 'screenType', width: 150 },
    // { columnName: 'content', width: 210 },
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const [tableColumnExtensions] = useState([
    { columnName: 'name', wordWrapEnabled: true },
  ]);

  useEffect(() => {
    getAllLinks();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);  // [open]

  const getAllLinks = () => {
    console.log("getAllLinks");
    console.log("getting programs...");
    let time = new Date();
    getPrograms()
    .then(res => {
      if (res.data.code === 200) {
        var newLinks = res.data.data;
        console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
        console.log("records: ", res.data.data.length);
        console.log("getting lessons...");
        time = new Date();
        getLessons()
        .then(res2 => {
          if (res2.data.code === 200) {
            newLinks = newLinks.concat(res2.data.data);
            setLinks(newLinks);
            console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
            console.log("records: ", res2.data.data.length);

            console.log("getting pages (this lesson/program)...");  // pages state (pages of this lesson)
            time = new Date();
            if (pages && pages.length > 0) {
              newLinks = newLinks.concat(pages)
              setLinks(newLinks);
              console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
              console.log("records: ", pages.length);
            } else {
              getProgram({ id: program._id })
              .then(res3 => {
                if (res3.data && res3.data.code === 200) {
                  let programData = res3.data.data;
                  let programPages = [];
                  programData.lessons.map(l => l.pages.map(p => programPages = [...programPages, p]))
                  newLinks = newLinks.concat(programPages)
                  setLinks(newLinks);
                  console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
                  console.log("program pages: ", programPages.length);
                }
              })
            }
            
            // getScreens()   // All screens
            // .then(res3 => {
            //   if (res3.data.code === 200) {
            //     newLinks = newLinks.concat(res3.data.data)
            //     setLinks(newLinks);
            //     console.log("elapsed: ", parseInt((new Date() - time) / 100) / 10, "sec");
            //     console.log(newLinks);
            //   } else {
            //     console.log("Hata Oluştu - 3")
            //   }
            // })

          } else {
            console.log("Hata Oluştu - 2")
          }
        })
      } else {
        console.log("Hata Oluştu - 1")
      }
    })
    .catch((err) => {
      console.log("Error: ", err);
    })
  }

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        // select a link
        var selectedId = row.id;
        var selectedName = row.name;
        // console.log(selectedId, selectedName)
        // setLink({id: row.id, name: row.name, type: row.type})
        setLink({id: row.id, name: row.name})
        toggle()
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );

  const rows = () => {
    var no = 0;
    return (
      links.map(l => {
        if (l) {
          no += 1;
          if (l.lessons) {
            var linkType = "Program"
            if (l.lessons[0]) {
              var content = l.lessons[0].name
            } 
          } else if (l.screenName) {
            linkType = "Sayfa"
            content = l.title
          } else {
            linkType = "Ders"
            content = l.title
            var screenType = l.type
          }
          return ({
            no: no,
            id: l._id,
            type: linkType,
            branch: l.branch,
            name: l.name ? l.name : l.screenName,
            screenType: l.type,
            content: content
          });
        } else {
          return null;
        }
      })
    )
  }
  
  
  const onAddAlgorithm = () => {
    //
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md" PaperComponent={PaperComponent}>
        <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">Algoritma Ekleyin</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Arşivden program, ders veya bu programın/dersin sayfalarından seçin ({links.length}) : <strong style={{background:'honeydew'}}></strong>
          </DialogContentText>
          <Paper>
            <Grid rows={rows()} columns={columns}>
              <SortingState defaultSorting={[{ }]}/>
              <IntegratedSorting />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <Table />
              <VirtualTable 
                height="400px" 
                rowComponent={TableRow} 
                tableComponent={TableComponent} 
                columnExtensions={tableColumnExtensions} 
              />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAddAlgorithm} color="primary" variant="contained">EKLE</Button>
          <Button onClick={toggle} color="primary">Kapat</Button>
        </DialogActions>
    </Dialog>
  );
}

export default AlgorithmArchiveDialog;