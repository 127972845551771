import React, { useEffect} from "react";
// import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom"; // router değişti
import { Router, Route, Switch, Redirect } from "react-router-dom"; // router deneme
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import { createBrowserHistory } from "history";
import { useAtom } from 'jotai';
import { coachAtom } from '../src/_atoms/coachAtom';
// import { createDiscountCodes } from '../src/services/userServices'
// import LoginPage from '../src/views/Pages/LoginPage';
/* eslint-disable no-unused-vars */
const hist = createBrowserHistory();

const App = props => {

  const [coach, setCoach] = useAtom(coachAtom);
  var { loggedin } = coach; 
  
  // login bypass
  // loggedin = true; 
  useEffect(() => {
    // setCoach({ name: "Cihan Nam", email: "cihan@fitness-hocam.com", loggedin: true });
    // one time functions ****
    // create discount codes
    // createDiscountCodes()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return (
    <Router history={hist}>
      <Switch>
        <Route path="/admin" render = { () => { return loggedin ? <AdminLayout {...props} /> : <Redirect to='/auth/login-page' /> }} />
        <Route path='/auth' render = { () => { return loggedin ? <Redirect to='/admin/webforms' /> : <AuthLayout {...props} /> }} />
        {/* <Redirect from="/" to="/admin/webForms" />  */}
        <Redirect from="/" to="/admin/webforms" /> 
      </Switch>
    </Router>
  );
}

export default App;