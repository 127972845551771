import React, { useState, useRef, useEffect } from 'react';
import Button from "@material-ui/core/Button";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js'
  /* eslint-disable no-unused-vars */
import { addScreen, addPageToLesson, updateScreen } from '../../services/contentServices';
import AlgorithmArchiveDialog from '../Dialogs/AlgorithmArchive';
import { ButtonGroup, DialogContentText } from '@material-ui/core';
import SweetAlert from "react-bootstrap-sweetalert";
import QuestionArchiveDialog from '../Dialogs/QuestionArchive';
import Archive2 from '../Dialogs/Archive2';
import ReactPlayer from 'react-player';
import UserAnswersDialog from '../Dialogs/UserAnswers';

const screenTypes = ["Sistem", "Bilgi ekranı", "Çoklu seçmeli soru", "Tekli seçmeli soru", "Metin girişi", "Rakam girişi"];
const algTimes = [" sn sonra", " dk sonra", " saat sonra", " gün sonra", " hafta sonra", " ay sonra"];
const uiTypes = ["Story", "Chat", "Bildirim", "Günün Motivasyonu", "Post"] // if feedback, question
const uiTypes2 = ["Story", "Chat"] // if question 
const questionTypes = ["Genel", "Grafik", "Profil", "Değerlendirme"]
const queryTypes = ["Genel", "Soru kıyaslama", "Ders kıyaslama", "Soru cevabı", "Sistem verisi"];
const comparisons = ["Güncel veri yok", "Son değer = İlk değer", "Son değer < İlk değer", "Son değer > İlk değer", "Son değer = Önceki değer", "Son değer < Önceki değer", "Son değer > Önceki değer"]
const branchTypes = ["Beslenme", "Psikoloji", "Egzersiz"];

var focus; 
var contentTrigger = true;

const ScreenDialog = (props) => {

  const { open, toggle, lesson, fetchPrograms, page, onDeletePage, fetchLesson, pages, setPages, program, screens, setScreens, userAnswers } = props;

  const [type, setType] = useState("Bilgi ekranı");
  const [choices, setChoices] = useState([]);
  const [choiceText, setChoiceText] = useState("");
  const [link, setLink] = useState({});
  const [editorState, setEditorState] = useState("");
  const [contentState, setContentState] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [openAlgorithmArchiveDialog, setOpenAlgorithmArchiveDialog] = useState(false);
  const [openQuestionArchiveDialog, setOpenQuestionArchiveDialog] = useState(false);
  const [openLessonArchiveDialog, setOpenLessonArchiveDialog] = useState(false);
  const [openUserAnswersDialog, setOpenUserAnswersDialog] = useState(false);

  const [point, setPoint] = useState(0);
  const [timeAmount, setTimeAmount] = useState(0)
  const [algTime, setAlgTime] = useState(algTimes[0]);
  const [alert, setAlert] = useState(null);
  const inputFile = useRef(null);
  const [uiType, setUiType] = useState("Story")
  const [questionType, setQuestionType] = useState("Genel");
  const [questionName, setQuestionName] = useState()
  const [screenTitle, setScreenTitle] = useState()
  const [query, setQuery] = useState(queryTypes[0])
  const [options, setOptions] = useState([])
  const [screenName, setScreenName] = useState("")
  const [branch, setBranch] = useState(branchTypes[0]);

  const htmlToEditorState = (html) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }


  // Calc answer stats
  var choiceCount = {};   // init choice count
  choices.map(c => choiceCount[c.choiceText] = 0 );
  for (let i = 0; i < userAnswers.length; i++) {
    userAnswers[i].choices.map(c => {
      choiceCount[c.choiceText]++;
      return null;
    });
  }
  // var answersText = "";
  // choices.map((c, i) => answersText += `${i+1}. ${c.choiceText}: ${choiceCount[c.choiceText]} (%${Math.round(100 * choiceCount[c.choiceText] / userAnswers.length)})  `)
  // const answerStats = `Cevaplar: ${answersText}`


  useEffect(() => {
    //reset
    setBody("");
    setEditorState(null);
    setContentState(null);
    setImage("");
    setFile("");
    setScreenTitle("");
    setScreenName("");
    setLink({});

    if (open) {
      if (page) { 
        if (page.body !== "") {
          if (!page.body || page.body === "undefined") { 
            setBody("");
          } else {
            setBody(page.body);
          }
          htmlToEditorState(page.body && page.body !=="undefined" ? page.body : "");
        }
        setImage(page.image);
        if (page.type !== undefined) {
          setType(page.type);
        }
        setScreenTitle(page.title);
        setScreenName(page.screenName);
        setOptions([]);
      } 
      setChoices(page.choices);
      if (page.ui !== undefined) {
        setUiType(page.ui);
      }
      if (page.query !== undefined) {
        setQuery(page.query);
      }
      if (page.questionType !== undefined) {
        setQuestionType(page.questionType)
      }
      if (page.branch !== undefined) {
        setBranch(page.branch)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, page])

  const toggleAlgorithmArchiveDialog = () => setOpenAlgorithmArchiveDialog(!openAlgorithmArchiveDialog);
  const toggleQuestionArchiveDialog = () => setOpenQuestionArchiveDialog(!openQuestionArchiveDialog);
  const toggleLessonArchiveDialog = () => setOpenLessonArchiveDialog(!openLessonArchiveDialog);
  const toggleUserAnswersDialog = () => setOpenUserAnswersDialog(!openUserAnswersDialog);

  const handleChange = event => setType(event.target.value);
  const handleChange2 = event => setAlgTime(event.target.value);
  const handleChangeUi = event => setUiType(event.target.value);
  const handleChangeQuestionType = event => setQuestionType(event.target.value);
  const handleChangeQuery = event => setQuery(event.target.value);
  const handleChangeBranch = event => setBranch(event.target.value);

  useEffect(() => {
    if (query === "Soru kıyaslama" || query === "Ders kıyaslama") {
      setOptions(comparisons);
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[type, query])

  const onImageClick = () => inputFile.current.click();
  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setFile(file);
    console.log(file);
    setImage(URL.createObjectURL(file))
    if (file.size > 1e5) {
      console.log('Dosya boyutunu küçültün')
    }
  }

  const handleSubmitFormdata = (event) => {
    event.preventDefault();
    if (type === "Bilgi ekranı" && choices.length > 1) {
      if (window.confirm("Bu bir Bilgi Ekranı ve birden fazla şık var. Yine de kaydetmeye devam etmek istiyor musunuz?")) {
        handleSubmit(event)
      }
    } else {
      handleSubmit(event);
    }
  }

  const handleSubmit = (event) => {
    var scrName = event.target.elements.screenName.value;
    // remove spaces in question and system pages for # function, ? allowed but [" ", ".", ",", "!", "&", "<"] not allowed
    if (type !== "Bilgi ekranı") {
      scrName = scrName.replaceAll(" ", "-"); // if needed add others for replacement
    }
    if (event.target.elements.screenTitle) {
      var title = event.target.elements.screenTitle.value;
    } else {
      title = ""
    }
    const fd = new FormData()
    if (file !== "") {
      fd.append('screen-photo', file, file.name);
    } 
    if (image && image !== "" && !image.includes('blob')) {
      fd.append('image', image)
    }
    if (file === "" && image === "") {
      fd.append('image', image)
    }
    fd.append('type', type);
    fd.append('screenName', scrName);
    fd.append('title', title);
    fd.append('body', body);
    if (choices.length) {
      fd.append('choices', JSON.stringify(choices))
    }
    fd.append('ui', uiType);
    fd.append('query', query);
    fd.append('questionType', questionType);
    fd.append('branch', branch);

    if (!page._id) {
      // create new screen
      addScreen(fd)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          console.log('Ekran oluşturuldu ');

          // add screen to pages archive state
          let allScreens = screens;
          allScreens = [...allScreens, res.data.data];
          setScreens(allScreens);

          // add Screen to lesson: lesson id, Screen id
          var screenId = res.data.data._id
          // get pages and push new page
          var pages = lesson.pages.map(p => p._id) // populate yapmadan sadece id
          pages.push(screenId)
          var id = lesson._id
          const data = { id, pages }
          addPageToLesson(data)
          .then(res => {
            if (res.data && res.data.code === 200) {
              console.log('Sayfa Derse eklendi: ', res.data.data);
              autoCloseAlert();
              // setLesson(res.data.data) // update parent state 
              // setPages(res.data.data.pages) // update parent state - for triggering useEffect : pages for refresh not fetch but state
              // fetchPrograms();
              fetchLesson(lesson);
            } else {
              console.log('Sorun çıktı. Kod: ',res.data.code)
            }
          })
          .catch(err => {
            console.log('İşlem başarısız: ', err)
          })
        } else {
          console.log('İşlem başarısız: ', res)
        }
      })
    } else {
      // update screen
      fd.append('id', page._id);
      // for (var pair of fd.entries()) { console.log(pair[0]+ ', ' + pair[1]) }
      updateScreen(fd)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          console.log('Ekran güncellendi :', res.data.data);
          autoCloseAlert()
          // fetchPrograms();
          fetchLesson(lesson);
        } else {
          console.log('Hata oluştu :', res.data.message, res.data.code);
        }
      })
    }
    setTimeout(toggle, 500);
  }

  const content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "Initialized from content state.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  }

  const handleAdd = () => {
    if (choiceText !== '') {
      const choice = { choiceText: choiceText, link: link.name, linkId: link.id, point: point, algTime: timeAmount + algTime}
      setChoices([...choices, choice]);
      setChoiceText('');
      setLink({})
      setPoint(0)
    }
  }
  const handleRemove = index => {
    setChoices([
      ...choices.slice(0, index), 
      ...choices.slice(index + 1)
    ])
  }
  const onSetPoint = (value) => {
    setPoint(value);
    // setLink({name: value + " puan", type: "puan"})
  }

  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" onConfirm={() => hideAlert()} showConfirm={false}>
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1500);
  };
  const hideAlert = () => setAlert(null);

  const handleTitleChange = (event) => {
    setScreenTitle(event.target.value)
    if (type !== "Sistem") {
      handleHashtag(event.target.value)
    // } else if (query === "Ders kıyaslama") {
    //   toggleLessonArchiveDialog()
    } else if (screenName && (!screenName.includes("PUAN") && !screenName.includes("SAYFAYA-GİT"))) {
      toggleQuestionArchiveDialog()
    }
  }

  const handleHashtag = (value) => {
    focus = "title";
    if (value && value.length && value.lastIndexOf('#') + 1 === value.length) {
      toggleQuestionArchiveDialog()
    }
  }

  const onEditorStateChange = editorState => setEditorState(editorState);
  const onContentStateChange = contentState => {
    console.log("editor state change")
    setContentState(contentState);
    setBody(draftToHtml(contentState))

    // hashtag control
    console.log("contentTrigger: ", contentTrigger);

    if (contentTrigger) {
      contentState.blocks.map((b, i) => {
        if (b.text.length && b.text.lastIndexOf('#') + 1 === b.text.length) {
          contentTrigger = false;
          focus = "body";
          toggleQuestionArchiveDialog()
        } else {
          contentTrigger = true;
        }
        return null;
      })
    } else {
      contentTrigger = true;
    }
  }

  useEffect(() => {
    if (questionName) {
      if (type === "Sistem") {
        focus = "";
        let choiceTexts = []
        if (questionName.choices) {
          questionName.choices.map(c => choiceTexts = [...choiceTexts, c.choiceText])
          setOptions(choiceTexts);
          console.log("choiceTexts", choiceTexts);
        }
        if (query === "Soru kıyaslama" || query === "Ders kıyaslama") {
          setOptions(comparisons);
        }
        setScreenTitle(questionName.name)
      }
      if (focus === "title") {
        setScreenTitle(screenTitle + questionName.name);
        wait1()
      }
      if (focus === "body") {
        let newBody1 = body.slice(0,body.lastIndexOf("#") + 1);
        let newBody2 = body.slice(body.lastIndexOf("#") + 1);
        htmlToEditorState(newBody1 + questionName.name + newBody2)
        setTimeout(()=> wait1(), 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[questionName])

  const wait1 = () => { contentTrigger = true }

  const onDeleteImage = () => {
    setImage("");
    setFile("");
  }

  const onUserAnswers = () => {
    toggleUserAnswersDialog();
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md" >
      <DialogTitle id="form-dialog-title" style={type === "Sistem" ? {background:"honeydew"} : {}}>
        {page._id ? 
          "Ekranı düzenleyin" 
        : 
          (type !== "Chat mesajı" ? "Yeni Ekran" : "Yeni Chat Mesajı")
        }
      </DialogTitle>

      {/* <DialogContentText>
        {userAnswers.length && 
          <div style={{ marginLeft: 20 }}>
            {page.type && page.type.includes("soru") &&
              <span style={{ marginRight: 20 }}>
                {answerStats}
              </span>
            }
            <Button onClick={onUserAnswers} color="primary">
              TÜM CEVAPLAR ({userAnswers.length})
            </Button>
          </div>
        }
      </DialogContentText> */}

      <DialogContent style={type === "Sistem" ? {background:"honeydew"} : {}}>
        <form onSubmit={handleSubmitFormdata}>
          <div style={{display:"flex", flexDirection: "row"}}>
            <div>
              <InputLabel required style={{marginTop: 20}} id="select-type">Sayfa(ekran) Tipi</InputLabel>
              <Select 
                labelId="label-type" id="type" value={type ? type : ""}
                onChange={handleChange} style={{ width: 200 }}
              >
                {screenTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
              </Select>
            </div>

            {type !== "Sistem" && 
              <div style={{marginLeft: 20}}>
                <InputLabel required style={{marginTop: 20}} id="select-ui">Arayüz Tipi</InputLabel>
                <Select 
                  autoFocus labelId="label-ui" id="ui" value={uiType ? uiType : ""}
                  onChange={handleChangeUi} style={{ width: 200 }}
                >
                  {type === "Bilgi ekranı" ? 
                    uiTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)
                  : 
                    uiTypes2.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)
                  }
                </Select>
              </div>
            }

            {((type !== "Sistem" && type !== "Bilgi ekranı" ) || (screenName && screenName.includes("PUAN")) || (page.screenName && page.screenName.includes("PUAN")) || (screenTitle && screenTitle.includes("PUAN")) || (page.title && page.title.includes("PUAN")) || (screenName && screenName.includes("YAĞ-ORANI"))) &&
              <div style={{marginLeft: 20}}>
                <InputLabel required style={{marginTop: 20}} id="select-question">Soru Tipi</InputLabel>
                <Select 
                  labelId="label-question" id="question" value={questionType ? questionType : ""}
                  onChange={handleChangeQuestionType} style={{  width: 200 , marginRight: 20 }}
                >
                  {questionTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>

                {userAnswers.length && 
                  <Button onClick={onUserAnswers} color="primary" variant="contained"> 
                    CEVAPLAR ({userAnswers.length})
                  </Button>
                }

              </div>
            }
            

            {type === "Sistem" &&
              <div style={{marginLeft: 20}}>
                <InputLabel required style={{marginTop: 20}} id="select-query">Sorgu Tipi</InputLabel>
                <Select 
                  labelId="label-query" id="query" value={query ? query : ""}
                  onChange={handleChangeQuery} style={{width: 200}}
                >
                  {queryTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
            }

            {uiType === "Post" &&
              <div style={{marginLeft: 20}}>
                <InputLabel required style={{marginTop: 20}} id="select-branch">Post Branşı</InputLabel>
                <Select 
                  labelId="label-branch" id="branch" value={branch ? branch : ""}
                  onChange={handleChangeBranch} style={{width: 200}}
                >
                  {branchTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
            }

            {page.likes && page.likes.length > 0 &&
              <TextField 
                defaultValue={page.likes.length} disabled type="text"
                label="Beğeni" style={{marginLeft: 20, marginTop: 20, width: 50}}
              />
            }

          </div>

          <TextField 
            name="screenName" defaultValue={page ? page.screenName : ""} required id="name" 
            label={uiType === "Günün Motivasyonu" ? "Motivasyon metni" : type === "Sistem" || type === "Bilgi ekranı" ? "Ekran adı" : "Soru adı"}
            type="text" style={{marginTop: 10, marginRight: 30 }} fullWidth
            onChange={(event) => setScreenName(event.target.value)}
          />
          
          <TextField 
            name="screenTitle" id="title" fullWidth multiline
            required={(type === "Bilgi ekranı" && uiType === "Story") || uiType === "Günün Motivasyonu" || (type === "Sistem" && screenName && (screenName.includes("PUAN") || screenName.includes("SAYFAYA-GİT") || screenName.includes("PROGRAMI-SONLANDIR") || screenName.includes("YAĞ-ORANI"))) ? false : true}
            placeholder={(uiType === "Chat" || uiType === "Bildirim") && type !== "Sistem" ? "Mesaj Metni" : type === "Sistem" ? "Bakılacak soru/sayfa/veri" : uiType === "Günün Motivasyonu" ? "Yazar" : "Başlık/Soru"}
            type="text" style={{marginTop: 20}} 
            onChange={handleTitleChange}
            value={screenTitle}
            onClick={handleTitleChange}
          />

          <div style={{marginTop:15}}>
            {(type !== "Chat mesajı" && type !== "Sistem") && 
              <Button onClick={onImageClick} size="small" style={{ margin:'auto' }}>GÖRSEL EKLE</Button>
            }
            {image && 
              <Button onClick={onDeleteImage} style={{ color: 'red'}}>GÖRSELİ SİL</Button>
            }
          </div>

          {type !== "Sistem" && uiType !== "Günün Motivasyonu" &&
            <>
              <input 
                type='file' id='file' name='screen-photo' accept="image/*, video/*" ref={inputFile} 
                onChange={onChangeFile} style={{display: 'none'}}
              />
              {(image && image.includes(".mp4")) || (file && file.name && file.name.includes(".mp4")) ?
                <ReactPlayer url={image} controls={true} width={320} height={180} />
              :
                <img alt={image} id="target" src={image} style={{ width: "20%", margin:"auto"}} />
              }
            </>
          }

          {(type !== "Sistem" && uiType === "Story") && 
            <Editor 
              placeholder="Metin" toolbarOnFocus initialContentState={content}
              editorContent={contentState} 
              onContentStateChange={contentTrigger ? (contentState) => onContentStateChange(contentState) : null}
              editorState={editorState} toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName"
              editorClassName="editorClassName" 
              onEditorStateChange={(editorState)=> onEditorStateChange(editorState)}
              toolbar={{ options: ['inline', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
              inline: {options: ['bold', 'italic', 'underline']}}}
              onFocus={() => contentTrigger = body.length === 0 ? true : contentTrigger}
            />
          }

          {type ? 
            <div>
              {type !== "Sistem" && 
                <TextField 
                  id="choice" label="Buton/Şık:" type="text" value={choiceText} style={{width: 330}} 
                  onChange={(e) => setChoiceText(e.target.value)}
                />
              }
              {type === "Sistem" && 
                <TextField 
                  select label="Cevaplar" id="options" value={choiceText} style={{width: 330}}
                  onChange={(e) => setChoiceText(e.target.value)} 
                  disabled={!options.length}
                >
                  {options.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </TextField>
              }

              <TextField 
                id="choiceAlg" label={link.name ? link.name : "Algoritma"} disabled style={{width: 200}} 
                defaultValue="" onClick={toggleAlgorithmArchiveDialog}
              />
              <TextField 
                id="choicePoint" label="Puanı" type="number" style={{marginLeft: 20, width: 60}} 
                value={point} onChange={(e) => onSetPoint(e.target.value)}
              />

              {(link.name || (screenName && (screenName.includes("SONRAKİ-DERS") || screenName.includes("SONRAKİ DERS") ))) && <>
                <TextField 
                  id="choiceTime" label="Birim" type="number" style={{marginLeft: 20, width: 50}} 
                  value={timeAmount} onChange={(e) => setTimeAmount(e.target.value)}
                />
                {timeAmount !== 0 && 
                  <TextField 
                    select label="Zaman" id="select2" value={algTime ? algTime : ""}
                    onChange={handleChange2} style={{marginLeft: 5, width: 130}}
                  >
                    {algTimes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                  </TextField>
                }
              </>}

              <ButtonGroup 
                style={{float:"right", marginTop: 15}} variant="contained" size="small" color="default"
              >
                <Button onClick={handleAdd}>+</Button>
              </ButtonGroup>
            </div> 
            : ""
          }
          <ol style={{ paddingLeft: 15}}>
            {choices ? 
              choices.map((choice, i) => {
                let choiceRate = Math.round(100 * choiceCount[choice.choiceText] / userAnswers.length);
                choiceRate = isNaN(choiceRate) ? 0 : choiceRate;
                return (
                  <li key={i} style={{padding: 8}}>
                    {userAnswers.length &&
                      <span 
                        style={{ 
                          display: "inline-block", 
                          width: Math.log10(userAnswers.length) * 8 + 48, marginLeft: 5,
                          fontWeight: choiceRate > 50 ? 500 : null
                        }}
                      >
                        {`%${choiceRate} (${choiceCount[choice.choiceText]}) `}
                      </span>
                    }
                    <b>{choice.choiceText}</b> ise <b>{choice.point} puan</b>  {choice.link ? " ve " : "."}
                    <b>{choice.link}</b>{<> adlı içerik <b>{choice.algTime !== "0" ? choice.algTime : "hemen"}</b> gidecek</>}
                    <ButtonGroup 
                      style={{float: "right", marginRight: -8}} variant="contained" size="small" color="secondary"
                    >
                      <Button onClick={() => handleRemove(i)}>x</Button>
                    </ButtonGroup>
                  </li>
                )
              })
            : ""}
          </ol>
          <DialogActions style={{marginTop:30}}>
            {!page._id ? 
              <Button type="submit" color="primary" variant="contained">OLUŞTUR</Button> 
            : 
              <Button type="submit" color="primary" variant="contained">GÜNCELLE</Button>}
            {page._id && 
              <Button color="secondary" onClick={onDeletePage}>SİL</Button>
            }
            <Button onClick={toggle} color="primary">Kapat</Button>
          </DialogActions>
        </form>
      </DialogContent>

      <AlgorithmArchiveDialog 
        open={openAlgorithmArchiveDialog} toggle={toggleAlgorithmArchiveDialog} setLink={setLink}
        pages={pages} program={program}
      />
      <QuestionArchiveDialog 
        open={openQuestionArchiveDialog} toggle={toggleQuestionArchiveDialog} 
        setQuestionName={setQuestionName} 
        systemPage={type === "Sistem" && (query === "Sistem verisi" ||  query === "Ders kıyaslama") ? true : false}
      />
      <Archive2 
        open={openLessonArchiveDialog} toggle={toggleLessonArchiveDialog} setQuestionName={setQuestionName}
      />
      <UserAnswersDialog 
        open={openUserAnswersDialog} toggle={toggleUserAnswersDialog} 
        userAnswers={userAnswers} title={page.title}
      />
      {alert}
    </Dialog>
  );
}

export default ScreenDialog;