import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { dateLocal } from '../../services/helperFunctions';
import { getFeedbacks } from '../../services/userServices';
import { coachNoToName, coachNameToNo2 } from '../../services/coachServices';
import SweetAlert from "react-bootstrap-sweetalert";
import { updateUserFeedback } from '../../services/userServices';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'type', title: 'Tip' },
  { name: 'text', title: 'Mesaj' },
  { name: 'stars', title: 'Rating' },
  { name: 'coach', title: 'Koç' },
  { name: 'avatar', title: '' },
  { name: 'name', title: 'Üye' },
  { name: 'email', title: 'E-posta' },
  { name: 'solved', title: 'Durumu' },
];

const FeedbacksTable = (props) => {

  const { setRating, setNotSolved, setChats } = props;

  const [feedbacks, setFeedbacks] = useState([]);
  const [tableColumnExtensions] = useState([
    { columnName: 'type', wordWrapEnabled: true }, { columnName: 'text', wordWrapEnabled: true },
  ]);
  const [alert, setAlert] = useState();
  const [totalSummaryItems] = useState([
    { columnName: 'type', type: 'count' },
  ]);

  useEffect(() => {
    getAllFeedbacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const getAllFeedbacks = () => {
    getFeedbacks()
    .then(res => {
      if (res.data && res.data.code === 200) {
        let data = res.data.data;
        let users = [];
        let chats = 0;
        for (let i = 0; i < data.length; i++) {
          let d = data[i];
          if (d.type.includes("Chat SSS:")) {
            chats++;
            if (d.user && !users.includes(d.user.email)) {
              users = [...users, d.user.email];
            }
          }
        }
        console.log("chats: ", chats, "users: ", users.length);
        setChats(`${chats}/${users.length}`)

        // mark as solved:
        let typeList = ["Egzersiz no:", "Hareket:", "Sunucudan giden"]
        data.map(d => {
          if (!d.solved && (d.stars >= 3 || d.text === "Yok" || typeList.some(t => d.type.includes(t)))) {
            return d.solved = true;
          } else return null;
        });
        // if not solved, text exists and stars <= 3
        let notSolved = data.filter(d => !d.solved && !(!d.text && d.stars >= 4) );
        setNotSolved(notSolved.length);
        // first: stars <= 3
        let sorted = notSolved.filter(d => d.stars <= 3);
        // sorted = sorted.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1);
        // second: with text
        let sorted2 = notSolved.filter(d => d.text);
        // sorted2 = sorted2.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1);
        let important = sorted.concat(sorted2);
        // sort by date
        important = important.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1);
        let others = data.filter(d => !important.includes(d));
        let all = important.concat(others);
        setFeedbacks(all);
      } else {
        console.log('Hata: ', res.data.code);
      }
    })
    .catch((err) => {
      console.log('Bir hata oluştu :', err)
    })
  }
  
  const onClick = (feedback) => {
    // handle feedback
    setAlert(
      <SweetAlert 
        info
        title="Geribildirim çözüldü mü?" 
        onConfirm={() => closeFeedBack(feedback)} 
        onCancel={() => setAlert()}
        confirmBtnText=" Evet "
        cancelBtnText=" Hayır "
        showCancel={true}
        showConfirm={true}
        confirmBtnBsStyle='success'
        cancelBtnBsStyle='secondary'
      />
    );
  }
  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert 
        success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" 
        onConfirm={() => hideAlert()} showConfirm={false}
      >
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1500);
  };
  const hideAlert = () => setAlert(null);

  const closeFeedBack = (feedback) => {
    setAlert();
    console.log(feedback);
    let data = {id: feedback._id, solved: true}
    // update
    updateUserFeedback(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        console.log("feedback updated:", res.data.data);
        setTimeout(autoCloseAlert, 100);
        getAllFeedbacks();
      }
    })
  }

  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'date', width: 90 },
    { columnName: 'type', width: 220 },
    { columnName: 'text', width: 220 },
    { columnName: 'stars', width: 110 },
    { columnName: 'coach', width: 80 },
    { columnName: 'avatar', width: 40 },
    { columnName: 'name', width: 70 },
    { columnName: 'email', width: 150 },
    { columnName: 'solved', width: 95 },
   
  ])

  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedFeedback = feedbacks.find(s => s._id === row.id);
        // setUser(selectedFeedback);
        // console.log(selectedUser);
        onClick(selectedFeedback);
      }}
      style={{
        cursor: 'pointer',
        backgroundColor: row.solved === "🟢 Kapandı" ? "#F4F6FA" : "#EFFFFF",
      }}
    />
  );

  const rows = (feedbacks) => {
    var no = 0;
    var rating = 0;
    var ratingCount = 0;
    return (
      feedbacks.map((s, i) => {
        no += 1;
        var id = s._id;
        var avatar = s.user && s.user.avatar;
        var name = s.user && s.user.name;
        var email = s.user && s.user.email;
        var date = s.date.slice(0,10);
        var type = s.type.split("|")[0];
        // var exercise = type.includes("Egzersiz no:");
        var text = s.text;
        var stars = s.stars;
        if (stars) {
          rating += stars;
          ratingCount++;
        }
        if (no === feedbacks.length) {
          setRating(rating / ratingCount);
        }
        var coach = s.coach;
        if (coach === undefined && (name === "Yağmur" || name === "Merve" || name === "Beliz")) {
          coach = coachNameToNo2(name);
        }
        var solved = !text && stars >= 4 ? true : s.solved;
        let randomCoach = Math.floor(Math.random() * 2.99) + 1;  // between 0 and 3 
        return ({
          no: feedbacks.length - i,
          id: id,
          avatar: avatar,
          name: name,
          email: email,
          date: date,
          type: type,
          text: text,
          stars: "⭐️".repeat(stars),
          coach: coachNoToName(coach || randomCoach),
          solved: solved ? "🟢 Kapandı" : "🔴 Açık"
        })
      })
    )
    
  }

  return (
    <>
      {feedbacks.length > 0 ?
        <Paper>
          <Grid rows={rows(feedbacks)} columns={columns}>
            <SortingState defaultSorting={[{ }]}/>
            <IntegratedSorting />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <Table />
            <VirtualTable 
              height="calc(100vh - 130px)"
              rowComponent={TableRow} 
              tableComponent={TableComponent} 
              columnExtensions={tableColumnExtensions} 
            />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
            <SummaryState
              totalItems={totalSummaryItems}
            />
            <IntegratedSummary />
            <TableSummaryRow />
          </Grid>
        </Paper>
      : 
        <CircularProgress />
      }
      {alert}
    </>
    
  );
}

export default FeedbacksTable;