// import UserFeedbacks from "views/UserFeedbacks/UserFeedbacks";
import Payments from "views/Payments/PaymentList";
// import Blog from "views/Blog/BlogList";
// import UserAnalytics from "views/Analytics/UserAnalytics";
// import UserListActive from './views/UserListActive/UserList';
// import WebUsers from './views/WebUsers/UserList';
// import UserEvents from './views/UserEvents/List';
import WebForms from './views/WebForms/FormList';

var dashRoutes = [
  {
    path: "/webforms",
    name: "Adaylar",
    icon: "assignment_ind",
    component: WebForms,
    layout: "/admin"
  },
  {
    path: "/payments",
    name: "Danışanlar",
    icon: "payment",
    component: Payments,
    layout: "/admin"
  },
];

export default dashRoutes;
