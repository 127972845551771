import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import {useHistory} from 'react-router-dom';
import UserLessons from './UserLessons';
import UserMessages from './UserMessages';
import UserAnswers from './UserAnswers';
// import Blank from './Blank';
import UserNutrition from './UserNutrition';
import UserWorkouts from './UserWorkouts';
import UserInfo from './UserInfo';
import UserMealDiaries from './UserMealdiaries';
import UserMoods from './UserMoods';
import UserWaters from './UserWaters';
import UserPhysicalactivities from './UserPhysicalactivities';
import UserFeedbacks from './UserFeedbacks';
import UserRecepies from './UserRecepies';
import UserMovements from './UserMovements';
import UserQuotes from './UserQuotes';

const UserDetails = (props) => {
  const [user, setUser] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (props.location.state && props.location.state.user) {
      setUser(props.location.state.user);
    } else {
      history.push({ 
        pathname: '/admin/userlist', 
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.location]);

  return (
    user._id ? 
      <>
        <UserInfo user={user} />
        <UserLessons user={user} />
        <UserAnswers user={user} />
        <UserMessages user={user} />
        <UserFeedbacks user={user} />
        <UserMoods user={user} />
        <UserQuotes user={user} />
        <UserWorkouts user={user} />
        <UserMovements user={user} />
        <UserPhysicalactivities user={user} />
        <UserNutrition user={user} />
        <UserRecepies user={user} />
        <UserMealDiaries user={user} />
        <UserWaters user={user} />
      </>
    : 
      <></>
  );
}

export default UserDetails;