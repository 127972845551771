import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import { createMovements, getMovements, updateMovement, createMovement, update } from '../../services/exerciseServices'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableEditRow, TableEditColumn, } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { EditingState, DataTypeProvider } from '@devexpress/dx-react-grid';
import ReactPlayer from 'react-player';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';

const videoDomain = "https://bewell-files.s3.eu-central-1.amazonaws.com/videos/";

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const getRowId = row => row.id || null;

const columns = [
  { name: 'id', title: 'id' },
  { name: 'no', title: 'no' },
  { name: 'name', title: 'name' },
  { name: 'video', title: 'video' },
  { name: 'vid', title: 'vid' },
  { name: 'nameaudio', title: 'nameaudio' },
  { name: 'descaudio', title: 'descaudio' },
  { name: 'stage', title: 'stage' },
  { name: 'muscle', title: 'muscle' },
  { name: 'detail', title: 'detail' },
  { name: 'equipment', title: 'equipment' },
  { name: 'doubleside', title: 'doubleside' },
  { name: 'lumbar', title: 'lumbar' },
  { name: 'knee', title: 'knee' },
  { name: 'jump', title: 'jump' },
  { name: 'shoulder', title: 'shoulder' },
  { name: 'level', title: 'level' },
  { name: 'timerep', title: 'timerep' },
  { name: 'progress', title: 'progress' },
  { name: 'resistance', title: 'resistance' },
];

const Exercise = () => {

  const [movements, setMovements] = useState([{}]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [addedRows, setAddedRows] = useState([]);

  const [onVideo, setOnVideo] = useState(false);
  const [tableColumnExtensions] = useState([{columnName: 'name', wordWrapEnabled: true}, {columnName: 'video', wordWrapEnabled: true},]);
  // console.log(editingRowIds);

  useEffect(() => {
    getMovements()
    .then((res) => {
      if (res.data && res.data.code === 200) {
        setMovements(res.data.data);
        console.log("hareket adedi: ", res.data.data.length);
      } 
    });
  },[])

  useEffect(() => {
    // Create all movements Database
    // createMovements()
    
    // add movements
    if (movements[0].name) {
      // createMovements();
      // update(movements)  // for updating shoulder etc..
    }
  },[movements])

  const rows = (movements) => {
    return (
      movements.map((m, i) => {
        if (m.video && m.video.includes(" ")) { console.log("video url'de boşluk var: ", m.video) }

        return ({
          id: m._id,
          no: i + 1,
          name: m.name,
          video: m.video,
          vid: m.video ? 
            <ReactPlayer 
              url={videoDomain + m.video} controls={m.name === onVideo} 
              height={m.name === onVideo ? 300 : 100} width={m.name === onVideo ? 200 : 150}
              onClick={() => setOnVideo(m.name)}
              onError={() => console.log("video hata: ", m.video)}
            /> 
          : null,
          nameaudio: m.nameaudio,
          descaudio: m.descaudio,
          stage: m.stage,
          muscle: m.muscle,
          detail: m.detail,
          equipment: m.equipment,
          doubleside: m.doubleside ? 1 : 0,
          lumbar: m.lumbar ? 1 : 0,
          knee: m.knee ? 1 : 0,
          jump: m.jump ? 1 : 0,
          shoulder: m.shoulder ? 1 : 0,
          level: m.level,
          timerep: m.timerep,
          progress: m.progress,
          resistance: m.resistance,
        })
      })
    )
  }

  const [defaultColumnWidths] = useState([
    { columnName: 'id', width: 90 },
    { columnName: 'no', width: 85 },
    { columnName: 'name', width: 170 },
    { columnName: 'video', width: 200 },
    { columnName: 'vid', width: 200 },
    { columnName: 'nameaudio', width: 95 },
    { columnName: 'descaudio', width: 95 },
    { columnName: 'stage', width: 100 },
    { columnName: 'muscle', width: 90 },
    { columnName: 'detail', width: 90 },
    { columnName: 'equipment', width: 100 },
    { columnName: 'doubleside', width: 90 },
    { columnName: 'lumbar', width: 90 },
    { columnName: 'knee', width: 90 },
    { columnName: 'jump', width: 90 },
    { columnName: 'shoulder', width: 90 },
    { columnName: 'level', width: 90 },
    { columnName: 'timerep', width: 90 },
    { columnName: 'progress', width: 90 },
    { columnName: 'resistance', width: 120 },
  ])

  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        //
      }}
      // style={{
      //   cursor: 'pointer'
      // }}
    />
  );

  const commitChanges = (e) => {
    const { changed, added } = e;
    if (changed) {
      const id = editingRowIds[0];
      const d = changed[id]
      const data = { id, ...d }
      updateMovement(data)
      .then((res)=> {
        if (res.data && res.data.code === 200){
          console.log("movement update başarılı")
          let index = movements.map( f => f._id).indexOf(res.data.data._id)
          if (index > -1) {
            setMovements([
              ...movements.slice(0, index), 
              res.data.data,
              ...movements.slice(index + 1)
            ])
          }
        }
      });
    }
    if (added) {
      console.log(added);
      const data = {
        name: added[0].name.trim(),  
        video: added[0].video.trim(),
        stage: (added[0].stage && added[0].stage.trim()) || "KUVVET",
        muscle: (added[0].muscle && added[0].muscle.trim()) || "Total Body",
        detail: added[0].detail,
        equipment: (added[0].equipment && added[0].equipment.trim()) || "BW",
        doubleside: added[0].doubleside,
        lumbar: added[0].lumbar || 1,
        knee: added[0].knee || 1,
        jump: added[0].jump || 1,
        shoulder: added[0].shoulder || 1,
        level: added[0].level || 3,
        timerep: added[0].timerep || "Süre",
        progress: added[0].progress || "HAYIR",
        resistance: added[0].resistance || "",
        nameaudio: added[0].nameaudio || "",
        descaudio: added[0].descaudio || "",
      }
      console.log("new movement: ", data.name);
      createMovement(data)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          console.log("created: ", res.data.data.name);
          setMovements([...movements, res.data.data]);
        } else {
          console.log("Error! movement name: ", data.name);
        }
      });
    }
  }

  const changeAddedRows = (value) => {
    const initialized = value.map(row => (Object.keys(row).length ? row : { 
      equipment: "BW",
      stage: 'KUVVET',
      muscle: "Total Body",
      level: 3,
      timerep: "Süre",
      progress: "HAYIR",
      lumbar: 1,
      knee: 1,
      jump: 1,
      shoulder: 1,
    }));
    setAddedRows(initialized);
  };

  return (
    <div>
      <Grid 
        rows={rows(movements)} 
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          onCommitChanges={commitChanges}
          onAddedRowsChange={changeAddedRows}
          addedRows={addedRows}
        />
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          height="calc(100vh - 130px)"
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
          columnExtensions={tableColumnExtensions} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <TableEditRow />
        <TableEditColumn
          showAddCommand
          showEditCommand
          showDeleteCommand
        />
      </Grid>
    </div>
  );
}

export default Exercise;