import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserPhysicalActivities } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserPhysicalactivities = (props) => {
  const { user } = props;

  const [activities, setActivities] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => { 
    let oneWeek = new Date()
    oneWeek.setDate(oneWeek.getDate() - 7);
    let data = { id: user._id, date: oneWeek }
    getUserPhysicalActivities(data)
    .then(res => {
      if (res.data && res.data.code === 200){
        setActivities(res.data.data);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <strong>Fiziksel Aktivite Girişleri</strong>
              {activities.map((a, i) => {
                return (
                  <li key={i} >
                    {new Date(a.date).toLocaleDateString().slice(0, 5)} {" : "}
                    {a.activities.map((b, j) => {
                      return <span key={j}>{b.activity} {b.amount} {b.amount ? "dk ": " "} Yoğunluk: {Math.round(b.intensity * 4 + 1)}/5 </span>
                    })}
                  </li>
                )
              })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserPhysicalactivities;