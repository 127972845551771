import React, { useEffect, useState } from 'react';
/* eslint-disable no-unused-vars */
import { createRecepie, getRecepies , fixRecepie, getFoods, createFoodRecepie, updateRecepie } from '../../services/nutritionServices'
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow, TableEditRow,
  TableEditColumn, } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import Paper from '@material-ui/core/Paper';
import { EditingState } from '@devexpress/dx-react-grid';
import recepie from '../../../src/recepies.json';

export const foodImageDomain = "https://bewell-files.s3.eu-central-1.amazonaws.com/foodimages/";

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const getRowId = row => row.id;

const columns = [
  { name: 'id', title: 'id' },
  { name: 'no', title: 'no' },
  { name: 'name', title: 'name' },
  { name: 'image', title: 'image' },
  { name: 'meal', title: 'meal' },
  { name: 'mealgroup', title: 'mealgroup' },
  { name: 'time', title: 'time' },
  { name: 'cooking', title: 'cooking' },
  { name: 'type', title: 'type' },
  { name: 'budget', title: 'budget' },
  { name: 'portion', title: 'portion' },
  { name: 'amount', title: 'amount' },
  { name: 'unit', title: 'unit' },
  { name: 'protein', title: 'protein' },
  { name: 'carb', title: 'carb' },
  { name: 'fat', title: 'fat' },
  { name: 'v', title: 'v' },
  { name: 'cal', title: 'cal' },
  { name: 'amount1', title: 'amount1' },
  { name: 'unit1', title: 'unit1' },
  { name: 'food1', title: 'food1' },
  { name: 'amount2', title: 'amount2' },
  { name: 'unit2', title: 'unit2' },
  { name: 'food2', title: 'food2' },
  { name: 'amount3', title: 'amount3' },
  { name: 'unit3', title: 'unit3' },
  { name: 'food3', title: 'food3' },
  { name: 'amount4', title: 'amount4' },
  { name: 'unit4', title: 'unit4' },
  { name: 'food4', title: 'food4' },
  { name: 'amount5', title: 'amount5' },
  { name: 'unit5', title: 'unit5' },
  { name: 'food5', title: 'food5' },
  { name: 'amount6', title: 'amount6' },
  { name: 'unit6', title: 'unit6' },
  { name: 'food6', title: 'food6' },
  { name: 'amount7', title: 'amount7' },
  { name: 'unit7', title: 'unit7' },
  { name: 'food7', title: 'food7' },
  { name: 'amount8', title: 'amount8' },
  { name: 'unit8', title: 'unit8' },
  { name: 'food8', title: 'food8' },
  { name: 'amount9', title: 'amount9' },
  { name: 'unit9', title: 'unit9' },
  { name: 'food9', title: 'food9' },
  { name: 'amount10', title: 'amount10' },
  { name: 'unit10', title: 'unit10' },
  { name: 'food10', title: 'food10' },
  { name: 'options', title: 'options' },
  { name: 'description', title: 'description' },
  { name: 'season', title: 'season' },
  { name: 'ketogenic', title: 'ketogenic' },
  { name: 'vegetarian', title: 'vegetarian' },
  { name: 'vegan', title: 'vegan' },
  { name: 'pregnant', title: 'pregnant' },
  { name: 'breastfeeding', title: 'breastfeeding' },
  { name: 'cholesterol', title: 'cholesterol' },
  { name: 'diabetes', title: 'diabetes' },
  { name: 'pcos', title: 'pcos' },
  { name: 'insulinresistance', title: 'insulinresistance' },
  { name: 'hypothyroidism', title: 'hypothyroidism' },
  { name: 'hashimoto', title: 'hashimoto' },
  { name: 'bloodpressure', title: 'bloodpressure' },
  { name: 'bloodthinner', title: 'bloodthinner' },
  { name: 'cortisone', title: 'cortisone' },
  { name: 'diuretic', title: 'diuretic' },
  { name: 'lactose', title: 'lactose' },
  { name: 'seafood', title: 'seafood' },
  { name: 'gluten', title: 'gluten' },
  { name: 'constipation', title: 'constipation' },
  { name: 'diarrhea', title: 'diarrhea' }
];

const Recepies = () => {
  
  const [recepies, setRecepies] = useState([{}]);
  const [rows, setRows] = useState([{}]);
  const [errorList, setErrorList] = useState([]);
  const [editingRowIds, setEditingRowIds] = useState([]);
  const [allFoods, setAllFoods] = useState([]);

  const [tableColumnExtensions] = useState([
    { columnName: 'description', wordWrapEnabled: true },
  ]);

  var i = 0

  // recursive function
  const fix = () => {
    fixRecepie(recepie.list[i])
    .then(res => {
      createRecepie(res)
      .then(r => {
        if (r.data && r.data.code === 200) {
          console.log(i, ". recepie created: ", recepie.list[i].name)
        } else {
          console.log(i + ". tarif *** hatalı *** ", recepie.list[i].name)
          setErrorList([...errorList, i])
        }
        i++
        if (i < recepie.list.length) {
          fix();
        } else {
          console.log("*** End ***");
          console.log("Error list: ", errorList);
        }
      })
    })
  }

  useEffect (()=> {
    
    // CREATE RECEPIE DATABASE
    // fix (); 

    // CREATE FOOD RECEPIES DATABASE FROM FOODS
    // getFoods()
    // .then((res) => {
    //   if (res.data && res.data.code === 200) {
    //     const foods = res.data.data; // all foods to recepis
    //     // const foods = res.data.data.filter(f => f.mealgroup) const foods = res.data.data.filter(f => f.mealgroup)
    //     foods.map(food => {
    //       createFoodRecepie(food)
    //       .then((res) => {
    //         // create as recepie
    //         // console.log(res);
    //         createRecepie(res)
    //         .then(r => {
    //           if (r.data && r.data.code === 200) {
    //             console.log("food recepie created: ", food.name);
    //           } else {
    //             console.log("food recepie *** problem ***: ", food.name)
    //           }
    //         })
    //       })
    //     })
    //   } 
    // });
  
  }, [])

  useEffect(() => {
    getRecepies()
    .then((res) => {
      if (res.data && res.data.code === 200) {
        const allRecepies = res.data.data.reverse();
        setRecepies(allRecepies);
        setRows(rows1(res.data.data));  
      } 
    });
    getFoods()
    .then((res) => {
      if (res.data && res.data.code === 200) {
        const allFoods = res.data.data.reverse();
        setAllFoods(allFoods);
      } 
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const convertNameToFile = (recepieName) => {
    let fileName = recepieName.toLowerCase().trim().replace(/\s+/g, '-');
    fileName = fileName.replace(/,/g, "").replace(/\(/g, "").replace(/\)/g, "");
    fileName = fileName.replace(/i̇/g, "i").replace(/ı/g, "i").replace(/ş/g, "s").replace(/ç/g, "c");
    fileName = fileName.replace(/ü/g, "u").replace(/ğ/g, "g").replace(/ö/g, "o");
    fileName += ".jpg";
    return fileName;
  }

  const rows1 = (recepies) => {
    return (
      recepies.map((r, i) => {
        // console.log(i, r);
        let imageText = r.image ? r.image : r.mealgroup ? convertNameToFile(r.name) : null;
        // let image = r.image ? <img src={foodImageDomain + r.image} alt={r.image} style={{ height:'45px', marginLeft:0 }}/> : null;
        let image = imageText ? <img src={foodImageDomain + imageText} alt={imageText} style={{ height:'45px', marginLeft:0 }}/> : null;
        if (!r.foods[0]) {
          console.log("no food[0]");
        }
        return ({
          id: r._id,
          no: recepies.length - i,
          name: r.name,
          meal: r.meal,
          mealgroup: r.mealgroup,
          time: r.time,
          cooking: r.cooking,
          type: r.type,
          budget: r.budget,
          portion: r.portion,
          amount: r.amount,
          unit: r.unit,
          amount1: r.foods[0] ? r.foods[0].amount : null,
          unit1: r.foods[0] ? r.foods[0].unit : null,
          food1: r.foods[0] && r.foods[0].foodId ? r.foods[0].foodId.name : "**YOK**",
          amount2: r.foods[1] ? r.foods[1].amount : null,
          unit2: r.foods[1] ? r.foods[1].unit : null,
          food2: r.foods[1] ? r.foods[1].foodId.name : null,
          amount3: r.foods[2] ? r.foods[2].amount : null,
          unit3: r.foods[2] ? r.foods[2].unit : null,
          food3: r.foods[2] ? r.foods[2].foodId.name : null,
          amount4: r.foods[3] ? r.foods[3].amount : null,
          unit4: r.foods[3] ? r.foods[3].unit : null,
          food4: r.foods[3] ? r.foods[3].foodId.name : null,
          amount5: r.foods[4] ? r.foods[4].amount : null,
          unit5: r.foods[4] ? r.foods[4].unit : null,
          food5: r.foods[4] ? r.foods[4].foodId.name : null,
          amount6: r.foods[5] ? r.foods[5].amount : null,
          unit6: r.foods[5] ? r.foods[5].unit : null,
          food6: r.foods[5] ? r.foods[5].foodId.name : null,
          amount7: r.foods[6] ? r.foods[6].amount : null,
          unit7: r.foods[6] ? r.foods[6].unit : null,
          food7: r.foods[6] ? r.foods[6].foodId.name : null,
          amount8: r.foods[7] ? r.foods[7].amount : null,
          unit8: r.foods[7] ? r.foods[7].unit : null,
          food8: r.foods[7] ? r.foods[7].foodId.name : null,
          amount9: r.foods[8] ? r.foods[8].amount : null,
          unit9: r.foods[8] ? r.foods[8].unit : null,
          food9: r.foods[8] ? r.foods[8].foodId.name : null,
          amount10: r.foods[9] ? r.foods[9].amount : null,
          unit19: r.foods[9] ? r.foods[9].unit : null,
          food10: r.foods[9] ? r.foods[9].foodId.name : null,
          options: r.options,
          description: r.description,
          image,
          v: r.v,
          protein: r.protein,
          carb: r.carb,
          fat: r.fat,
          cal: r.cal,
          season: r.season,
          ketogenic: r.ketogenic ? 1 : 0,
          vegetarian: r.vegetarian ? 1 : 0,
          vegan: r.vegan ? 1 : 0,
          pregnant: r.pregnant ? 1 : 0,
          breastfeeding: r.breastfeeding ? 1 : 0,
          cholesterol: r.cholesterol ? 1 : 0,
          diabetes: r.diabetes ? 1 : 0,
          pcos: r.pcos ? 1 : 0,
          insulinresistance: r.insulinresistance ? 1 : 0,
          hypothyroidism: r.hypothyroidism ? 1 : 0,
          hashimoto: r.hashimoto ? 1 : 0,
          bloodpressure: r.bloodpressure ? 1 : 0,
          bloodthinner: r.bloodthinner ? 1 : 0,
          cortisone: r.cortisone ? 1 : 0,
          diuretic: r.diuretic ? 1 : 0,
          lactose: r.lactose ? 1 : 0,
          seafood: r.seafood ? 1 : 0,
          gluten: r.gluten ? 1 : 0,
          constipation: r.constipation ? 1 : 0,
          diarrhea: r.diarrhea ? 1 : 0
        })
      })
    )
  }

  const [defaultColumnWidths] = useState([
    { columnName: 'id', width: 70 },
    { columnName: 'no', width: 70 },
    { columnName: 'name', width: 180 },
    { columnName: 'image', width: 85 },
    { columnName: 'meal', width: 120 },
    { columnName: 'mealgroup', width: 150 },
    { columnName: 'time', width: 70 },
    { columnName: 'cooking', width: 85 },
    { columnName: 'type', width: 90 },
    { columnName: 'budget', width: 90 },
    { columnName: 'portion', width: 90 },
    { columnName: 'amount', width: 90 },
    { columnName: 'unit', width: 120 },
    { columnName: 'amount1', width: 100 },
    { columnName: 'unit1', width: 90 },
    { columnName: 'food1', width: 120 },
    { columnName: 'amount2', width: 100 },
    { columnName: 'unit2', width: 90 },
    { columnName: 'food2', width: 120 },
    { columnName: 'amount3', width: 100 },
    { columnName: 'unit3', width: 90 },
    { columnName: 'food3', width: 120 },
    { columnName: 'amount4', width: 100 },
    { columnName: 'unit4', width: 90 },
    { columnName: 'food4', width: 120 },
    { columnName: 'amount5', width: 100 },
    { columnName: 'unit5', width: 90 },
    { columnName: 'food5', width: 120 },
    { columnName: 'amount6', width: 100 },
    { columnName: 'unit6', width: 90 },
    { columnName: 'food6', width: 120 },
    { columnName: 'amount7', width: 100 },
    { columnName: 'unit7', width: 90 },
    { columnName: 'food7', width: 120 },
    { columnName: 'amount8', width: 100 },
    { columnName: 'unit8', width: 90 },
    { columnName: 'food8', width: 120 },
    { columnName: 'amount9', width: 100 },
    { columnName: 'unit9', width: 90 },
    { columnName: 'food9', width: 120 },
    { columnName: 'amount10', width: 100 },
    { columnName: 'unit10', width: 90 },
    { columnName: 'food10', width: 120 },
    { columnName: 'options', width: 120 },
    { columnName: 'description', width: 280 },
    { columnName: 'v', width: 70 },
    { columnName: 'protein', width: 85 },
    { columnName: 'carb', width: 70  },
    { columnName: 'fat', width: 70  },
    { columnName: 'cal', width: 70 },
    { columnName: 'season', width: 90 },
    { columnName: 'ketogenic', width: 90 },
    { columnName: 'vegetarian', width:80  },
    { columnName: 'vegan', width: 90 },
    { columnName: 'pregnant', width: 90 },
    { columnName: 'breastfeeding', width: 90 },
    { columnName: 'cholesterol', width:80  },
    { columnName: 'diabetes', width: 90 },
    { columnName: 'pcos', width: 90 },
    { columnName: 'insulinresistance', width: 90 },
    { columnName: 'hypothyroidism', width: 90 },
    { columnName: 'hashimoto', width: 90 },
    { columnName: 'bloodpressure', width: 90 },
    { columnName: 'bloodthinner', width: 90 },
    { columnName: 'cortisone', width: 90 },
    { columnName: 'diuretic', width: 90 },
    { columnName: 'lactose', width: 90 },
    { columnName: 'seafood', width: 90 },
    { columnName: 'gluten', width: 90 },
    { columnName: 'constipation', width: 90 },
    { columnName: 'diarrhea', width: 90 }
  ])

  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        //
      }}
      // style={{
      //   cursor: 'pointer'
      // }}
    />
  );

  const booleanKeys = ['ketogenic', 'vegetarian', 'vegan', 'pregnant', 'breastfeeding', 'cholesterol', 'diabetes', 'pcos', 'insulinresistance', 'hypothyroidism', 'hashimoto', 'bloodpressure', 'bloodthinner', 'cortisone', 'diuretic', 'lactose', 'seafood', 'gluten', 'constipation', 'diarrhea'];

  
  
  const commitChanges = (e) => {
    const { changed, added } = e;
    if (changed) {
      const id = editingRowIds[0];
      let data = changed[id] || {}; // changed fields
      let allData = {};
      let allDataState = {};
      let recepieCopy = {};
      for (let i = 0; i < recepies.length; i++) {   // get changed rows all previous data
        if (recepies[i]._id === id) {
          allData = recepies[i];
          recepieCopy = JSON.parse(JSON.stringify(rows1([recepies[i]])[0]));
          allDataState = JSON.parse(JSON.stringify(recepies[i]));
          break;
        }
      }
      //  update changed data
      Object.keys(data).map(k => {
        if (booleanKeys.includes(k)) {
          allData[k] = data[k] === "1" ? true : false ;
        } else {
          allData[k] = data[k];
        };
        return null;
      });

      // update foods
      for (let i = 1; i <= 10; i++){  // get only changed data to foods array
        if (allData.foods[i - 1] && allData.foods[i - 1].foodId) {

          // console.log(allData.foods[i - 1].foodId);
          let _id = allData.foods[i - 1].foodId._id
          allData.foods[i - 1].foodId = _id;
          let name = recepieCopy["food" + i];
          if (name) {
            allDataState.foods[i - 1].foodId = { name, _id };
          }
        }
        if (data["food" + i]) {
          let foodId;
          for (let j = 0; j < recepies.length; j++) {
            if (recepies[j].name === data["food" + i]) {
              foodId = recepies[j]._id;
              break;
            }
          }
          if (foodId) {
            let name = data["food" + i];
            if (allData.foods[i - 1]) {
              allData.foods[i - 1].foodId = foodId;
              allDataState.foods[i - 1].foodId = { name } 
            } else {
              // append new item to foods array 
              allData.foods = [...allData.foods, {foodId, unit : "birim", amount : 1}];
              allDataState.foods = [...allDataState.foods, {
                foodId: { name, _id: foodId}, 
                unit : "birim", 
                amount : 1
              }];
            }
          } else {
            alert(data["food" + i] + " adlı besin bulunamadı!");
          }
        } else if (data["food" + i] === "" && allData.foods[i - 1]) {
          // delete food
          let index = i - 1;
          allData.foods = [...allData.foods.slice(0, index),...allData.foods.slice(index + 1)];
          allDataState.foods = [...allDataState.foods.slice(0, index),...allDataState.foods.slice(index + 1)];
        }
        if (data["unit" + i] || data["unit" + i] === "") {
          if (allData.foods[i - 1]) {
            allData.foods[i - 1].unit = data["unit" + i];
            allDataState.foods[i - 1].unit = data["unit" + i];
          }
        }
        if (data["amount" + i] || data["amount" + i] === "") {
          if (allData.foods[i - 1]) {
            allData.foods[i - 1].amount = data["amount" + i];
            allDataState.foods[i - 1].amount = data["amount" + i];
          }
        }
      }
      // update calorie
      allData.protein = 0;
      allData.carb = 0;
      allData.fat = 0;
      allData.v = 0;
      allData.cal = 0;

      for (let i = 1; i <= 10; i++){
        if (allData.foods[i - 1]) {
          let foodNo;
          for (let j = 0; j < allFoods.length; j++) {
            if (allFoods[j]._id === allData.foods[i - 1].foodId || allFoods[j]._id === allData.foods[i - 1].foodId) {
              foodNo = j;
              break;
            }
          }
          // console.log(foodNo);
          let amount = parseFloat(allData.foods[i - 1].amount);
          allData.protein +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].protein / parseInt(allData.portion);
          allData.carb +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].carb / parseInt(allData.portion);
          allData.fat +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].fat / parseInt(allData.portion);
          allData.v +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].v / parseInt(allData.portion);
         
          if (allFoods[foodNo].group === "Alkoller") {
            allData.cal += amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].cal / parseInt(allData.portion);
          } else {
            allData.cal += parseFloat((allData.protein * 4 + allData.carb * 4 + allData.fat * 9).toFixed(1));
          }
        }
      };
      allData.protein = parseFloat(allData.protein.toFixed(1))
      allData.carb = parseFloat(allData.carb.toFixed(1))
      allData.fat = parseFloat(allData.fat.toFixed(1))
      allData.v = parseFloat(allData.v.toFixed(1))
      allData.cal = parseFloat(allData.cal.toFixed(1))

      let updatedData = {id, ...allData}
      updateRecepie(updatedData)
      .then((res)=> {
        if (res.data && res.data.code === 200){
          let recepie = res.data.data;
          console.log("Tarif güncelleme başarılı");
          recepie.foods = allDataState.foods;
          console.log("Final recepie: ", recepie);

          // update state
          let index = recepies.map(f => f._id).indexOf(res.data.data._id)
          if (index > -1) {
            setRecepies([
              ...recepies.slice(0, index), 
              recepie,
              ...recepies.slice(index + 1)
            ]);
            setRows(rows1([
              ...recepies.slice(0, index), 
              recepie,
              ...recepies.slice(index + 1)
            ]));   
          }
        } else {
          console.log("Tarif güncelleme hatası")
        }
      });
    }
    // new recepie
    if (added) {
      let data = added[0]; // added data fields
      let dataOriginal = {};
      Object.keys(data).map(k => {
        dataOriginal[k] = data[k];
        return null;
      })
      
      //  update changed data
      // default values
      booleanKeys.map(k => {
        if (data[k] === undefined) {
          data[k] = true;
        } else {
          data[k] = data[k] === "0" ? false : true ;
        }
        return null;
      });
      
      // Object.keys(data).map(k => {
      //   if (booleanKeys.includes(k)) {
      //     // data[k] = data[k] === "1" ? true : false ;
      //     data[k] = data[k] === "0" ? false : true ;
      //   }
      //   return null;
      // });

      // create foods array
      data.foods = [];
      data.protein = 0;
      data.carb = 0;
      data.fat = 0;
      data.v = 0;
      data.cal = 0;
      for (let i = 1; i <= 10; i++){  // get only added data for foods array
        let foodId, foodNo;
        if (data["food" + i]) {
          for (let j = 0; j < allFoods.length; j++) {
            if (allFoods[j].name === data["food" + i]) {
              foodId = allFoods[j]._id;
              foodNo = j;
              break;
            }
          }
          if (foodId) {
            // append new item to foods array 
            data.foods = [...data.foods, {foodId, unit : "birim", amount : 1}];
            delete data["food" + i];
          } else {
            alert(data["food" + i] + " adlı besin bulunamadı!");
          }
        }
        if (data["unit" + i]) {
          if (data.foods[i - 1]) {
            data.foods[i - 1].unit = data["unit" + i];
            delete data["unit" + i];
          }
        }
        if (data["amount" + i]) {
          if (data.foods[i - 1]) {
            let amount = parseFloat(data["amount" + i]);
            data.foods[i - 1].amount = parseFloat(amount);
            data.protein +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].protein / parseInt(data.portion);
            data.carb +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].carb / parseInt(data.portion);
            data.fat +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].fat / parseInt(data.portion);
            data.v +=  amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].v / parseInt(data.portion);
            if (allFoods[foodNo].group === "Alkoller") {
              data.cal += amount / allFoods[foodNo].amount1 * allFoods[foodNo].amount2 / 100 * allFoods[foodNo].cal / parseInt(data.portion);
            } else {
              data.cal += parseFloat((data.protein * 4 + data.carb * 4 + data.fat * 9).toFixed(1));
            }
            delete data["amount" + i];
          }
        }
      };
      data.protein = parseFloat(data.protein.toFixed(1))
      data.carb = parseFloat(data.carb.toFixed(1))
      data.fat = parseFloat(data.fat.toFixed(1))
      data.v =  parseFloat(data.v.toFixed(1))
      data.cal = parseFloat(data.cal.toFixed(1));

      // console.log("added data: ", data);
      // create recepie
      createRecepie(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          let updatedData = res.data.data;
          // console.log(data);
          // console.log(updatedData);
          // console.log(dataOriginal);
          for (let i = 1; i <= 10; i++) {
            if (dataOriginal["food" + i]) {
              updatedData.foods[i - 1].foodId = { name: dataOriginal["food" + i], _id: data.foods[i -1].foodId };
            }
          }
          console.log("Yeni tarif oluşturuldu: ", updatedData);
          setRecepies([updatedData, ...recepies]);
          setRows(rows1([updatedData, ...recepies]));   
          alert("Bravo 👏🏻 Yeni Tarif Oluşturuldu.")
        } else {
          console.log("Tarif oluşturma hatası!");
          alert("Tarif oluşturma hatası!")
        }
      })
    }
  };

  return (
    <div style={{marginLeft: -20, marginTop: -20}}>
      <Grid 
        
        rows={rows} 
        columns={columns}
        getRowId={getRowId}
      >
        <EditingState
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
          onCommitChanges={commitChanges}
        />
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          columnExtensions={tableColumnExtensions} 
          height="700px" 
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <TableEditRow />
        <TableEditColumn
          showAddCommand
          showEditCommand
        />
      </Grid>
    </div>
  );
}

export default Recepies;