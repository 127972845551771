import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserInfo from '../../views/UserDetails/UserInfo';
import UserLessons from '../../views/UserDetails/UserLessons';
import UserAnswers from '../../views/UserDetails/UserAnswers';
import UserMessages from '../../views/UserDetails/UserMessages';
import UserFeedbacks from '../../views/UserDetails/UserFeedbacks';

/* eslint-disable no-unused-vars */

const UserDetailsDialog = (props) => {

  const { open, toggle, user } = props;
  
  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Kullanıcı Detayları 
      </DialogTitle>
      <DialogContent>
        {user && user._id &&
          <>
            <UserInfo user={user} />  
            <UserLessons user={user} />
            <UserAnswers user={user} />
            <UserMessages user={user} />
            <UserFeedbacks user={user} />
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle} color="primary" variant="contained">KAPAT</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserDetailsDialog;