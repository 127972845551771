import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserMealdiaries } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserMealDiaries = (props) => {
  const { user } = props;

  const [mealdiaries, setMealdiaries] = useState([]);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => {
    let oneWeek = new Date()
    oneWeek.setDate(oneWeek.getDate() - 7);
    let data = { id: user._id, date: oneWeek }
    getUserMealdiaries(data)
    .then(res => {
      if (res.data && res.data.code === 200){
        setMealdiaries(res.data.data);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <strong>Öğün Günlükleri</strong>
              {mealdiaries.map((m, i) => {
                return (
                  <li key={i} >
                    {m.meal} {new Date(m.date).toLocaleDateString().slice(0, 5)} {" : "}
                    {m.foods.map((f, j) => {
                      return <span key={j}>{f.recepie.name} {f.amount} {f.recepie.unit} </span>
                    })}
                  </li>
                )
              })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserMealDiaries;