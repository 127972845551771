import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserWorkouts } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserWorkouts = (props) => {
  const { user } = props;

  const [workouts, setWorkouts] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => {
    getUserWorkouts({ userId: user._id})
    .then(res => {
      if (res.data && res.data.code === 200) {
        let allWorkouts = res.data.data;
        setWorkouts(allWorkouts);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <h6>ANTRENMAN PROGRAMI</h6>
              
                {workouts.map((w, i) => {
                  let d = w.date ? new Date(w.date).toLocaleDateString().slice(0, 5) : ""
                  return (
                    <div key={i} style={cardStyle}>
                      <strong>{`No: ${w.workoutNo} Tarih: ${d} Süre: ${w.time}dk Zorluk: ${Math.round(w.intensity * 4 + 1)}/5 Split: ${w.split} SplitNo: ${w.splitNo} ${w.movements.length ? "✅" : ""}`}</strong><br></br>
                      {w.movements.length ? 
                        <>
                          Ekipmanlar: {w.equipments.map(e => e.name + "  ")} <br></br>
                          Hareketler: {w.movements.map((m, j) => {
                            return (
                              <li key={j} style={{ marginLeft: 10}}>
                                {`${m.name.name} ${m.name.equipment} Seviye:${m.name.level} Aşama: ${m.name.stage} Jump:${m.name.jump ? "1" : "0"} Knee:${m.name.knee ? "1" : "0"} Lumbar:${m.name.lumbar ? "1" : "0"}`}
                              </li>
                            )
                          })} 
                        </>
                        : 
                          <div>
                            Bu Antrenman henüz yapılmadı
                          </div>
                      }
                    </div>
                  )
                })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserWorkouts;