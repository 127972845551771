import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserWaters } from '../../services/userServices';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

const UserWaters = (props) => {
  const { user } = props;

  const [waters, setWaters] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetch = () => {
    let oneWeek = new Date()
    oneWeek.setDate(oneWeek.getDate() - 7);
    let data = { id: user._id, date: oneWeek }
    getUserWaters(data)
    .then(res => {
      if (res.data && res.data.code === 200){
        setWaters(res.data.data);
      }
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  return (
    <>
      <GridContainer style={{marginTop: -40}} onClick={() => setOpen(!open)}>
        <GridItem style={{width: '100%'}}>
          <Card style={cardStyle}>
            <CardBody style={{ overflowY: "scroll", paddingTop: -10 }}>
              <strong>Su Girişleri</strong>
              {waters.map((w, i) => {
                return (
                  <li key={i} >
                    {new Date(w.date).toLocaleDateString().slice(0, 5)} {" : "}
                    Su: {parseInt(w.amount * 10) / 10} lt
                  </li>
                )
              })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default UserWaters;