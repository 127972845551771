import axios from 'axios';
import { domain } from './domain';
import merve from "../assets/img/merve.jpg";
import beliz from "../assets/img/beliz.jpg";
import yagmur from "../assets/img/yagmur.jpg";
import cihan from "../assets/img/cihan.jpeg";
import avatar from "../assets/img/placeholder.jpg";

export const login = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/coachlogin', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

export const coachPhoto = (name) => {
  switch (name) {
    case "Merve Şahin" : return merve;
    case "Beliz Baylan" : return beliz;
    case "Yağmur Cantekin" : return yagmur;
    case "Cihan Nam" : return cihan;
    default: return avatar;
  }
}

export const coachNameToNo = (name) => {
  switch (name) {
    case "Merve Şahin" : return 1;
    case "Beliz Baylan" : return 2;
    case "Yağmur Cantekin" : return 3;
    default: return 0;
  }
}

export const coachNameToNo2 = (name) => {
  switch (name) {
    case "Merve" : return 1;
    case "Beliz" : return 2;
    case "Yağmur" : return 3;
    default: return 0;
  }
}

export const coachNoToName = (no) => {
  switch (no) {
    case 1: return "Merve";
    case 2: return "Beliz";
    case 3: return "Yağmur";
    default: return ""
  }
}

export const coachNameToBranch = (name) => {
  switch (name) {
    case "Merve Şahin" : return "Beslenme";
    case "Beliz Baylan" : return "Psikoloji";
    case "Yağmur Cantekin" : return "Egzersiz";
    default: return "Sağlıklı Yaşam";
  }
}

// get all coaches
export const getCoaches = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getcoaches', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

export const getCoachesAlg = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/getcoachesalg', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// update coach
export const updateCoach = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/coach', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}