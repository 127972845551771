import axios from 'axios';
import { domain } from './domain';
import foods from '../../src/foods.json';
// import recepie from '../../src/recepie.json';

// FOODS
// create new food
export const createFood = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/food', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get all foods
export const getFoods = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getfoods', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update a food
export const updateFood = (data) => {
  const requestOptions = {
      method: "PUT",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/food', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// create all food database
export const createFoods = () => {
  for (let i = 0 ; i < foods.list.length ; i++) {
    if (foods.list[i].name) {
      const data = {
        group: foods.list[i].group,
        name: foods.list[i].name.trim(),   // trim spaces
        cooked: foods.list[i].cooked,
        description: foods.list[i].description,
        mealgroup: foods.list[i].mealgroup,
        image: foods.list[i].image,
        amount1: foods.list[i].amount1,
        unit1: foods.list[i].unit1,
        amount2: foods.list[i].amount2,
        unit2: foods.list[i].unit2,
        v: foods.list[i].v,
        protein: foods.list[i].protein,
        carb: foods.list[i].carb,
        fat: foods.list[i].fat,
        cal: foods.list[i].cal,
        season: foods.list[i].season,
        ketogenic: foods.list[i].ketogenic,
        vegetarian: foods.list[i].vegetarian,
        vegan: foods.list[i].vegan,
        pregnant: foods.list[i].pregnant,
        breastfeeding: foods.list[i].breastfeeding,
        cholesterol: foods.list[i].cholesterol,
        diabetes: foods.list[i].diabetes,
        pcos: foods.list[i].pcos,
        insulinresistance: foods.list[i].insulinresistance,
        hypothyroidism: foods.list[i].hypothyroidism,
        hashimoto: foods.list[i].hashimoto,
        bloodpressure: foods.list[i].bloodpressure,
        celiac: foods.list[i].celiac,
        bloodthinner: foods.list[i].bloodthinner,
        cortisone: foods.list[i].cortisone,
        diuretic: foods.list[i].diuretic,
        lactose: foods.list[i].lactose,
        seafood: foods.list[i].seafood,
        gluten: foods.list[i].gluten,
        constipation: foods.list[i].constipation,
        diarrhea: foods.list[i].diarrhea
      }
  
      createFood(data)
      .then((res)=>{
        if (res.data && res.data.code === 200) {
          console.log(i, '. food oluşturuldu :' , data.name);
        } else {
          console.log(i, '. *** food hatası *** : ', data.name);
        }
      })
    }
    
  }
}

// RECEPIES
// create new recepie
export const createRecepie = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/recepie', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get all recepies
export const getRecepies = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getrecepies', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update a recepie
export const updateRecepie = (data) => {
  const requestOptions = {
      method: "PUT",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/updaterecepies', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// find food id by name
export const getFood = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getfood', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// create all recepies database
export const fixRecepie = async (recepie) => {
  
  let newRecepie = {...recepie}
  let foods = [];
  let requests = [];

  let foodAmount = -1;
  for ( const property in recepie) {
    if (property.includes("amount")) {
      foodAmount += 1;
    }
  }

  for (let i = 1; i <= foodAmount ; i++) {
    // console.log(i, recepie["food" + i]);
    if (recepie["food" + i] === undefined) { // if first food is empty
      i++;
      foodAmount++
    }

    var name = recepie["food" + i].substring(recepie["food" + i].indexOf(',') + 2)
    name = name.replace(", çiğ", "").trim()
    name = name.replace(", pişmiş", "").trim()

    let food = { 
      amount: recepie["amount" + i],
      unit: recepie["unit" + i],
      foodId: name
    }
    foods = [...foods, food];

    requests = [...requests, getFood({ name })]

  }

  let cal = 0;
  let protein = 0, carb = 0, fat = 0, v = 0;

  // console.log(requests);

  let res =  await Promise.all(requests);

  for (let j = 0; j < res.length ; j++) {
    
    if (res[j].data && res[j].data.data && res[j].data.data._id) {
      foods[j].foodId = res[j].data.data._id;

      protein +=  recepie["amount" + (j + 1)] / res[j].data.data.amount1 * res[j].data.data.amount2 / 100 * res[j].data.data.protein / recepie.portion;
      carb +=  recepie["amount" + (j + 1)] / res[j].data.data.amount1 * res[j].data.data.amount2 / 100 * res[j].data.data.carb / recepie.portion;
      fat +=  recepie["amount" + (j + 1)] / res[j].data.data.amount1 * res[j].data.data.amount2 / 100 * res[j].data.data.fat / recepie.portion;
      v += recepie["amount" + (j + 1)] / res[j].data.data.amount1 * res[j].data.data.v / recepie.portion;
      cal = protein * 4 + carb * 4 + fat * 9;
      
      protein = parseFloat(protein.toFixed(1))
      carb = parseFloat(carb.toFixed(1))
      fat = parseFloat(fat.toFixed(1))
      v =  parseFloat(v.toFixed(1))
      cal = parseFloat(cal.toFixed(1))

      // console.log("recepie amount j: ", recepie["amount" + (j + 1)], "res[j].data.data.amount1: ", res[j].data.data.amount1, "res[j].data.data.amount2: ", res[j].data.data.amount2, "res[j].data.data.protein: ",res[j].data.data.protein, "recepie.portion: ", recepie.portion )

      // console.log(j, ". food: ", res[j].data.data.name ,"protein:", protein, "carb: ",carb, "fat: ",fat, "cal: ",cal, "v: ",v)
      // console.log(" Success! name: ", res[j].data.data.name, " recepie: ", recepie.name, " j: ", j)

    } else {
      // console.log(" Food not found! ", " recepie: ", recepie.name, " j: ", j )

      foods[j].foodId = ""  // su: 603f86f8dd43bf1580706ab9
    }
  }

  // food resolve
  newRecepie = { ...newRecepie, foods}

  // kalori yazdır
  newRecepie = { ...newRecepie, protein: protein, carb: carb, fat: fat, v: v, cal: cal }

  return newRecepie;

  // for (let i = 0 ; i < 3 ; i++) {   // i < recepie.list.length

  //   // get food data for each recepie
  //   var protein = 0, carb = 0, fat = 0, v = 0;

 
  //   for (let f = 1; f <= 10 ; f++) {

  //     if (recepie.list[i]["food" + f]) {

  //       var words = recepie.list[i]["food" + f].split(",");
  //       var foodName = words[1].trim();

  //       const data = { name: foodName }

  //       getFood(data)
  //       .then((res) => {
  //         if (res.data.code === 200) {
  //           var food = res.data.data[0]

  //           if (food) {
  //             protein +=  recepie.list[i]["amount" + f] / food.amount1 * food.amount2 / 100 * food.protein / recepie.list[i].portion;
  //             carb +=  recepie.list[i]["amount" + f] / food.amount1 * food.amount2 / 100 * food.carb / recepie.list[i].portion;
  //             fat +=  recepie.list[i]["amount" + f] / food.amount1 * food.amount2 / 100 * food.fat / recepie.list[i].portion;
  //             v += recepie.list[i]["amount" + f] / food.amount1 * food.v / recepie.list[i].portion;
  //           }
  //         } 
  //       }) 

  //       foods.push({
  //         name: recepie.list[i]["food" + f],
  //         amount: recepie.list[i]["amount" + f],
  //         unit: recepie.list[i]["unit" + f],
  //       })
  //     }
  //   }

  //   // async problem
  //   console.log(protein, carb, fat, v);
    
  //   const data = {
  //     name: recepie.list[i].name,
  //     meal: recepie.list[i].meal,
  //     mealgroup: recepie.list[i].mealgroup,
  //     time: recepie.list[i].time,
  //     cooking: recepie.list[i].cooking,
  //     type: recepie.list[i].type,
  //     budget: recepie.list[i].budget,
  //     portion: recepie.list[i].portion,
  //     amount: recepie.list[i].amount,
  //     unit: recepie.list[i].unit,
  //     foods: foods,   // array of objects
  //     options: recepie.list[i].options,
  //     description: recepie.list[i].description,
  //     image: recepie.list[i].image,
  //     p: recepie.list[i].p,
  //     c: recepie.list[i].c,
  //     f: recepie.list[i].f,
  //     v: v,
  //     protein: protein,
  //     carb: carb,
  //     fat: fat,
  //     cal: protein * 4 + carb * 4 + fat * 9,
  //     season: recepie.list[i].season,
  //     ketogenic: recepie.list[i].ketogenic,
  //     vegetarian: recepie.list[i].vegetarian,
  //     vegan: recepie.list[i].vegan,
  //     pregnant: recepie.list[i].pregnant,
  //     breastfeeding: recepie.list[i].breastfeeding,
  //     cholesterol: recepie.list[i].cholesterol,
  //     diabetes: recepie.list[i].diabetes,
  //     pcos: recepie.list[i].pcos,
  //     insulinresistance: recepie.list[i].insulinresistance,
  //     hypothyroidism: recepie.list[i].hypothyroidism,
  //     hashimoto: recepie.list[i].hashimoto,
  //     hyperthyroidism: recepie.list[i].hyperthyroidism,
  //     bloodpressure: recepie.list[i].bloodpressure,
  //     celiac: recepie.list[i].celiac,
  //     bloodpressuremed: recepie.list[i].bloodpressuremed,
  //     antacid: recepie.list[i].antacid,
  //     bloodthinner: recepie.list[i].bloodthinner,
  //     cortisone: recepie.list[i].cortisone,
  //     thyroidmed: recepie.list[i].thyroidmed,
  //     diuretic: recepie.list[i].diuretic,
  //     dairy: recepie.list[i].dairy,
  //     searecepie: recepie.list[i].seafood,
  //     gluten: recepie.list[i].gluten,
  //     constipation: recepie.list[i].constipation,
  //     diarrhea: recepie.list[i].diarrhea
  //   }

  //   console.log(data);

    // createRecepie(data)
    // .then((res)=>{
    //   if (res.data.code === 200) {
    //     console.log('Recepie oluşturuldu : ', data.name);
    //   } 
    // })
  // }
}

export const createFoodRecepie = async (food) => {
  const newRecepie = food;
  newRecepie.meal = "Hepsi";
  newRecepie.time = 1;
  newRecepie.cooking = "Yok";
  newRecepie.type = "Besin";
  newRecepie.budget = "Orta";
  newRecepie.portion = 1;
  newRecepie.amount = food.amount1;
  newRecepie.unit = food.unit1;
  newRecepie.foods = [
    {
      amount: food.amount1,
      unit: food.unit1,
      foodId: food._id
    }
  ];
  newRecepie.protein = parseFloat((food.protein / 100 * food.amount2).toFixed(1));
  newRecepie.carb = parseFloat((food.carb / 100 * food.amount2).toFixed(1));
  newRecepie.fat = parseFloat((food.fat / 100 * food.amount2).toFixed(1));
  newRecepie.cal = parseFloat((food.cal / 100 * food.amount2).toFixed(1));
  delete newRecepie.group;
  delete newRecepie.cooked;
  delete newRecepie.amount1;
  delete newRecepie.unit1;
  delete newRecepie.amount2;
  delete newRecepie.unit2;
  return newRecepie;
}

export const updateFoodRecepie = async (food) => {
  const newRecepie = food;
  if (food.amount1) { 
    newRecepie.amount = food.amount1; 
    newRecepie.foods = [{ amount: food.amount1 }];
  }
  if (food.unit1) { 
    newRecepie.unit = food.unit1; 
    newRecepie.foods[0].unit = food.unit1;
  }
  if (food._id) { 
    newRecepie.foods[0].foodId = food._id;
  }
  if (food.protein && food.carb && food.fat && food.amount2) {
    newRecepie.protein = parseFloat((food.protein / 100 * food.amount2).toFixed(1));
    newRecepie.carb = parseFloat((food.carb / 100 * food.amount2).toFixed(1));
    newRecepie.fat = parseFloat((food.fat / 100 * food.amount2).toFixed(1));
    newRecepie.cal = parseFloat((food.cal / 100 * food.amount2).toFixed(1));
  } 
  delete newRecepie.group;
  delete newRecepie.cooked;
  delete newRecepie.amount1;
  delete newRecepie.unit1;
  delete newRecepie.amount2;
  delete newRecepie.unit2;
  return newRecepie;
}
