import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { getWebForms } from '../../services/webUserServices';
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';
import Dialog from './Dialog';

var styles = { 
  table: { 
    '& tbody tr:hover': {
      background: '#E4DFFF',
      cursor: 'pointer'
    }
  }
}

const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'name', title: 'Ad' },
  { name: 'tel', title: 'Telefon' },
  { name: 'email', title: 'Eposta' },
  { name: 'coach', title: 'Koç' },
  { name: 'notes', title: 'Bilgi' },
  { name: 'therapy', title: 'Terapi' },
  { name: 'status', title: 'Durum' },
  { name: 'info', title: 'Notlar' },
];

let allForms = [];

const getRowId = row => row.id || null;

const FormListTable = (props) => {

  const [coach, setCoach] = useAtom(coachAtom);
  const [forms, setForms] = useState([{ }]);
  const [openDialog, setOpenDialog] = useState(false);
  const [form, setForm] = useState({});
  const [admin] = useState(coach.email.includes("fitness-hocam.com"));
  const [readOnly] = useState(coach.title.includes("yetkili"));
  const [loading, setLoading] = useState(0);

  const toggleDialog = () => setOpenDialog(!openDialog);

  const onClick = (form) => {
    if (!readOnly) {
      setForm(form)
      toggleDialog()
    }
  }

  const [totalSummaryItems] = useState([
    { columnName: 'date', type: 'count' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'notes', wordWrapEnabled: true },
    { columnName: 'info', wordWrapEnabled: true },
    { columnName: 'therapy', wordWrapEnabled: true },
  ]);

  useEffect(() => {
    fetchForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchForms = () => {
    getWebForms()
    .then(res => {
      if (res.data && res.data.code === 200) {
        allForms = res.data.data;
        // if not admin => filter by coach 
        if (!admin && !readOnly) {
          // allForms = allForms.filter(form => form.coach.includes("#")); // all forms with all coaches
          allForms = allForms.filter(form => form.coach && form.coach.split("#")[1] === coach.email);
        }
        setLoading(allForms.length + 1)
        setForms(allForms);
      } else {
        console.log("sorun: ", res)
      }
    })
  }
  
  const [defaultColumnWidths] = useState([    
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'date', width: 115 },
    { columnName: 'name', width: 125 },
    { columnName: 'email', width: 175 },
    { columnName: 'tel', width: 125 },
    { columnName: 'coach', width: 165 },
    { columnName: 'notes', width: 220 },
    { columnName: 'therapy', width: 90 },
    { columnName: 'status', width: 120 },
    { columnName: 'info', width: 220 },
  ])
  const [defaultHiddenColumnNames] = useState(['id', !admin && 'coach']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedForm = forms.find(s => s._id === row.id);
        onClick(selectedForm);
      }}
      style={{
        cursor: 'pointer',
        backgroundColor: row.status !== "yeni" ? "#F4F6FA" : "#EFFFFF",
      }}
    />
  );

  const rows = (forms) => {
    var no = forms.length + 1;
    return (
      forms.map(s => {
        no -= 1;
        var id = s._id;
        const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit"    };
        const dateLocal = new Date(s.date).toLocaleDateString('tr-TR', dateOptions);
        var date = dateLocal.slice(0, 14);
        const therapy = s.notes && (s.notes.includes("Duygusal") || s.notes.includes("motivasyon")) ? "Psikoloğa Yönlendir" : "-";
        return ({
          no,
          id,
          date,
          name: s.name, 
          tel: s.tel,
          email: s.email,
          coach: s.coach && s.coach.split("#")[1],
          status: s.status,
          notes: s.notes,
          therapy,
          info: s.info
        })
      })
    )
  }

  return (
    !loading ? 
      <CircularProgress /> 
    :
    loading === 1 ?
      <div>Henüz Hiç Aday yok</div>
    :
    <Paper>
      <Grid rows={rows(forms)} columns={columns} getRowId={getRowId}>
        <SortingState defaultSorting={[{ }]}/>
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering />
        <Table />
        <VirtualTable 
          columnExtensions={tableColumnExtensions} 
          height="calc(100vh - 100px - 60px)"
          rowComponent={TableRow} 
          tableComponent={TableComponent} 
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
        <SummaryState
          totalItems={totalSummaryItems}
        />
        <IntegratedSummary />
        <TableSummaryRow />
      </Grid>
      <Dialog 
        open={openDialog} toggle={toggleDialog} form={form} forms={forms} setForms={setForms}
      />
    </Paper>
  );
}

export default FormListTable;