import React, { useState, useRef, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createBlog, updateBlog } from '../../services/blogServices';
/* eslint-disable no-unused-vars */
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, ContentState } from 'draft-js'
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';
import { coachNameToBranch } from '../../services/coachServices'

const branches = ["Psikoloji", "Beslenme", "Egzersiz", "Sağlıklı Yaşam"];
const isActives = ["Aktif", "Hayır"];

const BlogDialog = (props) => {
  
  const { open, toggle, fetch, blog, setBlog, fetchBlog } = props;
  
  const [coach, setCoach] = useAtom(coachAtom);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [editorState, setEditorState] = useState("");
  const [contentState, setContentState] = useState("");
  const [body, setBody] = useState("");
  const [branch, setBranch] = useState(coachNameToBranch(coach.name));
  const [isActive, setIsActive] = useState("Hayır");
  const inputFile = useRef(null);

  useEffect(() => {
    //reset
    setBody("");
    setEditorState(null);
    setContentState(null);
    setIsActive("Hayır");
    if (blog.url) {
      setImage(blog.image);
      setBody(blog.body);
      htmlToEditorState(blog.body && blog.body !=="undefined" ? blog.body : "");
      setImage(blog.image);
      setIsActive(blog.isActive ? "Aktif" : "Hayır");
      setBranch(blog.branch);
    } else {
      setImage("");
      setFile("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, blog])

  const handleChangeBranch = event => setBranch(event.target.value);
  const handleChangeIsActive = event => setIsActive(event.target.value);

  const htmlToEditorState = (html) => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }

  const onEditorStateChange = editorState => setEditorState(editorState);
  const onContentStateChange = contentState => {
    setContentState(contentState);
    setBody(draftToHtml(contentState))
  }

  const content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "Initialized from content state.",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  }

  const onImageClick = () => inputFile.current.click();

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if (file.size > 50000) {
      console.log('Dosya boyutunu küçültün')
      window.alert(`Görsel boyutu ${parseInt(file.size / 1000)} kb. 50 kb 'dan az olmalı. Önce boyutları maksimum 600 piksel olarak değiştirip sonra sıkıştırma uygulayın.`)
    } else {
      setFile(file);
      console.log(file);
      setImage(URL.createObjectURL(file))
    }
  }

  const onAddBlog = (event) => {
    event.preventDefault();
    toggle();
    var author = event.target.elements.blogAuthor.value;
    var url = event.target.elements.blogUrl.value;
    var title = event.target.elements.blogTitle.value;
    var description = event.target.elements.blogDescription.value;
    
    console.log(author, url, title, description, body);
    // form data, image create
    const fd = new FormData()
    if (file !== "") {
      fd.append('screen-photo', file, file.name);
    } 
    if (image && image !== "" && !image.includes('blob')) {
      fd.append('image', image)
    }
    if (file === "" && image === "") {
      fd.append('image', image)
    }
    fd.append('author', author);
    fd.append('url', url);
    fd.append('title', title);
    fd.append('description', description);
    fd.append('date', new Date());
    fd.append('body', body);
    fd.append('branch', branch);
    fd.append('isActive', isActive === "Aktif");
    // Display the form data key/value pairs
    for (var pair of fd.entries()) {
      console.log(pair[0]+ ', ' + pair[1])
    }
    if (!blog._id) {
      // create new blog
      createBlog(fd)
      .then((res) => {
        if (res.data.code === 200) {
          console.log('blog oluşturuldu :', url);
          console.log("yeni blog: ", res.data.data)
          // setBlog(res.data.data);
          fetch(res.data.data)
          setBlog(res.data.data)
        } 
      })
    } else {
      // update blog
      fd.append('id', blog._id);
      updateBlog(fd)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          let updatedblog = res.data.data
          console.log('blog güncellendi :', updatedblog);
          // fetch(res.data.data)
          fetchBlog(updatedblog);
        } else {
          console.log('Hata oluştu :', res.data.message, res.data.code);
        }
      })
    }
  }

  const onDeleteImage = () => {
    setImage("");
    setFile("");
  }

  let coachName = `${coach.title} ${coach.name}`
  let isActiveEmoji = isActive === "Aktif" ? "✅" : "";
  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="lg">
        <DialogTitle id="form-dialog-title">Yeni Blog {isActiveEmoji}</DialogTitle>
        <DialogContent>
          <form onSubmit={onAddBlog}>
          <div style={{display:"flex", flexDirection: "row"}}>
              <div style={{marginTop: 10, marginRight: 10, width: 140}}>
                <InputLabel required  id="select-branch">Branş</InputLabel>
                <Select 
                  autoFocus labelId="label-branch" id="branch" value={branch ? branch : ""}
                  onChange={handleChangeBranch} style={{width: 140}}
                >
                  {branches.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
              <div style={{marginTop: 10, marginRight: 10, width: 100}}>
                <InputLabel required  id="select-isActive">Aktif mi</InputLabel>
                <Select 
                  labelId="label-isActive" id="isActive" value={isActive ? isActive : "Hayır"}
                  onChange={handleChangeIsActive} style={{width: 100}}
                >
                  {isActives.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
              <TextField 
                name="blogAuthor" required id="author" label="blog author" type="text" 
                defaultValue={blog.author ? blog.author : (coachName ? coachName : "")}
                style={{marginTop: 10, marginRight: 10, width: 200}}
              />
              <TextField 
                name="blogUrl"  id="url" label="blog url" type="text" 
                defaultValue={blog.url ? blog.url : ""}
                style={{marginTop: 10, width: 250}}
              />
            </div>
            <TextField 
                name="blogTitle"  id="title" label="blog title (80 karakter)" type="text" 
                defaultValue={blog.title ? blog.title : ""} inputProps={{maxLength: 80}}
                style={{marginTop: 10, width: 720}}
              />
            <TextField 
                name="blogDescription"  id="description" label="blog description (155 karakter)" type="text" multiline maxRows={2} inputProps={{maxLength: 155}}
                defaultValue={blog.description ? blog.description : ""}
                style={{marginTop: 10, marginBottom: 20, width: 720}}
              />
            <div >
              <Editor 
                placeholder="Blog Metni" 
                initialContentState={content}
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(editorState)=> onEditorStateChange(editorState)}
                editorContent={contentState} 
                editorStyle = {{ maxHeight: 400, overflowY: "scroll", borderWidth: 1, borderColor: "#7265E3", borderStyle: "solid", padding: 10 }}
                onContentStateChange={(contentState) => onContentStateChange(contentState)}
                toolbar={{ 
                  options: ['inline', 'list', 'textAlign', 'blockType', 'colorPicker', 'link', 'image', 'emoji', 'history'],
                  inline: {
                    options: ['bold', 'italic', 'underline']
                  },
                  blockType: {
                    inDropdown: false,
                    options: ['Normal', 'H2'],
                    className: undefined,
                    component: undefined,
                  },
                  textAlign: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['left', 'center', 'justify'],
                  },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['unordered', 'ordered'],
                  },
                }}
              />
            </div>
            <div style= {{marginTop: 20}}>
              <Button onClick={onImageClick} size="small" style={{margin:'auto', marginBottom: 10}} variant="contained">
                BLOG GÖRSELİ
              </Button>
              {image && 
                <Button onClick={onDeleteImage} style={{color: 'red'}}>GÖRSELİ SİL</Button>
              }
            </div>

            <input 
              type='file' id='file' name='screen-photo' accept="image/*" ref={inputFile} 
              onChange={onChangeFile} style={{display: 'none'}}
            />
            <img alt={image} id="target" src={image} style={{ height: 200, margin:"auto"}} />
       
            <DialogActions>
            <Button type="submit" color={isActive === "Aktif" ? "secondary" : "primary"} variant="contained">{blog._id ? "GÜNCELLE" : "OLUŞTUR"} {isActiveEmoji}</Button>
              <Button onClick={toggle} color="primary">Kapat</Button>
            </DialogActions>
          </form>
        </DialogContent>
    </Dialog>
  );
}

export default BlogDialog;