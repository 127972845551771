import React, { useState, useRef, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addProgram, updateProgram } from '../../services/contentServices';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* eslint-disable no-unused-vars */
const progProcesses = ["Ders", "Takip"];
const branches = ["Psikoloji", "Beslenme", "Egzersiz", "Genel", "Yaşam Tarzı"];
const progTypes = ["Çözüm içeriği", "Özel durum", "Blog", "Anket"]

const ProgramDialog = (props) => {
  
  const { open, toggle, fetch, program, setProgram, fetchProgram } = props;
  const [process, setProcess] = useState(progProcesses[0]);
  const [branch, setBranch] = useState("Genel");
  const [progType, setProgType] = useState(progTypes[0]);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const inputFile = useRef(null);

  useEffect(() => {
    if (program.name) {
      setProcess(program.process);
      setBranch(program.branch);
      setProgType(program.type);
      setImage(program.image);
    } else {
      setProcess(progProcesses[0])
      setBranch("Genel");
      setProgType(progTypes[0]);
      setImage("");
      setFile("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open, program])

  const handleChangeBranch = event => setBranch(event.target.value);
  const handleChangeType = event => setProgType(event.target.value);
  const handleChangeProcess = event => setProcess(event.target.value);

  const onImageClick = () => inputFile.current.click();

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setFile(file);
    console.log(file);
    setImage(URL.createObjectURL(file))
    if (file.size > 1e5) {
      console.log('Dosya boyutunu küçültün')
    }
  }

  const onAddProgram = (event) => {
    event.preventDefault();
    toggle();
    var name = event.target.elements.progName.value;
    var description = event.target.elements.progDesc ? event.target.elements.progDesc.value : ""
    // form data, image create
    const fd = new FormData()
    if (file !== "") {
      fd.append('screen-photo', file, file.name);
    } 
    if (image && image !== "" && !image.includes('blob')) {
      fd.append('image', image)
    }
    if (file === "" && image === "") {
      fd.append('image', image)
    }
    fd.append('name', name);
    fd.append('branch', branch);
    fd.append('process', process);
    fd.append('type', progType);
    fd.append('description', description);
    if (program.decisions && program.decisions.length) {
      fd.append('decisions', JSON.stringify(program.decisions))
    }
    if (!program._id) {
      // create new program
      addProgram(fd)
      .then((res) => {
        if (res.data.code === 200) {
          console.log('Program oluşturuldu :', name);
          console.log("yeni program: ", res.data.data)
          // setProgram(res.data.data);
          fetch(res.data.data)
          setProgram(res.data.data)
        } 
      })
    } else {
      // update program
      fd.append('id', program._id);
      // Display the form data key/value pairs
      for (var pair of fd.entries()) {console.log(pair[0]+ ', ' + pair[1])}
      updateProgram(fd)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          let updatedProgram = res.data.data
          console.log('Program güncellendi :', updatedProgram);
          // fetch(res.data.data)
          fetchProgram(updatedProgram);
        } else {
          console.log('Hata oluştu :', res.data.message, res.data.code);
        }
      })
    }
  }

  const onDeleteImage = () => {
    setImage("");
    setFile("");
  }

  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="sm">
        <DialogTitle id="form-dialog-title">Yeni Program</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Yeni bir program yaratmak için bilgileri girin.
          </DialogContentText>
          <form onSubmit={onAddProgram}>
            <div style={{display:"flex", flexDirection: "row"}}>
              <div>
                <InputLabel required style={{marginTop: 20}} id="select-process">Program İşleyişi</InputLabel>
                <Select labelId="label-process" id="process" value={process ? process : ""}
                  onChange={handleChangeProcess} style={{width: 150}}>
                  {progProcesses.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                </Select>
              </div>
              
              {process === "Ders" && 
                <>
                  <div style={{marginTop: 20, marginLeft: 20 }}>
                    <InputLabel required  id="select-branch">Branş</InputLabel>
                    <Select 
                      autoFocus labelId="label-branch" id="branch" value={branch ? branch : ""}
                      onChange={handleChangeBranch} style={{width: 150}}
                    >
                      {branches.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                    </Select>
                  </div>

                  <div style={{marginTop: 20, marginLeft: 20}}>
                    <InputLabel required  id="select-type">Program Tipi</InputLabel>
                    <Select 
                      labelId="label-type" id="type" value={progType ? progType : ""}
                      onChange={handleChangeType} style={{width: 150}}
                    >
                      {progTypes.map(item => <MenuItem value={item} key={item}>{item}</MenuItem>)}
                    </Select>
                  </div>
                </>
              }

            </div>
            <>
              <TextField 
                name="progName" required id="name" label="Program Adı" type="text" 
                defaultValue={program.name ? program.name : ""}
                style={{marginTop: 10, width: 400}}
              />
              {process === "Ders" && 
                <TextField 
                  name="progDesc" required={process === "Ders" ? true : false} id="description" 
                  label="Program Açıklaması" type="text" 
                  multiline={true} style={{marginTop: 10, width: 500}}
                  defaultValue={program.description ? program.description : ""}
                />
              }
            </>
            {process === "Ders" && 
              <div style= {{marginTop: 10}}>
                <Button onClick={onImageClick} size="small" style={{margin:'auto'}}>
                  PROGRAM GÖRSELİ
                </Button>
                {image && 
                  <Button onClick={onDeleteImage} style={{color: 'red'}}>GÖRSELİ SİL</Button>
                }
              </div>
            }
            
            <input 
              type='file' id='file' name='screen-photo' accept="image/*" ref={inputFile} 
              onChange={onChangeFile} style={{display: 'none'}}
            />
            <img alt={image} id="target" src={image} style={{ width: "30%", margin:"auto"}} />
       
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">{program._id ? "GÜNCELLE" : "OLUŞTUR"}</Button>
              <Button onClick={toggle} color="primary">Kapat</Button>
            </DialogActions>
          </form>
        </DialogContent>
    </Dialog>
  );
}

export default ProgramDialog;