import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, VirtualTable, TableFilterRow, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { getPayments, getNoUserPayments } from '../../services/userServices';
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';
import Dialog from './Dialog';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew',
      cursor: 'pointer'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

const columns = [
  { name: 'no', title: 'No' },
  { name: 'id', title: 'ID' },
  { name: 'date', title: 'Tarih' },
  { name: 'status', title: 'Ödeme' },
  { name: 'amount', title: 'Tutar' },
  { name: 'months', title: 'Ay' },
  { name: 'name', title: 'Ad' },
  { name: 'tel', title: 'Telefon' },
  { name: 'email', title: 'E-posta' },
  { name: 'coach', title: 'Koç' },
  { name: 'info', title: 'Durum' },
  { name: 'therapy', title: 'Terapi' },
  { name: 'notes', title: 'Notlar' },
  { name: 'trxCode', title: 'Trx Code' },
];

const getRowId = row => row.id || null;

const PaymentListTable = (props) => {

  const [coach, setCoach] = useAtom(coachAtom);
  const [payments, setPayments] = useState([{}]);
  const [openDialog, setOpenDialog] = useState(false);
  const [payment, setPayment] = useState({});
  const [admin] = useState(coach.email.includes("fitness-hocam.com"));
  const [readOnly] = useState(coach.title.includes("yetkili"));
  const [loading, setLoading] = useState(0);

  const toggleDialog = () => setOpenDialog(!openDialog);

  const onClick = (payment) => {
    if (!readOnly)  {
      setPayment(payment)
      toggleDialog()
    }
  }

  const [totalSummaryItems] = useState([
    { columnName: 'date', type: 'count' },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: 'notes', wordWrapEnabled: true },
  ]);

  useEffect(() => {
    fetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchPayments = () => {
    getNoUserPayments()
    .then(res => {
      if (res.data && res.data.code === 200) {
        getPayments()
        .then(res2 => {
          if (res2.data && res2.data.code === 200) {
            let allPayments = [...res.data.data, ...res2.data.data];
            allPayments = allPayments.sort((a, b) => (new Date(a.date) < new Date(b.date)) ? 1 : -1);
            // if not admin => filter by coach 
            if (!admin && !readOnly) {
              // allPayments = allPayments.filter(payment => payment.coach.includes("#")); //  all coaches
              allPayments = allPayments.filter(payment => payment.status === "success");
              allPayments = allPayments.filter(payment => payment.coach && payment.coach.split("#")[1] === coach.email);
            }
            if (readOnly) {
              allPayments = allPayments.filter(payment => payment.status === "success");
            }
            setLoading(allPayments.length + 1)
            setPayments(allPayments);
          }
        })
      }
    });
  }
  
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 80 },
    { columnName: 'id', width: 80 },
    { columnName: 'date', width: 115 },
    { columnName: 'name', width: 125 },
    { columnName: 'email', width: 175 },
    { columnName: 'tel', width: 125 },
    { columnName: 'coach', width: 165 },
    { columnName: 'notes', width: 220 },
    { columnName: 'status', width: 125 },
    { columnName: 'info', width: 115 },
    { columnName: 'therapy', width: 115 },
    { columnName: 'amount', width: 80 },
    { columnName: 'months', width: 80 },
    { columnName: 'trxCode', width: 280 },
  ])

  const [defaultHiddenColumnNames] = useState(['id', !admin && 'trxCode', !admin && 'coach', !admin && 'status']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedPayment = payments.find(s => s._id === row.id);
        onClick(selectedPayment);
      }}
      style={{
        cursor: 'pointer',
        backgroundColor: row.info !== "yeni" ? "#F4F6FA" : "#EFFFFF",
      }}
    />
  );

  const rows = (payments) => {
    var no = payments.length + 1;
    return (
      payments.map(s => {
        no -= 1;
        var id = s._id;
        const dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit"    };
        const dateLocal = new Date(s.date).toLocaleDateString('tr-TR', dateOptions);
        var date = dateLocal.slice(0, 14);
        var trxCode = s.amount ? s.trxCode : "no user payment !";
        var amount = s.amount ? s.amount : "-";
        var months = s.months;
        var email = s.email;
        return ({
          no: no,
          id: id,
          date: s.date && date,
          trxCode: trxCode,
          amount: amount,
          months: months,
          email: email,
          coach: s.coach && s.coach.split("#")[1],
          name: s.name, 
          tel: s.tel,
          status: s.status,
          notes: s.notes,
          info: s.info,
          therapy: s.therapy
        })
      })
    )
  }

  return (
    !loading ? 
      <CircularProgress /> 
    :
    loading === 1 ?
      <div>Henüz Hiç Danışan yok</div>
    :
      <Paper>
        <Grid rows={rows(payments)} columns={columns} getRowId={getRowId}>
          <SortingState defaultSorting={[{ }]}/>
          <IntegratedSorting />
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <Table />
          <VirtualTable 
            columnExtensions={tableColumnExtensions} 
            height="calc(100vh - 100px - 60px)"
            rowComponent={TableRow} 
            tableComponent={TableComponent} 
          />
          <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
          <SummaryState
            totalItems={totalSummaryItems}
          />
          <IntegratedSummary />
          <TableSummaryRow />
        </Grid>
        <Dialog 
          open={openDialog} toggle={toggleDialog} payment={payment} payments={payments} setPayments={setPayments}
        />
      </Paper>
  );
}

export default PaymentListTable;