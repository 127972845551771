import React, { useState, useEffect } from 'react';
/* eslint-disable no-unused-vars */
import { getUserLessons, updateUserLesson } from '../../services/userServices';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HeightIcon from '@material-ui/icons/Height';
import SweetAlert from "react-bootstrap-sweetalert";
import Button from "@material-ui/core/Button";

const UserLessons = (props) => {
  const { user } = props;

  const [lessons, setLessons] = useState([]);
  const [lesson, setLesson] = useState();
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState();
  const [lessonList, setLessonList] = useState([]);

  useEffect(() => {
    fetchLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchLessons = () => {
    const data = { userId: user._id }
    getUserLessons(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        setLessons(res.data.data);
        setLesson();
        for (var i = 0; i < res.data.data.length ;i++) {
          if (res.data.data[i].isActive) {
            setLesson(res.data.data[i]);
            // console.log(res.data.data[i]);
            break;
          }
        }
      } else {
        console.log('Hata: ', res.data.code);
      }
    })
    .catch((err) => {
      console.log('Bir hata oluştu ')
    })
  }

  const cardStyle = {
    maxHeight: open ? "calc(100vh - 20px)" : 300, 
    marginTop: -15, 
    paddingTop: 10, 
    paddingBottom: 10
  }

  const autoCloseAlert = () => {
    setAlert(
      <SweetAlert 
        success style={{ display: "block", marginTop: "-100px" }} title="Bravo!" 
        onConfirm={() => hideAlert()} showConfirm={false}
      >
        İşlem Başarılı!
      </SweetAlert>
    );
    setTimeout(hideAlert, 1000);
  };

  const hideAlert = () => setAlert(null);

  const handleLesson = (lesson) => {
    setAlert();
    let data = {id: lesson._id, isActive: false}
    // update
    updateUserLesson(data)
    .then(res => {
      if (res.data && res.data.code === 200) {
        // console.log("lessson updated:", res.data.data);
        setTimeout(autoCloseAlert, 100);
        // refresh state
        fetchLessons();
      }
    })
  }

  const updateLessons = () => {
    setAlert();
    for (let i = 0; i < lessonList.length; i++) {
      let data = { id: lessonList[i], isActive: false }
      updateUserLesson(data)
      .then(res => {
        if (res.data && res.data.code === 200) {
          // console.log("lesson updated:", res.data.data);
        }
        if (i === lessonList.length - 1) {
          setLessonList([]);
          setTimeout(autoCloseAlert, 100);
          // refresh state
          fetchLessons();
        }
      })
    }
  }

  const confirmLessons = () => {
    // console.log(lessonList);
    setAlert(
      <SweetAlert 
        info
        title="Seçilen dersler tamamlandı olarak işaretlensin mi?" 
        onConfirm={updateLessons} 
        onCancel={() => setAlert()}
        confirmBtnText=" Evet "
        cancelBtnText=" Hayır "
        showCancel={true}
        showConfirm={true}
        confirmBtnBsStyle='success'
        cancelBtnBsStyle='secondary'
      />
    );
  }

  // const completeLesson = (lesson) => {
  //   console.log(lesson);
  //   setAlert(
  //     <SweetAlert 
  //       info
  //       title="Ders tamamlandı olarak işaretlensin mi?" 
  //       onConfirm={() => handleLesson(lesson)} 
  //       onCancel={() => setAlert()}
  //       confirmBtnText=" Evet "
  //       cancelBtnText=" Hayır "
  //       showCancel={true}
  //       showConfirm={true}
  //       confirmBtnBsStyle='success'
  //       cancelBtnBsStyle='secondary'
  //     />
  //   );
  // }

  const completeLesson = (lesson) => {
    let index = lessonList.indexOf(lesson._id)
    if (index > -1) {
      setLessonList([...lessonList.slice(0, index), ...lessonList.slice(index + 1)])
    } else {
      setLessonList([...lessonList, lesson._id]);
    }
  }

  const completeProgram = (progName) => {
    let list = lessonList;
    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].program && lessons[i].program.name === progName && lessons[i].isActive) {
        if (!lessonList.includes(lessons[i]._id)) {
          list = [...list, lessons[i]._id];
          // console.log("added: ", lessons[i]._id);
        } else {
          let index = list.indexOf(lessons[i]._id);
          list = [...list.slice(0, index), ...list.slice(index + 1)];
          // console.log("deleted: ", lessons[i]._id);
        }
      }
    }
    setLessonList(list);
  }

  return (
    user ? 
    <>
      <Card style={cardStyle} >
        <CardHeader>
          <strong>Dersleri </strong>
          <IconButton 
            onClick={() => setOpen(!open)} size="small" variant="contained"
            style={{marginLeft: 10, padding: 0, paddingBottom: 3 }}
          >
            <HeightIcon style={{ fontSize: 16 }} color="primary"/>
          </IconButton>
          {lessonList.length > 0 && 
            <Button 
              onClick={confirmLessons} color="primary" variant="contained" style={{ marginLeft: 10 }}
            >
              SEÇİMİ ONAYLA({lessonList.length})
            </Button>
          }
        </CardHeader>
        <CardBody style={{ overflowY: "scroll" }}>
            <table>
              <thead>
                <tr style={{ backgroundColor: '#8D81F8', color: '#FFF' }} >
                  <th style={{width: '20%', padding: 5}} align="left">Program</th>
                  <th style={{width: '10%', padding: 5}} align="left">Branş</th>
                  <th style={{width: '10%', padding: 5}} align="left">Süreç</th>
                  <th style={{width: '25%', padding: 5}} align="left">Ders</th>
                  <th style={{width: '10%', padding: 5}} align="left">Durumu</th>
                </tr>
              </thead>
              <tbody >
                {lessons.map((l, i) => {
                  if (!(l.program && l.program.name === "Blog İçerikleri") || (l.lesson && !l.isActive) || true) {  // test: || true
                    return (
                      <tr 
                        style={{ 
                          backgroundColor : i % 2 ? "#E4DFFF" : "#F4F6FA",
                          fontWeight: lessonList.includes(l._id) ? 500 : 200
                        }} 
                        key={i}
                      >
                        <td style={{padding: 5}}>
                          {l.program ? l.program.name  : null}
                          {l.program && (i === 0 || (i > 0 && ((lessons[i - 1].program && lessons[i - 1].program.name !== l.program.name) || !lessons[i - 1].program) )) &&
                            <IconButton 
                              onClick={() => completeProgram(l.program.name)} size="small" 
                              style={{marginLeft: 10, padding: 0, paddingBottom: 3 }}
                            >
                              <CheckCircleIcon style={{ fontSize: 16 }} color="primary" />
                            </IconButton>
                          }
                        </td>
                        <td style={{padding: 5}}>{l.program ? l.program.branch : null}</td>
                        <td style={{padding: 5}}>{l.lesson ? l.lesson.process : ""}</td>
                        <td style={{padding: 5}}>{l.lesson ? l.lesson.name.split("|")[0] : ""}</td>
                        <td 
                          style={{padding: 5}}
                        >
                          {l.lesson ? (l.isActive ? " sırada" : " tamamlandı ✔") : ""}
                          {l.lesson && l.isActive && 
                            <IconButton 
                              onClick={() => completeLesson(l)} size="small" 
                              style={{marginLeft: 10, padding: 0, paddingBottom: 3 }}
                            >
                              <CheckCircleIcon style={{ fontSize: 16 }} color="primary" />
                              <span style={{ fontSize: 12 }}>{lessonList.includes(l._id) && " ✅"}</span>
                            </IconButton>
                          }
                          
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
              </tbody>
            </table>       
          <p style={{ paddingTop: 10}} >
            Aktif Programın sıradaki dersi: <strong>{lesson && lesson.lesson ? lesson.lesson.name : null }</strong>
          </p>
        </CardBody>
      </Card>
      {alert}
    </>
    : 
    <></>
  );
}

export default UserLessons;