import React from 'react';
import PaymentListTable from './PaymentListTable';

const PaymentList = () => {
  return (
    <div style={{marginTop: -30}}>
      <PaymentListTable />
    </div>
  );
}

export default PaymentList;