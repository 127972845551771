import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { login } from '../../services/coachServices';
import { useAtom } from 'jotai';
import { coachAtom } from '../../_atoms/coachAtom';

const useStyles = makeStyles(styles);
/* eslint-disable no-unused-vars */

const LoginPage = () => {
  const [coach, setCoach] = useAtom(coachAtom);
  const [alert, setAlert] = useState('');
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const onFormSubmit = (event) => {
    event.preventDefault();
    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;
    if (email && password) {
      const data = { email, password };
      login(data)
      .then((res) => {
        if (res.data && res.data.code === 200) {
          setCoach({ ...res.data.data, loggedin: true });
        } else {
          console.log("res.data: ", res.data);
          setAlert(res.data.message);
          window.alert("E-posta veya şifre hatalı!");
        }
      })
      .catch((err)=>{
        setAlert('Bir hata oluştu ')
      })
    }
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onFormSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="rose">
                <h4 className={classes.cardTitle}>Giriş</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="E-posta"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Şifre"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="rose" simple size="lg" block>GİRİŞ YAP</Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default LoginPage;