import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { getBlogs, getBlog } from '../../services/blogServices';
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import BlogDialog from './BlogDialog';

const BlogList = () => {
  const [blogs, setBlogs] = useState([{}]);
  const [blog, setBlog] = useState({});
  const [openBlogDialog, setOpenBlogDialog] = useState(false);

  useEffect(() => {
    fetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const fetchBlogs = () => {
    getBlogs()
    .then(res => {
      if (res.data && res.data.code === 200)  {
        setBlogs(res.data.data.reverse());
      }
    })
  }
  const fetchBlog = (b) => {
    let data = { id: b._id}
    getBlog(data)
    .then(res => {
      if (res.data && res.data.code === 200)  {
        console.log(res.data.data)
        setBlog(res.data.data);
        toggleBlogDialog(b);
      }
    })
  }

  const newBlog = () => {
    setBlog({});
    toggleBlogDialog();
  }
  const toggleBlogDialog = () => setOpenBlogDialog(!openBlogDialog);

  return (
    <GridContainer style={{marginTop: -40}}>
      <GridItem style={{marginTop: 10, marginBottom: 10, width: '100%'}}>
        <ButtonGroup variant="contained" size="small" color="secondary">
          <Button onClick={newBlog}> YENİ </Button>
        </ButtonGroup>

        <table style={{marginTop: 10}} >
          <thead>
            <tr style={{ backgroundColor: '#8D81F8', color: '#FFF' }} >
            <th style={{width: 55, padding: 5}} align="left">No</th>
              <th style={{width: 110, padding: 5}} align="left">Tarih</th>
              <th style={{width: 65, padding: 5}} align="left">Aktif</th>
              <th style={{width: 100, padding: 5}} align="left">Branş</th>
              <th style={{width: 140, padding: 5}} align="left">Url</th>
              <th style={{width: 280, padding: 5}} align="left">Title</th>
              <th style={{width: 100, padding: 5}} align="left">Görsel</th>
            </tr>
          </thead>
          <tbody>
          {blogs.map((b, i) => {
            return (
              <tr 
                style={{ cursor: 'pointer',backgroundColor : i % 2 ? "#E4DFFF" : "#F4F6FA", height: 60}} 
                key={i}
                onClick={()=> fetchBlog(b)} 
              >
                <td style={{padding: 5}}>{i + 1}</td>
                <td style={{padding: 5}}>{b.date && b.date.slice(0,10)}</td>
                <td style={{padding: 5}}>{b.isActive ? "✅" : "❌"}</td>
                <td style={{padding: 5}}>{b.branch}</td>
                <td style={{padding: 5}}>{b.url}</td> 
                <td style={{padding: 5}}>{b.title && b.title.slice(0,80)}</td>
                <td style={{padding: 5}}>
                  <img src={b.image} alt={b.image} style={{ height:'50px', marginLeft:0 }}/>
                </td>
              </tr>
            )
          })}
          </tbody>

          
        </table>
        
        <BlogDialog 
          open={openBlogDialog} toggle={toggleBlogDialog} fetch={fetchBlogs} 
          blog={blog} setBlog={setBlog} fetchBlog={fetchBlog}
        />

      </GridItem>
    </GridContainer>
  );
}

export default BlogList;