import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/core/styles';
import { Grid, Table, TableHeaderRow, TableColumnVisibility, TableColumnResizing, 
  VirtualTable, TableFilterRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, FilteringState, IntegratedFiltering, DataTypeProvider } from '@devexpress/dx-react-grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { getScreens, addPageToLesson } from '../../services/contentServices';

const styles = { 
  table: { 
    '& tbody tr:hover': {
      background: 'honeydew'
    }
  }
}
const TableComponentBase = ({ classes, ...restProps }) => {
  return (
    <Table.Table 
    { ...restProps }
    className={ classes.table }
  />
  );
}
export const TableComponent = withStyles(styles, {name:'TableComponent'})(TableComponentBase);

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const columns = [
  { name: 'no', title: 'Sıra' },
  { name: 'id', title: 'ID' },
  { name: 'name', title: 'Ekran Adı' },
  { name: 'type', title: 'Ekran Tipi' },
  { name: 'title', title: 'Başlık' },
  // { name: 'image', title: 'Görsel' },
  // { name: 'body', title: 'Metin' }
];
const PageArchiveDialog = (props) => {

  const { open, toggle, lesson, setLesson, setPages, fetchLesson, screens, setScreens } = props;

  
  const [page, setPage] = useState([{}]);
  const [screen, setScreen] = useState({});

  const [screens2, setScreens2] = useState([{}]); // '2' archive screens state 
  const [defaultColumnWidths] = useState([
    { columnName: 'no', width: 95 },
    { columnName: 'id', width: 90 },
    { columnName: 'name', width: 320 },
    { columnName: 'type', width: 150 },
    { columnName: 'title', width: 200 },
    // { columnName: 'body', width: 150 },
    // { columnName: 'image', width: 90 }
  ])
  const [defaultHiddenColumnNames] = useState(['id']);

  const TableRow = ({ row, ...restProps }) => (
    <Table.Row
      {...restProps}
      onClick={() => {
        var selectedScreen = screens.find(s => s._id === row.id);
        setScreen(selectedScreen);
        console.log(selectedScreen)
        setActivePage(selectedScreen)
      }}
      style={{
        cursor: 'pointer'
      }}
    />
  );
  const rows = (screens) => {
    var no = 0;
    return (
      screens.map(s => {
        no += 1;
        var id = s._id
        var name = s.screenName;
        var type = s.type
        var title = s.title
        // var body = s.body
        // var image = s.image
        return ({
          no: no,
          id: id,
          name: name,
          type: type,
          title: title,
          // body: body,
          // image: <img src={image} alt={image} style={{ height:'30px', marginLeft:0 }}/>
        })
      })
    )
  }

  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const fetchPages = () => {
    getScreens()
    .then(res => {
      if (res.data && res.data.code === 200) {
        setScreens(res.data.data);
      } else {
        console.log("Hata: ", res);
      }
    })
  }

  const setActivePage = (pg) => {
    setPage(pg);
    // console.log('Seçilen sayfa: ',pg)
  }

  const onAddPage = () => {
    toggle()
    console.log(page._id,' id nolu sayfa,', lesson.name, 'dersine ekleniyor')
    // add Screen to lesson: lesson id, Screen id
    var screenId = page._id
    // get pages and push new page
    var pages = lesson.pages
    pages.push(screenId)
    var id = lesson._id
    const data = { id, pages }
    addPageToLesson(data)
    .then(res =>{
      if (res.data && res.data.code === 200) {
        let updatedLesson = res.data.data;
        // console.log('Sayfa Derse eklendi',res.data.data.pages);
        // setLesson(res.data.data) // update parent state 
        // setPages(res.data.data.pages) // update parent state 
        fetchLesson(updatedLesson);
      } else {
        console.log('Sorun çıktı. Kod: ',res.data.code)
      }
    })
    .catch(err =>{
      console.log('İşlem başarısız: ', err)
    })
  }

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const TooltipFormatter = ({ row: { body, image }, value }) => (
    // <Tooltip title={(
    //   <span>
    //     <br />
    //     <div dangerouslySetInnerHTML={{ __html: body }} />
    //   </span>
    // )}
    // >
    //   <span>
    //     {value}
    //   </span>
    // </Tooltip>

    <HtmlTooltip
      title={(
        <span>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </span>
      )}>
      <span>{value}</span>
    </HtmlTooltip>
  );
  
  const CellTooltip = props => (
    <DataTypeProvider
      for={columns.map(({ name }) => name)}
      formatterComponent={TooltipFormatter}
      {...props}
    />
  );
  
  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="md">
      <DialogTitle id="form-dialog-title">{lesson.name} adlı derse Sayfa Ekleyin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Arşivden Sayfa seçin ({screens.length}): <strong style={{background:'honeydew'}}>{screen.screenName}</strong>
        </DialogContentText>
        <Paper>
          <Grid rows={rows(screens)} columns={columns}>
            <SortingState defaultSorting={[{ }]}/>
            <IntegratedSorting />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <CellTooltip />
            <Table />
            <VirtualTable 
              height="400px" 
              rowComponent={TableRow} 
              tableComponent={TableComponent} 
            />
            <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
          </Grid>
        </Paper>
      </DialogContent>


      {/* <DialogContent>
        <DialogContentText>
          Arşivden Ekran seçin:
        </DialogContentText>
        <strong>EKRANLAR</strong>
        <ol style={{ paddingLeft: 15}}>
          {screens.map(s => {
            return (
            <li onClick={()=> setActivePage(s)} style={{ cursor: 'pointer', backgroundColor: page._id === s._id && page._id ? "lightgreen" : "" }} key={s._id ? s._id : "1"} >{s.screenName ? s.screenName : s._id}</li>
            )
          })}
        </ol>
      </DialogContent> */}


      <DialogActions>
      <Button onClick={onAddPage} color="primary" variant="contained">EKLE</Button>
      <Button onClick={toggle} color="primary">Kapat</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PageArchiveDialog;