import axios from 'axios';
import { domain } from './domain';

// SCREEN SERVICES
// creating new screen
export const addScreen = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/screen', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// getting all screens
export const getScreens = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getscreens', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// getting system and question screens
export const getScreensSystem = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getscreenssystem', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// getting all screens-2 (for choices)
export const getAllScreens = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getallscreens', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update a screen
export const updateScreen = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/updatescreen', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// update screen2
export const updateScreen2 = (data) => {
  const requestOptions = {
      method: "PUT",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/updatescreen2', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// LESSON SERVICES
// create new lesson
export const addLesson = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/lesson', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// getting all lessons
export const getLessons = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getlessons', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get lesson 
export const getLesson = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getlesson', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// updating lesson (deleting, updating name, adding page, deleting page)
export const updateLesson = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/updatelesson', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// adding page to lesson - needed ?? 
export const addPageToLesson = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/addpage', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// add algorithm to lesson
export const addAlgorithmToLesson = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/addalgorithm', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// PROGRAM SERVICES
// create new program
export const addProgram = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/program', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// getting all programs
export const getPrograms = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getprograms', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get program all data
export const getProgram = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getprogram', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// get program lessons (nam, _id)
export const getProgramLessons = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/getprogramlessons', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// adding lesson to a program
export const addLessonToProgram = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/addlesson', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// updating a program
export const updateProgram = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/updateprogram', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      }else{
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// add algorithm to program
export const addAlgorithmToProgram = (data) => {
  const requestOptions = {
      method: "POST",
      headers: {
        //   'Authorization': 'Bearer ' + token, 
        'Content-type': 'application/json'
      },
      data
  }
  return axios(domain + '/addalgorithmprogram', requestOptions)
  .then( response => { 
      if(response.status){
          return response
      } else {
          throw new Error('Bağlantı hatası!')
      }})
  .catch( error => { return error })
}

// SENDING LESSON TO USER

// create a user lesson 
export const createUserLesson = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      //   'Authorization': 'Bearer ' + token, 
      'Content-type': 'application/json'
    },
    data
  }
  return axios(domain + '/userlesson', requestOptions)
  .then( response => { 
    if(response.status) {
        return response
    } else {
        throw new Error('Bağlantı hatası!')
    }})
  .catch( error => { return error })
}

// create user lesson : send lesson 
export const addUserLesson = async (linkId, userId, pushToken) => {
  // this id is a lesson
  // create user lesson from one lesson
  var dateObj = Date.now();
  let firstLessonDate = new Date(dateObj) // add delay if entered in dashboard
  console.log(pushToken);
  const data = { 
    user: userId, 
    pushToken: pushToken,
    program: linkId, // not program but lesson id
    isActive: true, 
    created: dateObj,
    dueDate: firstLessonDate,
    rank: 1,
    lesson: linkId
  }
  return createUserLesson(data)
  .then(res => {
    if (res.data.code === 200) {
      console.log('User lesson oluşturuldu!');
      return res.data.data
    } else {
      console.log('Hata: ', res.data.code);
      return null;
    }
  })
}

// send program to user
export const addUserLessons = async (program, userId) => {

  const lessons = program.lessons;
  let rank = 1;
  var dateObj = Date.now();
  let firstLessonDate = new Date(dateObj + 0 ) // add delay if entered in dashboard
  let futureDate = new Date(dateObj + 1000*60*60*24*365*2)  // future lessons are not due until current lesson finishes
  lessons.map((lesson) => {
    const data = { 
      user: userId, 
      program: program._id,
      isActive: true, 
      created: new Date(dateObj + rank),  // add rank as ms
      dueDate: rank === 1 ? firstLessonDate : futureDate, // give dueDate for the first lessson, others: no dueDate
      rank: rank,
      lesson: lesson._id
    }
    rank++
    return createUserLesson(data)
    .then(res => {
      if (res.data.code === 200) {
        console.log('User lesson oluşturuldu!', res.data.data);
      } else {
        console.log('Hata: ', res.data.code)
      }
    })
  })
  return null;
}


export const createFeedProgram = () => {
  // create pages

  // create lessons
  // add pages to lessons

  // create program
  // add lessons to program
}