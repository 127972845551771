import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import UserListTable from '../../views/UserList/UserListTableFast';
/* eslint-disable no-unused-vars */

const UserListDialog = (props) => {

  const { open, toggle, user, setUser, onSendLesson, lesson, program, users } = props;

  const onUserClick = (u) => {
    setUser(u);
  }
  
  return (
    <Dialog open={open} onClose={toggle} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        {(lesson.name && lesson.name.split("|")[0]) || program.name} adlı {lesson.name ? "dersi" : "programı"} kullanıcıya gönderin
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Üye seçin: {user.avatar} <strong> {user.name} </strong> {user.email}
        </DialogContentText>

        <UserListTable onClick={onUserClick} users={users}/>

      </DialogContent>

      <DialogActions>
      <Button onClick={onSendLesson} color="primary" variant="contained">GÖNDER</Button>
      <Button onClick={toggle} color="primary">KAPAT</Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserListDialog;